import { Grid, ListItemButton, Typography, useTheme } from '@mui/material';

import React, { useRef } from 'react';

import { CheckCircle, Error, LockClock, Warning } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import BackgroundLetterAvatars from '../../Components/UI/backgroundLetterAvatars';
import { mixpanel, trackingEvents } from '../../Monitoring/Mixpanel';
import { states } from '../../Utils/enums';


export default function RecentVerifications({ data }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const textRef = useRef();

  return (
    <ListItemButton
      container
      onClick={() => {
        mixpanel.track(trackingEvents.form.events.select.opened, {
          link: data?.link,
          formName: data?.formName,
          orgName: data?.name,
          state: states[data?.state]?.title
        });
        console.log(`opened:`, ` ${data?.link}`);
        window.open(data?.link, '_self');
        // window.location.replace(data.link);
        // const link = new URL(data?.link);
        // if (link) {
        //   navigate(link?.pathname + link?.search);
        // }
      }}
      sx={{
        padding: '1em',
        border: '2px solid #F2F2F2',
        borderRadius: '8px',
        margin: '1em 0 0em 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 2px 0px 0px #044DA14D',
        width: '100%',
        bgcolor: '#ffffff'
        // position: 'relative',
      }}>

      <Grid
        item
        xs={2}
        sm={2}
        sx={{
          marginRight: '1em', padding: '4px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
        {data.image ? <img src={data.image} style={{ width: '100%' }} alt="org" /> :
          <BackgroundLetterAvatars name={data.name} sx={{width: '40px', height: '40px'}} />}
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}>
        <Typography variant="h6" sx={{ margin: 0, overflowWrap: 'anywhere' }}>
          {data?.formName}
        </Typography>
        <Typography
          variant="body2" color="grey" sx={{ margin: 0, overflowWrap: 'anywhere' }}>
          {data.name}
          {/* <ShowMore
            text={data.credentials.join(' • ')}
          /> */}
        </Typography>

      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
          padding: 0, margin: 0,
          fontWeight: 600
        }}>
        <Typography
          variant="body2" color={states[data.state]?.color}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {states[data.state]?.icon}
          {states[data.state]?.title}
        </Typography>

      </Grid>
    </ListItemButton>
  );
}
