/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-no-duplicate-props */
// *https://www.registers.service.gov.uk/registers/country/use-the-api*

import { Autocomplete, CircularProgress, ListItemText, Paper, styled, useTheme } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import CustomTextField from './Input/customTextField';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}


export default function SearchAutocomplete({ id, label, updateSelectedKey, selectedKey,
  apiTrigger, getName, initialText = '', sx = {}, error = false, other = { name: 'Other', value: 'Other' } }) {
  const theme = useTheme();
  const listboxRef = useRef(null);
  const myRef = useRef(null);
  const [searchText, setSearchText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [timeoutInstance, setTimeoutInstance] = React.useState({ cancel: () => { } });
  const [seed, setSeed] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  useEffect(() => listboxRef.current?.scrollIntoView());

  const callApi = useCallback(debounce(async (searchThis, newSeedValue, actualOptions = options, ref = listboxRef?.current) => {
    setLoading(true);
    if (ref) {
      // ref.scrollTop -= 20;
    }
    const response = await apiTrigger(searchThis, newSeedValue);
    console.log('response:', response);
    console.log('scrollHeight:before', ref?.scrollHeight);
    let lastScrollHeight = 0;
    if (ref) {
      lastScrollHeight = ref.scrollHeight;
    }
    setOptions([...actualOptions, ...response?.list]);
    if (ref) {
      console.log('scrollHeight:after:1', ref?.scrollHeight);


      setTimeout(() => {
        const scrollDiff = ref.scrollHeight - lastScrollHeight;
        if (newSeedValue === 0) {
          ref.scrollTop = 0;
        } else {
          ref.scrollTop = lastScrollHeight;
        }
      }, 100);
    }
    setTotal(response.total);
    setSeed(newSeedValue);
    setLoading(false);
  }, 1000), [options]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      callApi(searchText, 0, []);
    }
  }, []);

  React.useEffect(() => {
    // setSearchText(initialText);
  }, []);

  return (
    <Autocomplete
      id={id}
      ref={myRef}
      sx={{
        // 'zIndex': 999999999,
        'margin': '0',
        '& .MuiInput-root': {
          color: 'currentColor',
          fontFamily: 'Lato',
          fontSize: '1.32rem',
        },
        '& .MuiInputLabel-root': {
          // color: 'rgb(0 0 0 / 44%)',
          fontFamily: 'Lato',
          fontSize: '1.4rem',
        }, '& .MuiInputBase-input': {
          color: 'currentColor',
          fontFamily: 'Lato',
          fontSize: '1.32rem',
          fontWeight: 300
        },
      }}
      required
      selectOnFocus
      style={{ width: '100%', ...sx }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={(e, reason) => {
        console.log('rest', ...reason);
        if (reason === 'toggleInput') { setOpen(false); }
      }}
      onChange={(option, value, reason) => {
        if (reason === 'clear') {
          setSearchText('');
          callApi('', 0, []);
          updateSelectedKey(null);
          setOpen(true);
        } else {
          console.log('option, value', option, value);
          updateSelectedKey(value);
          setOpen(false);
        }
        // handleClose();
        return option === value;
      }}
      getOptionLabel={(option) => getName(option).primary}
      options={options}
      loading={loading && open}
      loadingText={<p style={{ fontSize: '1.3rem' }}>
        {' '}
        Loading...
      </p >}
      noOptionsText={<p style={{ fontSize: '1.3rem' }}>
        {' '}
        No options
      </p>}
      ListboxProps={{
        sx: {
          //   minHeight: 200,
          'maxHeight': '50vh',
          'textAlign': 'left',
          '& .MuiAutocomplete-popupIndicatorOpen': {
            // 'zIndex': '99999 !important',
            'textAlign': 'left'
          },
          'scrollBehavior': 'smooth'
        },
        onScroll: async (event) => {
          const listboxNode = event.currentTarget;
          console.log(
              '====aaaa====', listboxNode.scrollTop, listboxNode.clientHeight, listboxNode.scrollHeight, parseInt(listboxNode.scrollTop + listboxNode.clientHeight),
              parseInt(listboxNode.scrollTop + listboxNode.clientHeight) <= listboxNode.scrollHeight
          );
          if (parseInt(listboxNode.scrollTop + listboxNode.clientHeight) >= listboxNode.scrollHeight - 5) {
            console.log('====bb====');
            // listboxNode.scrollTop -= 50;
            if (options?.length < total) {
              callApi(searchText, seed + 50, undefined, listboxNode);
            }
          }
        },
        ref: listboxRef
      }}
      renderOption={(props, option, { inputValue }) => {
        let matches; let parts;
        const name = getName(option);
        if (name.primary) {
          matches = match(name.primary, inputValue, { insideWords: true });
          parts = parse(name.primary, matches);
        }
        // console.log("parts", parts, name, option, inputValue);
        return (
          <ListItemText
            {...props}
            style={{
              'display': 'flex',
              'flexDirection': 'column',
              'alignItems': 'flex-start',
              // "alignItems": "center",
              'overflowWrap': 'anywhere',
              'textAlign': 'left'
            }}
            id={name}
            primary={parts?.map((part, index) => (
              <span
                key={`${part.text}-${index}`}
                style={{
                  fontWeight: part.highlight ? 700 : 400,
                  textAlign: 'left',
                  fontSize: '1.3rem'
                }}
              >
                {part.text}
              </span>
            ))}
            secondary={name.secondary}
          />
        );
      }}
      renderInput={(params) => (
        <CustomTextField
          // autoFocus
          {...params}
          required
          style={{ width: 'webkit-fill-available' }}
          placeholder={searchText !== '' ? searchText : initialText}
          label={label}
          variant="outlined"
          value={searchText}
          onFocus={(e) => {
            e.preventDefault();
            e.stopPropagation();
            callApi(e.target.value, 0, []);
            // setOpen(true);
          }}
          onBlur={() => {
            setSearchText('');
            setLoading(true);
            setOpen(false);
          }}
          onChange={(e) => {
            callApi(e.target.value, 0, []);
            setSearchText(e.target.value);
          }}
          //   inputProps={{
          //     style: {
          //       padding: 5
          //     }
          //   }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(loading && open) ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
                {/* <IconButton onClick={() =>{
                    setSearchText('');
                    callApi(searchText, 0, []);
                    updateSelectedKey(other);
                    setOpen(true);
                  }}>
                    <Clear />
                  </IconButton>

                  <IconButton
                    sx={{padding: '2px'}} onClick={() =>{
                      setSearchText('');
                      callApi(searchText, 0, []);
                      updateSelectedKey('');
                      setOpen(true);
                    }}>
                    <ArrowDropDown />
                  </IconButton> */}
              </>
            ),
            sx: { marginBottom: '2px' }
          }}
          error={error}
          helperText={<span>
            Unable to find
            {' '}
            {label}
            ?
            {' '}
            <a
              style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setSearchText(other.value);
                myRef.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')?.[0]?.click();
                updateSelectedKey(other);
                setOpen(false);
              }}>
              Add Other
            </a>
          </span>}
          FormHelperTextProps={{
            sx: {
              marginTop: 0,
            }
          }}
        />
      )}
    />

  );
}

const StyledMuiAutocompletePaperComponent = styled(Paper)(({ theme }) => ({
  'margin': 0,
}));
