
export function getInsufficiencyList(responseList, insufficiencyDataList, accResolved) {
  console.log('insuf--::', responseList, insufficiencyDataList);
  const acc = [];

  responseList?.forEach(((insuf, i) => {
    console.log('insuf-1', insuf);

    if (!insufficiencyDataList) {
      acc.push(insuf);
    } else if (insufficiencyDataList) {
      const text = insufficiencyDataList?.inputInsufficiencyDataList
          ?.find((item) => item.insufficiencyId === insuf.insufficiencyId)?.value;
      const files = insufficiencyDataList?.documents?.filter((z) => {
        if (z?.document?.label?.startsWith(`${insuf.insufficiencyId}::FILE::`)) {
          return true;
        } else {
          return false;
        }
      });
      accResolved.insuf.push(insuf);
      accResolved.resolution[insuf.insufficiencyId] = {
        text,
        files
      };
    }

    // if (insufficiencyDataList?.every((x) => !x.idList?.includes(insuf?.insufficiencyId))) {
    //   acc.push(insuf);
    // } else if (insufficiencyDataList?.length <= 0) {
    //   acc.push(insuf);
    // } else if (!insufficiencyDataList) {
    //   acc.push(insuf);
    // }
  }));

  return { unresolved: acc, resolved: accResolved };
}
