/* eslint-disable new-cap */
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getHash, getWallet } from '../../Config/api';
import { UIStore, fetchFromStore } from '../../Config/store';
import { mixpanel, trackingEvents } from '../../Monitoring/Mixpanel';
import { links, tawkToConfig } from '../../Utils/enums';

export default function TawkTo({ }) {
  const tawkToRef = useRef(null);
  const location = useLocation();
  const tawkTo = process.env.REACT_APP_TEST ? null : tawkToConfig(process.env.REACT_APP_ORG);
  const [hash, setHash] = useState('');
  const isAuthenticated = UIStore.useState((s) => s.isAuthenticated);

  useEffect(() => {
    if (tawkTo && process.env.REACT_APP_ORG !== 'TEST') {
      let intervalToFindTawkto = null;
      if (location.pathname.includes(`/${links.EKYC}`)) {
        intervalToFindTawkto = setInterval(() => {
          const d = document.getElementsByClassName('widget-visible')?.[0];
          if (window.Tawk_API && window.Tawk_API.showWidget) {
            window.Tawk_API.showWidget();
            clearInterval(intervalToFindTawkto);
          }
          if (d) {
            d.setAttribute('style', 'display: block !important;');
            clearInterval(intervalToFindTawkto);
          }
        }, 500);
      } else {
        intervalToFindTawkto = setInterval(() => {
          const d = document.getElementsByClassName('widget-visible')?.[0];
          if (d) {
            // window.Tawk_API.hideWidget();
            d.setAttribute('style', 'display: none !important;');
            tawkToRef.current.autoStart = false;
            clearInterval(intervalToFindTawkto);
          }
        }, 500);
      }
    }
  }, [location.pathname]);


  // closure for sending fresh attributes whenever they change, gets triggered everytime the user sends message
  function onChatMessageVisitor() {
    const obj = {};
    function trigger(hash) {
      try {
        console.log('tawkto: somethingChanged-before', obj);
        const searched = fetchFromStore('searchedQuery');
        let somethingChanged = false;
        if (searched.txnId && searched.txnId !== obj['transaction-id']) {
          obj['transaction-id'] = searched.txnId;
          somethingChanged = true;
        }
        if (searched.orgId && searched.orgId !== obj['org-id']) {
          obj['org-id'] = searched.orgId;
          somethingChanged = true;
        }
        if (somethingChanged) {
          console.log('tawkto: somethingChanged', obj);
          tawkToRef?.current?.setAttributes({
            ...obj,
            hash
          }, function(error) {
            console.log('tawkto: error: onChatMessageVisitor-setAttributes', error);
            // do something if error
          });
        }
      } catch (error) {
        console.log('tawkto: error: onChatMessageVisitor', error);
      }
    }
    return trigger;
  }

  const triggerOnChatMessageVisitor = onChatMessageVisitor();

  return tawkTo ?
    <TawkMessengerReact
      propertyId={tawkTo.propertyId}
      widgetId={tawkTo.widgetId}
      ref={tawkToRef}
      onLoad={async () => {
        if (isAuthenticated) {
          window.Tawk_API.showWidget();
          const wallet = await getWallet();
          const tempHash = await getHash(wallet?.data?.mobile);
          const userInput = fetchFromStore('userInput');
          console.log('tawkToRef.current', tawkToRef.current, tempHash);
          setHash(tempHash);
          window.tawkToReactElement = tawkToRef.current;

          try {
            tawkToRef?.current?.setAttributes({
              'phone': wallet?.data?.mobile,
              'name': `${wallet?.data?.mobile} , ${wallet?.data?.profileDetails.name ?? '-'}`,
              'hash': tempHash,
            }, function(error) {
              console.log('tawkto: error: onLoad-setAttributes', error);
              // do something if error
            });
          } catch (error) {
            console.log('tawkto: error: onLoad', error);
          }
        } else {
          window.Tawk_API.hideWidget();
        }
      }}
      // onChatMessageVisitor={() => { triggerOnChatMessageVisitor(hash); }}
      onChatStarted={() => {
        const searchedQuery = fetchFromStore('searchedQuery');
        mixpanel.track(trackingEvents.tawkTo.events.chatStart, {
          location: window.location.href,
          orgId: searchedQuery?.orgId,
          txnId: searchedQuery?.txnId
        });
      }}
    /> :
    null;
}


