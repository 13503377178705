/* eslint-disable */
import * as Sentry from "@sentry/react";
import axios from 'axios';
import { logout } from "./store";

const ErrorStatusCodes = [400, 401, 403, 404, 422, 500, 503, 504]

const addInterceptors = (instance) => {
  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      console.error('Intercepting request error', error);
      window.snackbar('error', error.message);
      // return Promise.reject(error);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      console.log('response:interceptors:', response);

      if (ErrorStatusCodes?.includes(response?.status)) {
        Sentry.captureException(response);
      }

      if (response.status === 504) {
        window.snackbar('error', 'Server is down, please try again later');
      }

      if (response.status === 401) {
        // logout();
        // return null;
      }
      if (response.data.code === 138) {
        // logout();
        window.navigate('/setup?loggedIn=true', {
          state: {
            link: window.location.href.split(window.location.origin)[1],
            nextState: { moveNext: true }
          }, disableBack: true
        });
      }
      return response;
    },
    (error) => {
      console.error('Intercepting outside 2xx response error,interceptors:', error.message, error);
      if (ErrorStatusCodes?.includes(error?.response?.status)) {
        Sentry.captureException(error);
      }
      if (error.code === 'ECONNABORTED') {
        window.snackbar('error', 'Your internet connection may be slow. Please try with a better connection');
        return null;
      }

      const shouldLogout = error?.response?.data?.code <= -10 && error?.response?.data?.code >= -12;
      if (shouldLogout) {
        logout(true);
      }
      if (error?.response?.data?.code === 138) {
        // logout();
        window.navigate('/setup?loggedIn=true', {
          state: {
            link: window.location.href.split(window.location.origin)[1],
            nextState: { moveNext: true }
          }, disableBack: true
        });
      }

      if (error?.response?.data?.code !== 104 && error?.response?.data?.code !== 105 && !error?.config?.headers?.noSnackbar && !shouldLogout) {
        console.log('Intercepting error: ', error);
        window.snackbar('error', error?.response?.data?.message ?? error?.message);
      }
      return Promise.reject(error);
    }
  );
};

const addInterceptorsWithSentryOnly = (instance) => {

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => {

      if (ErrorStatusCodes?.includes(response?.status)) {
        Sentry.captureException(response);
      }

      return response;
    },
    (error) => {
      const shouldLogout = error?.response?.data?.code <= -10 && error?.response?.data?.code >= -12;
      if (shouldLogout) {
        logout(true);
      }
      if (error?.code === 'ECONNABORTED') {
        window.snackbar('error', 'Your internet connection may be slow. Please try with a better connection');
        return null;
      }
      console.error('Intercepting outside 2xx response error,sentry interceptors:', error.message, error);
      if (ErrorStatusCodes?.includes(error?.response?.status)) {
        Sentry.captureException(error);
      }
      return Promise.reject(error);
    }
  );
};

const TIMEOUT_MILLISECONDS = 10 * 60 * 1000;

const baseURLs = {
  base: `${process.env.REACT_APP_BASE_URL}`,
  digilocker: `${process.env.REACT_APP_DIGILOCKER_URL}`,
  issuer: `${process.env.REACT_APP_ISSUER_TRENTIAL_URL}`,
  ekyc: `${process.env.REACT_APP_EKYC_URL}`,
  webWallet: `${process.env.REACT_APP_WEB_WALLET_URL}`,
  payment: process.env.REACT_APP_PAYMENT_URL,
  notifications: `${process.env.REACT_APP_NOTIFICATIONS_URL}`,
  utility: `${process.env.REACT_APP_UTILITY_URL}`
  // ekyc: 'https://ssp.aashishprasad-c.repl.co'
  // ekyc: 'https://indisi-gateway-dev-7kuqnnxqla-em.a.run.app/verification'
};


const disableCacheInInstance = (ax) => {
  ax.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  };
  return ax;
};

const axiosBaseInstance = axios.create({
  baseURL: baseURLs.base,
  timeout: TIMEOUT_MILLISECONDS ?? 10000,
});
addInterceptors(disableCacheInInstance(axiosBaseInstance));

const axiosNotificationsInstance = axios.create({
  baseURL: baseURLs.notifications,
  timeout: TIMEOUT_MILLISECONDS ?? 10000,
});
addInterceptors(disableCacheInInstance(axiosNotificationsInstance));

const axiosDigilockerInstance = axios.create({
  baseURL: baseURLs.digilocker,
  timeout: TIMEOUT_MILLISECONDS ?? 10000,
});
addInterceptors(disableCacheInInstance(axiosDigilockerInstance));

const axiosIssuerTrentialInstance = axios.create({
  baseURL: baseURLs.issuer,
  timeout: TIMEOUT_MILLISECONDS ?? 10000,
});
addInterceptors(disableCacheInInstance(axiosIssuerTrentialInstance));

const axiosEkycInstance = axios.create({
  baseURL: baseURLs.ekyc,
  timeout: TIMEOUT_MILLISECONDS ?? 10000,
});

addInterceptors(disableCacheInInstance(axiosEkycInstance));

const axiosWebWalletInstance = axios.create({
  baseURL: baseURLs.webWallet,
  timeout: TIMEOUT_MILLISECONDS ?? 10000,
});

addInterceptors(disableCacheInInstance(axiosWebWalletInstance));

const axiosPaymentInstance = axios.create({
  baseURL: baseURLs.payment,
  timeout: TIMEOUT_MILLISECONDS ?? 10000,
});

addInterceptors(disableCacheInInstance(axiosPaymentInstance));

const axiosBaseInstanceWithSentryOnly = axios.create({
  baseURL: baseURLs.base,
  timeout: TIMEOUT_MILLISECONDS ?? 10000,
});
addInterceptorsWithSentryOnly(disableCacheInInstance(axiosBaseInstanceWithSentryOnly));

const axiosUtilityInstance = axios.create({
  baseURL: baseURLs.utility,
  timeout: TIMEOUT_MILLISECONDS ?? 10000,
});
addInterceptors(disableCacheInInstance(axiosUtilityInstance));

export const getDefaultAxiosInstance = (url) => {
  const defaultAxiosInstance = axios.create({
    ...(url ? { baseURL: url, } : {}),
    timeout: TIMEOUT_MILLISECONDS ?? 10000,
  });
  addInterceptorsWithSentryOnly(disableCacheInInstance(defaultAxiosInstance));
  return defaultAxiosInstance;
}

const axiosInstanceWithoutBaseUrl = getDefaultAxiosInstance()

export {
  axiosBaseInstance, axiosBaseInstanceWithSentryOnly, axiosDigilockerInstance,
  axiosEkycInstance, axiosInstanceWithoutBaseUrl, axiosIssuerTrentialInstance,
  axiosNotificationsInstance, axiosPaymentInstance, axiosUtilityInstance, axiosWebWalletInstance
};

