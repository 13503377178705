/* eslint-disable */
import { CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SimpleBackground from '../../Components/Background/Simple';
import ProviderCard from '../../Components/Provider';
import { getPaymentRegionList } from '../../Config/payment/api';
import { UIStore } from '../../Config/store';
import { useQuery } from '../../Helper/helpers';

const Provider = () => {
  const navigate = useNavigate();
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const searchParams = useQuery();
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const orgId = searchedQuery?.orgId
  const txId = searchedQuery?.txnId
  const link = searchedQuery?.link
  const redirectedFrom = searchParams.get("redirectedFrom")

  const fetchList = async () => {
    const response = await getPaymentRegionList(orgId, txId)
    setLoading(false)
    if (response) {
      setList(response?.data)
      if (response?.data?.length == 0) {
        window.snackbar('error', 'No payment options available.')
        window.history.back()
      }
      if (response?.data?.length == 1) {
        if(redirectedFrom){
          navigate(link)
        }
        else{
          navigate(`/payment?region=${response?.data[0]?.region}`);
        }
      }
    }
    else {
      window.snackbar('error', 'Unable to fetch payment options')
    }
  }

  useEffect(() => {
    fetchList()
  }, [])

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }
  return <SimpleBackground
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 1,
      padding: '0',
      margin: 'auto',
    }}
    title={'Payment Details'}>

    <Grid container justify="space-between" sx={{ mt: 2, mb: 0, }}>
      <Typography sx={{ textAlign: 'left' }} variant="h1" component="h2">
        Select mode of Payment
      </Typography>
    </Grid>
    {list?.map((item, key) => {
      return <ProviderCard data={{ label: item.name, name: item.name, info: item.info, logo: item.logo, link: `/payment?region=${item.region}` }} />
    })}
  </SimpleBackground>
};

export default Provider;
