import step1 from '../../../../../Assets/wes-login/instructions-step-1.svg';
import step2 from '../../../../../Assets/wes-login/instructions-step-2.svg';
import step3 from '../../../../../Assets/wes-login/instructions-step-3.svg';
import step4 from '../../../../../Assets/wes-login/instructions-step-4.svg';
import step5 from '../../../../../Assets/wes-login/instructions-step-5.svg';

export const INSTRUCTIONS = [
  {
    title: 'Identity Verification – Aadhaar or Passport',
    desc: 'Follow the instructions to complete the identity verification.',
    imgSrc: step2,
    gridSize: 4,
    imgStyle: {height: {xs: '7.8rem', sm: '9.5rem', md: '11rem', blg: '13rem'}, margin: '2.8rem 0 !important'}
  },
  {
    title: 'Enter WES Number & Verify your E-mail',
    desc: 'Enter your WES number and click the button to receive a 6-digit one-time password (OTP) on email registered with WES.',
    imgSrc: step1,
    gridSize: 4,
    imgStyle: {width: '100%', margin: '2.8rem 0 !important', mb: '3.5rem'}
  },
  {
    title: 'Document Upload',
    desc: 'Upload at least one of the educational qualifications to proceed further.',
    imgSrc: step3,
    gridSize: 4,
    imgStyle: {width: '100%', margin: '2.8rem 0 !important'}
  },
  {
    title: 'Review Uploaded Documents',
    desc: 'Ensure that all uploaded documents are accurate and legible, and review the information associated with each document.',
    imgSrc: step4,
    gridSize: 6,
    imgStyle: {height: {xs: '7rem', md: '9.5rem'}, margin: '2.8rem 0 !important'}
  },
  {
    title: 'Proceed to Payment',
    desc: 'After uploading all required documents, click "Pay" and follow the prompts to complete the payment. Prices may vary based on the number of documents uploaded.',
    imgSrc: step5,
    gridSize: 6,
    imgStyle: {height: {xs: '7rem', md: '9.5rem'}, margin: '2.8rem 0 !important'}
  }
];
