import { Grid, Link, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as React from 'react';
import FullScreenDialog from '../../../Components/UI/fullScreenDialog';
import { UIStore } from '../../../Config/store';

export default function NoAadhaarContactUs() {
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const currentUserData = UIStore.useState((s) => s.currentUserData);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>

      <Typography>
        Facing issues?
        <Link
          variant="text"
          onClick={handleClickOpen}
          sx={{ cursor: 'pointer', margin: '0.5em' }}>
          <b>
            Contact Us
          </b>
        </Link>
      </Typography>

      {/* <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      > */}

      <FullScreenDialog
        title={'Select the problem you are facing?'}
        open={open}
        setOpen={handleClose}
        fullScreen={false}
        color="primary">
        {/* <DialogTitle>
          Don't have Aadhaar?
        </DialogTitle> */}
        <DialogContent>

          <FormControl color="primary">
            <RadioGroup
              value={value}
              onChange={handleChange}
              color="primary"
            >
              <FormControlLabel color="primary" value="I am not an Indian Citizen" control={<Radio color="primary" />} label="I am not an Indian Citizen" />
              <FormControlLabel color="primary" value="I can't receive OTP for my Aadhaar" control={<Radio color="primary" />} label="I can't receive OTP for my Aadhaar" />
            </RadioGroup>
          </FormControl>

          {/* <Divider sx={{ width: '100%', marginTop: '1.5rem', color: '#DADADA' }} >
            <b>
              OR
            </b>
          </Divider> */}


        </DialogContent>
        <DialogActions sx={{ flexDirection: 'column' }}>
          <Typography sx={{ width: '100%', textAlign: 'left', paddingLeft: '1em' }}>
            Contact
            {' '}
            <b>
              {process.env.REACT_APP_ORG_MAIL}
            </b>
          </Typography>
          <Grid container justifyContent={'flex-end'}>
            <Button onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={!value}
              onClick={() => {
                const a = document.createElement('a');
                const text = `Dear Support Team, \n\n I am encountering an issue with filling out this form as it requires Aadhaar, which I currently do not possess. Your prompt assistance would be greatly appreciated. \n\n Reporter - ENTER_YOUR_NAME_HERE \n\n ${searchedQuery?.txnId ? `Transaction ID -${searchedQuery?.txnId}` : ''} \n  ${searchedQuery?.orgId ? `Organization ID -${searchedQuery?.orgId}` : ''} \n Mobile - ${currentUserData.mobile}`;
                a.href = `mailto:${process.env.REACT_APP_ORG_MAIL}?subject=Assistance Needed: ${value}&body=${encodeURIComponent(text)}`;
                a.click();
              }}>
              Send Mail
            </Button>
          </Grid>

        </DialogActions>
      </FullScreenDialog>
    </React.Fragment>
  );
}
