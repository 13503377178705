import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import theme from './Config/theme';
import './index.css';
import { initSentry } from './Monitoring/Sentry';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize(process.env.REACT_APP_GA_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'production') {
  initSentry();
}

// initGleap();

root.render(<ThemeProvider theme={theme}>
  <SnackbarProvider maxSnack={3}>
    <div style={{
      width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column'
    }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>

    </div>
  </SnackbarProvider>
</ThemeProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
