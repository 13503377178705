export default {
  stepTxt: {color: '#1C43C3', letterSpacing: '3.1px', fontWeight: '500', fontSize: {xs: '1.2rem', md: '1.4rem'}},
  descTxt: {color: '#494949 !important', fontWeight: '400 !important', lineHeight: '2.7rem !important'},
  gridContainer: {pl: {xs: '4.2rem', md: '4vw', lmd: '9vw', blg: '12.5vw', xl: '15.5vw'}, pr: {xs: '4.2rem', md: '4vw', lmd: '9vw', blg: '12.5vw', xl: '15.5vw'}},
  gridItemWrapper: {
    boxShadow: '0px 0px 12px -1px rgba(0, 0, 0, 0.14)',
    borderRadius: {xs: '8px', sm: '18px', lmd: '30px'},
    padding: '1.4rem 2.7rem',
    pb: '2.7rem',
    height: '100%'
  }
};
