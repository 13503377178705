
import { AccountCircle, CheckCircleOutline, ChevronRight } from '@mui/icons-material';
import { Avatar, Chip, ListItem, ListItemAvatar, ListItemButton, ListItemText, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import greenCheckThorned from '../../../Assets/greenCheckThorned.svg';
import { UIStore, updateStore } from '../../../Config/store';
import theme from '../../../Config/theme';
import { getFetchProcessInterval, triggerCancellationController, useQuery } from '../../../Helper/helpers';
import { ignoreKeysForProfile, statesEnum } from '../../../Utils/enums';

const ignoreKeys = ignoreKeysForProfile;
const colors = (c) => {
  if (c) {
    return {
      bgcolor: theme.palette.success.light,
      border: '1px solid #E7EDF6'
    };
  } else {
    return {
      bgcolor: '#ffffff',
      border: `2px solid ${theme.palette.primary.main}`
    };
  }
};

export default function BasicDetails({ enabledDocs, setEnabledDocs, setOpenStartingProcessDialog }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();
  const { state } = useLocation();
  const lottieRef = useRef(null);
  const [loading, setLoading] = React.useState(true);
  const [status, setStatus] = React.useState(false);
  const [identityKey, setIdentityKey] = useState('');
  const [selecetedCred, setSelecetedCred] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const form = UIStore.useState((s) => s.form) ?? {};
  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  const currentUserData = UIStore.useState((s) => s.currentUserData);
  const formUnderProcess = UIStore.useState((s) => s.formUnderProcess);
  const infoSubmitted = UIStore.useState((s) => s.infoSubmitted);
  const userInput = UIStore.useState((s) => s.userInput);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const [txInfo, setTxInfo] = React.useState({});
  // const identityKeyInfo = form?.list?.[identityKey] ? form?.list?.[identityKey] : configsForMandatoryFormsToFill?.list?.[identityKey];

  const onProfileCardClick = async () => {
    updateStore('currentWorkingStepperStep', null);
    if (currentUserData?.profileDetails?.name) {
      const subData = Object.keys(currentUserData.profileDetails)
          ?.reduce((acc, x) => {
            if (!ignoreKeys.includes(x)) {
              acc[x] = currentUserData.profileDetails[x];
            }
            return acc;
          }, { mobile: currentUserData?.mobile });
      if (subData) {
        updateStore('isAutoplay', false);
        clearInterval(getFetchProcessInterval());
        triggerCancellationController();
        // updateStore('currentWorkingStepperStep', identityKey);
        const tempz = subData;

        updateStore('docData', tempz);
        return navigate('/displayInfo', { state: { onlyShow: true, title: 'Profile' } });
      } else {
        navigate('/profile-form');
      }
    } else {
      navigate('/profile-form');
    }
  };

  const onUserInputCardClick = async ()=>{
    updateStore('isAutoplay', false);
    clearInterval(getFetchProcessInterval());
    triggerCancellationController();

    updateStore('currentWorkingStepperStep', 'user-input');
    navigate('/user-input', { state: { link: window.location.href } });

    setSelecetedCred(null);
  };

  const isUserInputSubmitted = userInput?.additionalInfo?.authOptions[0]?.page?.config?.[0]?.inputs?.[0]?.value &&
    ![statesEnum.NOT_INITIALIZED].includes(form?.state);
  return (
    <>
      {!form?.manualProfileRequired ?
        <ListItem
          component={ListItemButton}
          sx={{
            'flexGrow': 0,
            'border': '1px solid #E7EDF6',
            'borderRadius': '8px',
            'padding': '2em 0em',
            'margin': '1.5em 0em',
            'cursor': 'pointer',
            'boxShadow': '0px 4px 6px 0px #0000001A',
            ...colors(currentUserData?.profileDetails?.name)
          }}
          id={`profile-${currentUserData?.profileDetails?.name ? 'verified' : ''}`}
          secondaryAction={currentUserData?.profileDetails?.name ? <Chip
            color="success" variant="outlined"
            sx={{
              'border': 'none',
              '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
            }}
            size="small"
            icon={<img
              src={greenCheckThorned}
              style={{ width: '20px' }} />}
            label="Verified" /> :
            <ChevronRight
              color="grey" />
          }
          // onClick={async () => {
          //   // if (infoSubmitted[identityKey]) {
          //   //   updateStore('docData', infoSubmitted[identityKey]);
          //   //   return navigate('/displayInfo', { state: { onlyShow: true } });
          //   // } else

          //   if (!status?.statesForMandatoryStuffs?.existInWallet) {
          //     updateStore('isAutoplay', false);
          //     clearInterval(getFetchProcessInterval());
          //     triggerCancellationController();

          //     updateStore('currentWorkingStepperStep', identityKey);
          //     setTimeout(() => {
          //       navigate('/docs', { state: { openThisFlow: true } });
          //     }, 500);
          //   } else {
          //     const subData = Object.keys(currentUserData.profileDetails)
          //         ?.reduce((acc, x) => {
          //           if (!ignoreKeys.includes(x)) {
          //             acc[x] = currentUserData.profileDetails[x];
          //           }
          //           return acc;
          //         }, { mobile: currentUserData?.mobile });
          //     if (subData) {
          //       updateStore('isAutoplay', false);
          //       clearInterval(getFetchProcessInterval());
          //       triggerCancellationController();
          //       updateStore('currentWorkingStepperStep', identityKey);
          //       const tempz = subData;

          //       updateStore('docData', tempz);
          //       if (!identityKeyInfo?.error) {
          //         if (subData) {
          //           return navigate('/displayInfo', { state: { onlyShow: true } });
          //         }
          //       } else {
          //         window.snackbar('info', 'Details are being submitted, kindly wait a few minutes');
          //       }
          //     } else {
          //       window.snackbar('info', 'Details are being submitted, kindly wait a few minutes');
          //     }
          //   }

          //   setSelecetedCred(null);
          // }}
          onClick={() => {
            if (!currentUserData?.profileDetails?.name && form?.purposeRequired) {
              setOpenStartingProcessDialog({
                verification: 'profile',
                callback: async () => {
                  return onProfileCardClick();
                }
              });
            } else {
              return onProfileCardClick();
            }
          }}
          datacy="profile"
        >
          <ListItemAvatar sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
            padding: 0,
            minWidth: '40px'
          }}>
            <Avatar style={{
              // border: '1px solid #E7EDF6',
              backgroundColor: 'transparent',
              height: '30px',
              width: '30px', margin: '0 1em'
            }}>
              {/* <img
              src={identityKeyInfo?.logo}
              style={{
                minHeight: '15px',
                maxHeight: '25px', margin: '0', objectFit: 'contain'
              }} alt="logo" /> */}
              <AccountCircle color="black" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{ padding: '0 0.4rem', maxWidth: '66%' }}
            primary={'Profile'}
            primaryTypographyProps={{
              variant: 'body2',
              noWrap: true,
              sx: {
                margin: '0em',
                // color: c?.dependency?.credential ?
                //   (configsForMandatoryFormsToFill?.list?.[c.dependency.credential]?.done ?
                //     (configsForMandatoryFormsToFill?.list?.[c]?.done ? '#000000' : '#000000') :
                //     '#aaaaaa') :
                //   (configsForMandatoryFormsToFill?.list?.[c]?.done ? '#000000' : '#000000')
              }
            }}
            secondaryTypographyProps={{
              variant: 'body2',
              noWrap: true,
              sx: { margin: '0em', padding: '0em', maxWidth: '80%' }
            }}
          />
        </ListItem > : null
      }

      {
  userInput?.metadata ? <ListItem
    component={ListItemButton}
    sx={{
      'flexGrow': 0,
      'border': '1px solid #E7EDF6',
      'borderRadius': '8px',
      'padding': '2em 0em',
      'margin': '1.5em 0em',
      'cursor': 'pointer',
      'boxShadow': '0px 4px 6px 0px #0000001A',
      'bgcolor': (isUserInputSubmitted ? theme.palette.secondary.light : '#ffffff'),


      ...(form?.manualProfileRequired ? false : (!currentUserData?.profileDetails?.name) ? {
        bgcolor: theme.palette.grey.light,
        color: '#ffffff',
        pointerEvents: 'none',
        cursor: 'not-allowed',
      } : ((form?.manualProfileRequired ? false : (!currentUserData?.profileDetails?.name)) ? {
        'border': (isUserInputSubmitted ? '1px solid #E7EDF6' :
          `2px solid ${theme.palette.primary.main}`),
      } : {}))

    }}
    secondaryAction={isUserInputSubmitted ? <Chip
      color="primary" variant="outlined" sx={{
        'border': 'none',
        '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
      }} size="small" icon={<CheckCircleOutline style={{ width: '20px' }} />}
      label="Submitted" /> : <ChevronRight
        color="grey" />}

    onClick={() => {
      if (form?.state === 'NOT_INITIALIZED' && form?.purposeRequired) {
        setOpenStartingProcessDialog({
          verification: 'user-input',
          callback: async () => {
            return onUserInputCardClick();
          }
        });
      } else {
        onUserInputCardClick();
      }
    }}>
    <ListItemAvatar sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      minWidth: '40px'
    }}>
      <Avatar style={{
        // border: '1px solid #E7EDF6',
        backgroundColor: '#F6F6F6',
        height: '30px',
        width: '30px', margin: '0 1em'
      }}>
        <img
          src={userInput?.metadata?.issuerLogo}
          style={{
            minHeight: '15px',
            maxHeight: '25px', margin: '0', objectFit: 'contain'
          }} alt="logo" />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      sx={{ padding: '0 0.4rem', maxWidth: '66%' }}
      primary={userInput?.additionalInfo?.name}
      primaryTypographyProps={{
        variant: 'body2',
        noWrap: true,
        sx: {
          margin: '0em',
          // color: c?.dependency?.credential ?
          //   (form?.list?.[c.dependency.credential]?.done ?
          //     (form?.list?.[c]?.done ? '#000000' : '#000000') :
          //     '#aaaaaa') :
          //   (form?.list?.[c]?.done ? '#000000' : '#000000')
        }
      }}
      secondaryTypographyProps={{
        variant: 'body2',
        noWrap: true,
        sx: { margin: '0em', padding: '0em', maxWidth: '80%' }
      }}
    />
  </ListItem> : null
      }
    </>
  );
}
