/* eslint-disable */
import { getAccessToken } from '../../Utils/tokenUtils';
import { axiosEkycInstance, axiosPaymentInstance } from "../axios";
import routes from './routes';

const method = {
    GET: 'GET',
    POST: 'POST'
}

const getTxInfo = async (orgId, txnId, region, paymentId) => {
    try {
        let params = ''
        if (region) {
            if (process.env.REACT_APP_TEST && region === 'india') {
                params += "&region=test"
            } else {
                params += "&region=" + region
            }
        }
        if (paymentId) {
            params += "&paymentId=" + paymentId
        }
        const response = await axiosPaymentInstance({
            method: method.GET,
            url: `${routes.getTxInfo}?orgId=${orgId}&txnId=${txnId}${params}`,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'partner-org-id': process.env.REACT_APP_PARTNER_ORG_ID,
            }
        })
        console.log("txn info", response.data)
        // return mockResponses.getTxInfo
        return response.data
    }
    catch (e) {
        console.log("error in txn info", e)
        return false
        // return mockResponses.getTxInfo
    }
};

const getPaymentRegionList = async (orgId, txnId) => {
    try {
        const response = await axiosPaymentInstance({
            method: method.GET,
            url: `${routes.getPaymentRegionList}?orgId=${orgId}`,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'partner-org-id': process.env.REACT_APP_PARTNER_ORG_ID,
            }
        })
        console.log("txn info", response.data)
        // return mockResponses.getPaymentRegionList
        return response.data
    }
    catch (e) {
        console.log("error in txn info", e)
        return false
        // return mockResponses.getPaymentRegionList
    }
};

const initPayment = async (orgId, txnId, region) => {
    try {
        const response = await axiosPaymentInstance({
            method: method.POST,
            url: routes.initPayment,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'partner-org-id': process.env.REACT_APP_PARTNER_ORG_ID,
            },
            data: {
                orgId,
                txnId,
                region
            },
        })
        console.log("initPayment", response.data)
        // return mockResponses.initPayment
        return {
            data: response.data,
            error: false
        }
    }
    catch (e) {
        console.log("error in initPayment", e)
        return {
            error: true,
            message: 'Error in payment'
        }
        // return mockResponses.initPayment
    }
};

const getPaymentStatus = async (orgId, paymentId) => {
    try {
        const response = await axiosPaymentInstance({
            method: method.GET,
            url: `${routes.getPaymentStatus}?paymentId=${paymentId}&orgId=${orgId}`,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'partner-org-id': process.env.REACT_APP_PARTNER_ORG_ID,
            }
        })
        console.log("getPaymentStatus", response.data)
        // return mockResponses.getPaymentStatus
        return {
            data: response.data,
            error: false
        }
    }
    catch (e) {
        console.log("error in getPaymentStatus", e)
        return {
            message: e.toString(),
            error: true
        }
        // return mockResponses.getPaymentStatus
    }
};

const getTxInfoDetails = async (orgId, txnId) => {
    try {
        const response = await axiosEkycInstance({
            method: method.GET,
            url: `${routes.getEkycFormById}?orgId=${orgId}&txnId=${txnId}`,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'partner-org-id': process.env.REACT_APP_PARTNER_ORG_ID,
            }
        })
        console.log("getTxInfoDetails", response.data)
        // return mockResponses.getPaymentStatus
        return response.data
    }
    catch (e) {
        console.log("error in getTxInfoDetails", e)
        return false
        // return mockResponses.getPaymentStatus
    }
}

const getPaidTxDetails = async (orgId, txnId, orderId) => {
    try {
        const response = await axiosEkycInstance({
            method: method.GET,
            url: `${routes.getPaidTxDetails}/${orderId}/price?orgId=${orgId}&txnId=${txnId}`,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'partner-org-id': process.env.REACT_APP_PARTNER_ORG_ID,
            }
        })
        console.log("getPaidTxDetails", response.data)
        // return mockResponses.getPaymentStatus
        return response.data
    }
    catch (e) {
        console.log("error in getPaidTxDetails", e)
        return false
        // return mockResponses.getPaymentStatus
    }
}


const getTxHistory = async (params) => {
    try {
        let str = '';
        Object.keys(params).forEach(key => {
            if (!str) {
                str += "?" + key + "=" + params[key];
            } else {
                str += `&${key}=${params[key]}`
            }
        })
        const response = await axiosEkycInstance({
            method: method.GET,
            url: routes.getTxHistory + str,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
                'partner-org-id': process.env.REACT_APP_PARTNER_ORG_ID,
            }
        })
        console.log("getTxHistory", response.data)
        // return mockResponses.getPaymentStatus
        return response.data
    }
    catch (e) {
        console.log("error in getTxHistory", e)
        return false
        // return mockResponses.getPaymentStatus
    }
}

const capturePaymentPaypal = async (orgId, paymentId) => {
    try {
        const response = await axiosEkycInstance({
            method: method.POST,
            url: `${routes.capturePaymentPaypal}`,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
            },
            data: {
                orgId,
                paymentId
            }
        })
        console.log("capturePaymentPaypal", response.data)
        // return mockResponses.capturePaymentPaypal
        return {
            data: response.data,
            error: false
        }
    }
    catch (e) {
        console.log("error in capturePaymentPaypal", e)
        return {
            message: e.toString(),
            error: true
        }
        // return mockResponses.getPaymentStatus
    }
};

const downloadInvoice = async (orgId, paymentId) => {
    try {
        const response = await axiosEkycInstance({
            method: method.POST,
            url: `${routes.downloadInvoice}`,
            headers: {
                'Authorization': `Bearer ${getAccessToken()}`,
            },
            data: {
                orgId,
                paymentId
            }
        })
        console.log("downloadInvoice", response.data)
        // return mockResponses.downloadInvoice
        return {
            data: response.data,
            error: false
        }
    }
    catch (e) {
        console.log("error in downloadInvoice", e)
        return {
            message: e.toString(),
            error: true
        }
        // return mockResponses.getPaymentStatus
    }
};
// /transactions/payment/capture/paypal


export { capturePaymentPaypal, downloadInvoice, getPaidTxDetails, getPaymentRegionList, getPaymentStatus, getTxHistory, getTxInfo, getTxInfoDetails, initPayment };

