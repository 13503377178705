import { Clear, Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import trentialWithText from '../../Assets/trentialWithText.svg';
import { getOrg } from '../../Config/api';
import { UIStore, UIStoreLocal, patchStore, updateStore, updateStoreLocal } from '../../Config/store';

import Notifications from '../../Containers/Notifications';
import Splash from '../../Containers/Splash';
import { getFetchProcessInterval, removeLoggedIn, updateDocumentTitle, useQuery } from '../../Helper/helpers';
import { useIsDesktop } from '../../Helper/hooks';
import { links } from '../../Utils/enums';
import { getAccessToken } from '../../Utils/tokenUtils';
import { linksData, pathnameToText } from './data';

const drawerWidth = 240;
const removeFooter = [`${links.EKYC}`, 'docs', 'displayInfo', 'liveness', 'submitted', 'digilocker'];
const goSolo = ['login'];

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
  /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
  position: 'relative',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function Header({ children = <></>, showSplash = false }) {
  const theme = useTheme();
  const params = useParams();
  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const linkWithoutLoggedIn = removeLoggedIn(window.location.href);

  const [loadingOrgDetails, setLoadingOrgDetails] = React.useState(false);
  const [orgDetails, setOrgDetails] = React.useState({ name: '', image: '' });
  const drawer = UIStore.useState((s) => s.drawer);
  const isAuthenticated = UIStore.useState((s) => s.isAuthenticated);
  const openDemo = UIStore.useState((s) => s.openDemo);
  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  const showWelcome = UIStoreLocal.useState((s) => s.showWelcome) ?? {};
  const txnId = params?.txnId || searchedQuery?.txnId;
  const matchesAboveSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesAboveLg = useMediaQuery(theme.breakpoints.up('lg'));

  const form = UIStore.useState((s) => s.form);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];

  console.log('matchesAboveSm, isDesktop', matchesAboveSm, isDesktop, orgDetails);

  const open = drawer;
  const setOpen = (b) => updateStore('drawer', b);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSplash = async () => {
    const linkObj = {};
    if (query.get('orgId')) {
      linkObj.orgId = query.get('orgId');
    }
    if (query.get('txId')) {
      linkObj.txnId = query.get('txId');
    }
    patchStore('searchedQuery', linkObj);

    if (query.get('orgId')) {
      setLoadingOrgDetails(true);
      const res = await getOrg(query.get('orgId'));
      if (res) {
        setOrgDetails(res);
        if (!showWelcome?.[linkWithoutLoggedIn]) {
          updateStoreLocal('showWelcome', {
            ...showWelcome,
            [linkWithoutLoggedIn]: false
          });
        } else {
          if (!isAuthenticated) {
            updateStoreLocal('showWelcome', {
              [linkWithoutLoggedIn]: false
            });
            navigate('/login', {
              state: {
                link: window.location.href.split(window.location.origin)[1],
                nextState: { moveNext: true }
              }
            });
          }
        }
      }
      setLoadingOrgDetails(false);
    }
  };

  React.useEffect(() => {
    if (showSplash) {
      handleSplash();
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (!(location.pathname).includes(links.EKYC)) {
      clearInterval(getFetchProcessInterval());
      updateDocumentTitle([pathnameToText(location.pathname, searchedQuery?.link)]);
    }
  }, [location.pathname, searchedQuery.link]);


  return showSplash && (!showWelcome?.[linkWithoutLoggedIn] && query.get('orgId') ||
  (!isAuthenticated && query.get('orgId'))) ?
    <Splash
      moveNext={() => {
        updateStoreLocal('showWelcome', {
          ...showWelcome,
          [linkWithoutLoggedIn]: true
        });

        // setTimeout(() => {
        //   const data = getAccessToken();
        //   if (!data) {
        //     navigate('/login', { state: { link: window.location.href.split(window.location.origin)[1] } });
        //   }
        // }, 1000);
      }}
      orgName={orgDetails.name}
      orgImage={orgDetails.image}
      additionalDetail={orgDetails.additionalDetail}
      loadingOrgDetails={loadingOrgDetails} /> : (
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: 0, height: '100vh' }} id="header-box">
        <Box
          component="main" sx={{
            width: '100%',
            height: '100vh',
            overflowY: 'auto',
            display: 'flex', flexDirection: 'column',
            flex: 1, bgcolor: isDesktop ? '#F5FCFF' : '#ffffff'
          }}>
          {(matchesAboveSm && !goSolo.includes(location.pathname.split('/')[1])) ? <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            columnGap={1}
            sx={{ width: matchesAboveLg ? '80%' : '100%', margin: '0 auto', height: '100%' }}
          >
            {isAuthenticated ? <Grid
              item xs sx={{
                // height: getCalc('100vh', `${Number(HEIGHT_OF_TOOLBAR + 30)}px`),
                overflowY: 'auto'
              }}>
              <div style={{ backgroundColor: '#ffffff', borderRadius: '16px', margin: '1em' }}>
                <ListOfNavigation
                  linksData={linksData} onClick={(text) => {
                    linksData[text].onClick(navigate);
                    setOpen(false);
                  }} />
              </div>

            </Grid> : null}
            <Grid
              item xs={isAuthenticated ? 6 : 10} sx={{
                // height: getCalc('100vh', `${Number(HEIGHT_OF_TOOLBAR)}px`),
                overflowY: 'auto',
                // borderRadius: '16px',
                margin: isAuthenticated ? '0' : 'auto',
                paddingTop: '1rem',
                display: 'flex', flexDirection: 'column',
                maxHeight: window.innerHeight,
              }}>
              <Outlet />
              {showSplash ? (getAccessToken() ? children : null) : children}
              {/* {!removeFooter.includes(location.pathname.split('/')[1]) ?
              <Toolbar /> : null} */}
              {/* {!removeFooter.includes(location.pathname.split('/')[1]) ?
              <BottomComponent>
                <PoweredByTrential />
              </BottomComponent> : null} */}
            </Grid>
            {isAuthenticated ? <Grid
              item xs sx={{
                // height: getCalc('100vh', `${Number(HEIGHT_OF_TOOLBAR + 30)}px`),
                overflowY: 'auto'
              }}>
              {!['notifications'].includes(location.pathname.split('/')[1]) ? <Notifications /> : null}
            </Grid> : null}
          </Grid> :
            <Grid
              sx={isDesktop ? ({
                display: 'flex', flexDirection: 'column',
                justifyContent: 'flex-start', alignItems: 'flex-start',
                height: '100vh',
                // maxWidth: '400px',
              }) : ({
                maxWidth: '100vw',
                flex: 1,
                display: 'flex', flexDirection: 'column',
                justifyContent: 'flex-start', alignItems: 'flex-start',
                height: '100vh',
              })}
            >
              <Outlet />
              {showSplash ? (getAccessToken() ? children : null) : children}
              {/* {(!isDesktop && !removeFooter.includes(location.pathname.split('/')[1])) ?
              <Toolbar /> : null} */}
              {/* {!removeFooter.includes(location.pathname.split('/')[1]) ?
              <BottomComponent noFlex={isDesktop}>
                <PoweredByTrential />
              </BottomComponent> : null} */}
            </Grid>}

        </Box>


        <Drawer
          sx={{
            'width': drawerWidth,
            'flexShrink': 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DrawerHeader sx={{ width: '100%' }}>
            {process.env.REACT_APP_SHOW_TRENTIAL_BRANDING ?
              <img
                src={trentialWithText}
                style={{ width: '100px' }}
                alt="trentialWithText" /> :
              null}
            <IconButton
              onClick={handleDrawerClose}
              sx={{ marginLeft: 'auto' }}>
              <Close />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <ListOfNavigation
            linksData={linksData} onClick={(text) => {
              linksData[text].onClick(navigate);
              setOpen(false);
            }} />
        </Drawer>

        {process.env.REACT_APP_WES_AUTH ? <Dialog
          maxWidth="md"
          fullWidth
          open={openDemo}
          onClose={() => { updateStore('openDemo', false); }}
          sx={{ padding: '1em' }}
        >
          <DialogTitle>
            <Typography variant="body1">
              Watch Demo
            </Typography>
            <IconButton
              sx={{ position: 'absolute', top: '0px', right: '0.5em' }}
              onClick={() => {
                updateStore('openDemo', false);
              }}>
              <Clear />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ padding: '1em', width: '100%' }}>


            <video style={{ width: '100%', margin: 'auto' }} controls controlsList="nodownload">

              <source src={process.env.REACT_APP_DEMO_VIDEO_LINK} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </DialogContent>
        </Dialog> : null}
      </Box>
    );
}

const ListOfNavigation = ({ linksData, onClick }) => {
  const theme = useTheme();
  return <List>
    {Object.keys(linksData)?.map((text, index) => (
      <ListItem
        key={text}
        id={text}
        disablePadding
        secondaryAction={linksData[text]?.secondaryAction}
      >
        <ListItemButton
          color="primary"
          onClick={() => {
            onClick(text);
          }}>
          <ListItemIcon
            color="primary"
            sx={{ minWidth: '0', margin: '8px' }}>
            {linksData[text].icon(window.location.pathname === linksData[text]?.link ? 'primary' : '')}
          </ListItemIcon>
          <ListItemText
            primary={text}
            secondary={linksData[text].subtitle}
            primaryTypographyProps={{
              color: window.location.pathname === linksData[text].link ? 'primary' : ''
            }}
            secondaryTypographyProps={{
              color: theme.palette.secondary.main,
              fontStyle: 'italic',
              margin: '-1em 0'
            }} />
        </ListItemButton>
      </ListItem>
    ))}
  </List>;
};


{/* <AppBar position="fixed" open={open}>
<Toolbar sx={{
  paddingLeft: '0px !important',
  ...(matchesAboveSm ? { width: matchesAboveLg ? '80%' : '100%', margin: '0 auto' } : {})
}}>
  {pathnameToText(location.pathname) === 'Trential' ? <img
    src={trential}
    style={{
      width: '20px', padding: '0 0 0 4px'
    }} /> : pathnameToBackLink(location.pathname) === null ? null : (<IconButton
      color="inherit"
      edge="end"
      onClick={() => navigate(pathnameToBackLink(location.pathname))}
      sx={{}}
    >
      <ArrowBack />
    </IconButton>)}
  <Typography
    variant="body1" noWrap
    sx={{
      flexGrow: 1,
      fontWeight: 700, color: '#FFFFFF',
      marginLeft: pathnameToBackLink(location.pathname) === null ? '1em' : '6px'
    }} component="div">
    {pathnameToText(location.pathname)}
  </Typography>
 <IconButton
    color="inherit"
    edge="end"
    sx={{ margin: '0 5px 0 0' }}
    onClick={() => navigate('/notifications')}
  >
    <Badge badgeContent={4} color="error">
      <NotificationsOutlined />
    </Badge>
  </IconButton>
  {(isAuthenticated && !isDesktop) ? <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerOpen}
  >
    <MenuIcon />
  </IconButton> : null}
</Toolbar>
</AppBar> */}
