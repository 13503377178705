export const SOLUTIONS = [
  // {
  //   label: 'Open Trust Network (OTN)',
  //   url: ''
  // },
  {
    label: 'Skill Pass Mobile App',
    url: 'https://play.google.com/store/apps/details?id=com.skillpassnsdc&hl=en_AU&pli=1',
    target: '_blank'
  },
  // {
  //   label: 'API Stack',
  //   url: '',
  // },
  // {
  //   label: 'Verification Suite',
  //   url: ''
  // }
];

export const RESOURCES = [
  {
    label: 'Contact support',
    url: `mailto:${process.env.REACT_APP_ORG_MAIL}`,
    target: '_self'
  },
];

export const FOOTER_LINKS = [
  {
    label: 'Solutions',
    links: SOLUTIONS,
    gridSize: {
      xs: 6,
      sm: 6
    },
  },
  {
    label: 'Resources',
    links: RESOURCES,
    gridSize: {
      xs: 5,
      sm: 6
    },
  }
];
