import { useTheme } from '@mui/material';
import React from 'react';
import SimpleBackground from '../../Components/Background/Simple';
import './styles.css';

export default function PrivacyPolicy() {
  const theme = useTheme();
  return (
    <SimpleBackground
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        height: '100%',
        padding: '0 1em',
        margin: 'auto'
      }}
      title={'Privacy Policy'}>
      {' '}
      <div
        data-mesh-id="comp-laghfqsg2inlineContent-gridContainer"
        data-testid="mesh-container-content"
        style={{ color: `${theme.palette.grey.text}!important` }}
      >
        <div
          id="comp-laghfqsn"
          className="KcpHeO tz5f0K comp-laghfqsn wixui-rich-text"
          data-testid="richTextElement"
        >
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              Last updated: November 14, 2022
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              This Privacy Policy describes Our policies and
                              procedures on the collection, use and disclosure of Your
                              information when You use the Service and tells You about
                              Your privacy rights and how the law protects You.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              We use Your Personal data to provide and improve the
                              Service. By using the Service, You agree to the
                              collection and use of information in accordance with
                              this Privacy Policy.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.8rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Interpretation and Definitions
                              </span>
                            </span>
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                &nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Interpretation
                              </span>
                            </span>
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                &nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              The words of which the initial letter is capitalized
                              have meanings defined under the following conditions.
                              The following definitions shall have the same meaning
                              regardless of whether they appear in singular or in
                              plural.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Definitions
                              </span>
                            </span>
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                &nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              For the purposes of this Privacy Policy:&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Account
                                  </span>
                                  {' '}
                                  means a unique account created for You to access our
                                  Service or parts of our Service.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Company
                                  </span>
                                  {' '}
                                  (referred to as either "the Company", "We", "Us" or
                                  "Our" in this Agreement) refers to
                                  {' '}
                                  {process.env.REACT_APP_ORG_FULL_NAME}
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Cookies
                                  </span>
                                  {' '}
                                  are small files that are placed on Your computer,
                                  mobile device or any other device by a website,
                                  containing the details of Your browsing history on
                                  that website among its many uses.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Country
                                  </span>
                                  {' '}
                                  refers to: India&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Device
                                  </span>
                                  {' '}
                                  means any device that can access the Service such as
                                  a computer, a cellphone or a digital tablet.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Personal Data
                                  </span>
                                  {' '}
                                  is any information that relates to an identified or
                                  identifiable individual.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Service
                                  </span>
                                  {' '}
                                  refers to the Website.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Service Provider
                                  </span>
                                  {' '}
                                  means any natural or legal person who processes the
                                  data on behalf of the Company. It refers to
                                  third-party companies or individuals employed by the
                                  Company to facilitate the Service, to provide the
                                  Service on behalf of the Company, to perform
                                  services related to the Service or to assist the
                                  Company in analyzing how the Service is used.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Usage Data
                                  </span>
                                  {' '}
                                  refers to data collected automatically, either
                                  generated by the use of the Service or from the
                                  Service infrastructure itself (for example, the
                                  duration of a page visit).&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Website
                                  </span>
                                  {' '}
                                  refers to
                                  {' '}
                                  {process.env.REACT_APP_ORG_ALTERNATE_NAME}
                                  , accessible from&nbsp;
                                  <span
                                    style={{ color: '#002D9C' }}
                                    className="wixui-rich-text__text"
                                  >
                                    <span
                                      style={{ textDecoration: 'underline' }}
                                      className="wixui-rich-text__text"
                                    >
                                      <a
                                        href={process.env.REACT_APP_ORG_WEBSITE}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="wixui-rich-text__text"
                                      >
                                        {process.env.REACT_APP_ORG_WEBSITE}
                                      </a>
                                    </span>
                                    &nbsp;
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    You
                                  </span>
                                  {' '}
                                  means the individual accessing or using the Service,
                                  or the company, or other legal entity on behalf of
                                  which such individual is accessing or using the
                                  Service, as applicable.
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          &nbsp;
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.8rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontWeight: 'bold' }}
                                  className="wixui-rich-text__text"
                                >
                                  Collecting and Using Your Personal Data
                                </span>
                              </span>
                            </span>
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontSize: '1.4rem' }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontWeight: 'bold' }}
                                  className="wixui-rich-text__text"
                                >
                                  &nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Types of Data Collected&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.3rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontWeight: 'bold' }}
                                  className="wixui-rich-text__text"
                                >
                                  Personal Data&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontWeight: 'bold' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span className="wixGuard wixui-rich-text__text">

                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              While using Our Service, We may ask You to provide Us
                              with certain personally identifiable information that
                              can be used to contact or identify You. Personally
                              identifiable information may include, but is not limited
                              to:&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Email address&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  First name and last name&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Phone number&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Address, State, Province, ZIP/Postal code,
                                  City&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Usage Data&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>

          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.3rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontWeight: 'bold' }}
                                  className="wixui-rich-text__text"
                                >
                                  Usage Data
                                </span>
                                &nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              Usage Data is collected automatically when using the
                              Service.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              Usage Data may include information such as Your Device's
                              Internet Protocol address (e.g. IP address), browser
                              type, browser version, the pages of our Service that You
                              visit, the time and date of Your visit, the time spent
                              on those pages, unique device identifiers and other
                              diagnostic data.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              When You access the Service by or through a mobile
                              device, We may collect certain information
                              automatically, including, but not limited to, the type
                              of mobile device You use, Your mobile device unique ID,
                              the IP address of Your mobile device, Your mobile
                              operating system, the type of mobile Internet browser
                              You use, unique device identifiers and other diagnostic
                              data.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              We may also collect information that Your browser sends
                              whenever You visit our Service or when You access the
                              Service by or through a mobile device.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ color: '#000000' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                Our company is hosted on the Wix.com platform. Wix.com
                                provides us with the online platform that allows us to
                                sell our products and services to you. Your data may
                                be stored through Wix.com’s data storage, databases,
                                and the general Wix.com applications. They store your
                                data on secure servers behind a firewall. &nbsp;&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ color: '#FF0000' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Tracking Technologies and Cookies&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              We use Cookies and similar tracking technologies to
                              track the activity on Our Service and store certain
                              information. Tracking technologies used are beacons,
                              tags, and scripts to collect and track information and
                              to improve and analyze Our Service. The technologies We
                              use may include:&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Cookies or Browser Cookies.
                                  </span>
                                  {' '}
                                  A cookie is a small file placed on Your Device. You
                                  can instruct Your browser to refuse all Cookies or
                                  to indicate when a Cookie is being sent. However, if
                                  You do not accept Cookies, You may not be able to
                                  use some parts of our Service. Unless you have
                                  adjusted Your browser setting so that it will refuse
                                  Cookies, our Service may use Cookies.
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Flash Cookies.
                                  </span>
                                  {' '}
                                  Certain features of our Service may use local stored
                                  objects (or Flash Cookies) to collect and store
                                  information about Your preferences or Your activity
                                  on our Service. Flash Cookies are not managed by the
                                  same browser settings as those used for Browser
                                  Cookies. For more information on how You can delete
                                  Flash Cookies, please read "Where can I change the
                                  settings for disabling, or deleting local shared
                                  objects?" available at&nbsp;
                                  <span
                                    style={{ color: '#002D9C' }}
                                    className="wixui-rich-text__text"
                                  >
                                    <span
                                      style={{ textDecoration: 'underline' }}
                                      className="wixui-rich-text__text"
                                    >
                                      <a
                                        href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="wixui-rich-text__text"
                                        style={{ overflowWrap: 'anywhere' }}
                                      >
                                        https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                                      </a>
                                    </span>
                                    &nbsp;
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Web Beacons.
                                  </span>
                                  {' '}
                                  Certain sections of our Service and our emails may
                                  contain small electronic files known as web beacons
                                  (also referred to as clear gifs, pixel tags, and
                                  single-pixel gifs) that permit the Company, for
                                  example, to count users who have visited those pages
                                  or opened an email and for other related website
                                  statistics (for example, recording the popularity of
                                  a certain section and verifying system and server
                                  integrity).&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>

          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              Cookies can be "Persistent" or "Session" Cookies.
                              Persistent Cookies remain on Your personal computer or
                              mobile device when You go offline, while Session Cookies
                              are deleted as soon as You close Your web browser. Learn
                              more about cookies:&nbsp;
                              <span
                                style={{ color: '#002D9C' }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ textDecoration: 'underline' }}
                                  className="wixui-rich-text__text"
                                >
                                  <a
                                    href="https://www.freeprivacypolicy.com/blog/cookies/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="wixui-rich-text__text"
                                  >
                                    Cookies: What Do They Do?
                                  </a>
                                </span>
                                .
                              </span>
                              &nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              We use both Session and Persistent Cookies for the
                              purposes set out below:&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ol className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    Necessary / Essential Cookies
                                  </span>
                                  &nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ol>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Type: Session Cookies&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Administered by: Us&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Purpose: These Cookies are essential to provide You
                                  with services available through the Website and to
                                  enable You to use some of its features. They help to
                                  authenticate users and prevent fraudulent use of
                                  user accounts. Without these Cookies, the services
                                  that You have asked for cannot be provided, and We
                                  only use these Cookies to provide You with those
                                  services.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span className="wixGuard wixui-rich-text__text">

                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontWeight: 'bold' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontSize: '1.4rem' }}
                                className="wixui-rich-text__text"
                              >
                                &nbsp; &nbsp;2. Cookies Policy / Notice Acceptance
                                Cookies&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Type: Persistent Cookies&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Administered by: Us&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Purpose: These Cookies identify if users have
                                  accepted the use of cookies on the Website.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontWeight: 'bold' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontSize: '1.4rem' }}
                                className="wixui-rich-text__text"
                              >
                                &nbsp; &nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontWeight: 'bold' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontSize: '1.4rem' }}
                                className="wixui-rich-text__text"
                              >
                                &nbsp; &nbsp;3. Functionality Cookies&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Type: Persistent Cookies&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Administered by: Us&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Purpose: These Cookies allow us to remember choices
                                  You make when You use the Website, such as
                                  remembering your login details or language
                                  preference. The purpose of these Cookies is to
                                  provide You with a more personal experience and to
                                  avoid You having to re-enter your preferences every
                                  time You use the Website.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>

          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              For more information about the cookies we use and your
                              choices regarding cookies, please visit our Cookies
                              Policy or the Cookies section of our Privacy
                              Policy.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontWeight: 'bold' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                Information Collected while Using the
                                Application&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontWeight: 'bold' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ lineHeight: '2em', fontSize: 16 }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            While using Our Application, in order to provide features
                            of Our Application, We may collect, with Your prior
                            permission:&nbsp;
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ lineHeight: '2em', fontSize: 16 }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">

                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ lineHeight: '2em', fontSize: 16 }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            Pictures and other information from your Device's camera
                            and photo library&nbsp;
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ lineHeight: '2em', fontSize: 16 }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">

                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ lineHeight: '2em', fontSize: 16 }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            We use this information to provide features of Our
                            Service, to improve and customize Our Service. The
                            information may be uploaded to the Company's servers
                            and/or a Service Provider's server or it may be simply
                            stored on Your device.&nbsp;
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ lineHeight: '2em', fontSize: 16 }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">

                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ lineHeight: '2em', fontSize: 16 }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            You can enable or disable access to this information at
                            any time, through Your Device settings.&nbsp;
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ lineHeight: '2em', fontSize: 16 }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span className="wixGuard wixui-rich-text__text">

                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Use of Your Personal Data
                              </span>
                              &nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              The Company may use Personal Data for the following
                              purposes:&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    To provide and maintain our Service
                                  </span>
                                  , including to monitor the usage of our
                                  Service.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    To manage Your Account:
                                  </span>
                                  {' '}
                                  to manage Your registration as a user of the
                                  Service. The Personal Data You provide can give You
                                  access to different functionalities of the Service
                                  that are available to You as a registered
                                  user.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    For the performance of a contract:
                                  </span>
                                  {' '}
                                  the development, compliance and undertaking of the
                                  purchase contract for the products, items or
                                  services You have purchased or of any other contract
                                  with Us through the Service.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    To contact You:
                                  </span>
                                  {' '}
                                  To contact You by email, telephone calls, SMS, or
                                  other equivalent forms of electronic communication,
                                  such as a mobile application's push notifications
                                  regarding updates or informative communications
                                  related to the functionalities, products or
                                  contracted services, including the security updates,
                                  when necessary or reasonable for their
                                  implementation.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    To provide You
                                  </span>
                                  {' '}
                                  with news, special offers and general information
                                  about other goods, services and events which we
                                  offer that are similar to those that you have
                                  already purchased or enquired about unless You have
                                  opted not to receive such information.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    To manage Your requests:
                                  </span>
                                  {' '}
                                  To attend and manage Your requests to Us.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    For business transfers:
                                  </span>
                                  {' '}
                                  We may use Your information to evaluate or conduct a
                                  merger, divestiture, restructuring, reorganization,
                                  dissolution, or other sale or transfer of some or
                                  all of Our assets, whether as a going concern or as
                                  part of bankruptcy, liquidation, or similar
                                  proceeding, in which Personal Data held by Us about
                                  our Service users is among the assets
                                  transferred.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  F
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    or other purposes:
                                  </span>
                                  {' '}
                                  We may use Your information for other purposes, such
                                  as data analysis, identifying usage trends,
                                  determining the effectiveness of our promotional
                                  campaigns and to evaluate and improve our Service,
                                  products, services, marketing and your
                                  experience.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>

          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              We may share Your personal information in the following
                              situations:&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    With Service Providers:
                                    {' '}
                                  </span>
                                  We may share Your personal information with Service
                                  Providers to monitor and analyze the use of our
                                  Service, to contact You.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    For business transfers:
                                  </span>
                                  {' '}
                                  We may share or transfer Your personal information
                                  in connection with, or during negotiations of, any
                                  merger, sale of Company assets, financing, or
                                  acquisition of all or a portion of Our business to
                                  another company.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    With Affiliates:
                                  </span>
                                  {' '}
                                  We may share Your information with Our affiliates,
                                  in which case we will require those affiliates to
                                  honor this Privacy Policy. Affiliates include Our
                                  parent company and any other subsidiaries, joint
                                  venture partners or other companies that We control
                                  or that are under common control with Us.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    With business partners:
                                  </span>
                                  {' '}
                                  We may share Your information with Our business
                                  partners to offer You certain products, services or
                                  promotions.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    With other users:
                                    {' '}
                                  </span>
                                  when You share personal information or otherwise
                                  interact in the public areas with other users, such
                                  information may be viewed by all users and may be
                                  publicly distributed outside. If You interact with
                                  other users or register through a Third-Party Social
                                  Media Service, Your contacts on the Third-Party
                                  Social Media Service may see Your name, profile,
                                  pictures and description of Your activity.
                                  Similarly, other users will be able to view
                                  descriptions of Your activity, communicate with You
                                  and view Your profile.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  <span
                                    style={{ fontWeight: 'bold' }}
                                    className="wixui-rich-text__text"
                                  >
                                    With Your consent:
                                  </span>
                                  {' '}
                                  We may disclose Your personal information for any
                                  other purpose with Your consent.&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Retention of Your Personal Data
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{}}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              The Company will retain Your Personal Data only for as
                              long as is necessary for the purposes set out in this
                              Privacy Policy. We will retain and use Your Personal
                              Data to the extent necessary to comply with our legal
                              obligations (for example, if we are required to retain
                              your data to comply with applicable laws), resolve
                              disputes, and enforce our legal agreements and
                              policies.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              The Company will also retain Usage Data for internal
                              analysis purposes. Usage Data is generally retained for
                              a shorter period of time, except when this data is used
                              to strengthen the security or to improve the
                              functionality of Our Service, or We are legally
                              obligated to retain this data for longer time
                              periods.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>

          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Transfer of Your Personal Data&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              Your information, including Personal Data, is processed
                              at the Company's operating offices and in any other
                              places where the parties involved in the processing are
                              located. It means that this information may be
                              transferred to — and maintained on — computers located
                              outside of Your state, province, country or other
                              governmental jurisdiction where the data protection laws
                              may differ than those from Your jurisdiction.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              Your consent to this Privacy Policy followed by Your
                              submission of such information represents Your agreement
                              to that transfer.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              The Company will take all steps reasonably necessary to
                              ensure that Your data is treated securely and in
                              accordance with this Privacy Policy and no transfer of
                              Your Personal Data will take place to an organization or
                              a country unless there are adequate controls in place
                              including the security of Your data and other personal
                              information.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Disclosure of Your Personal Data
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>

          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Business Transactions&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              If the Company is involved in a merger, acquisition or
                              asset sale, Your Personal Data may be transferred. We
                              will provide notice before Your Personal Data is
                              transferred and becomes subject to a different Privacy
                              Policy.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>

          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Law enforcement&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              Under certain circumstances, the Company may be required
                              to disclose Your Personal Data if required to do so by
                              law or in response to valid requests by public
                              authorities (e.g. a court or a government agency).&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>

          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Other legal requirements&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              The Company may disclose Your Personal Data in the good
                              faith belief that such action is necessary to:&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Comply with a legal obligation&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Protect and defend the rights or property of the
                                  Company&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Prevent or investigate possible wrongdoing in
                                  connection with the Service&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Protect the personal safety of Users of the Service
                                  or the public&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  Protect against legal liability&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>

          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Security of Your Personal Data
                              </span>
                            </span>
                          </span>

                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{}}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{}}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              The security of Your Personal Data is important to Us,
                              but remember that no method of transmission over the
                              Internet, or method of electronic storage is 100%
                              secure. While We strive to use commercially acceptable
                              means to protect Your Personal Data, We cannot guarantee
                              its absolute security.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>

          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Children's Privacy
                              </span>
                              &nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              Our Service does not address anyone under the age of 13.
                              We do not knowingly collect personally identifiable
                              information from anyone under the age of 13. If You are
                              a parent or guardian and You are aware that Your child
                              has provided Us with Personal Data, please contact Us.
                              If We become aware that We have collected Personal Data
                              from anyone under the age of 13 without verification of
                              parental consent, We take steps to remove that
                              information from Our servers.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              If We need to rely on consent as a legal basis for
                              processing Your information and Your country requires
                              consent from a parent, We may require Your parent's
                              consent before We collect and use that
                              information.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>

          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Links to Other Websites
                              </span>
                              &nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              Our Service may contain links to other websites that are
                              not operated by Us. If You click on a third party link,
                              You will be directed to that third party's site. We
                              strongly advise You to review the Privacy Policy of
                              every site You visit.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              We have no control over and assume no responsibility for
                              the content, privacy policies or practices of any third
                              party sites or services.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Changes to this Privacy Policy&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              We may update Our Privacy Policy from time to time. We
                              will notify You of any changes by posting the new
                              Privacy Policy on this page.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              We will let You know via email and/or a prominent notice
                              on Our Service, prior to the change becoming effective
                              and update the "Last updated" date at the top of this
                              Privacy Policy.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              You are advised to review this Privacy Policy
                              periodically for any changes. Changes to this Privacy
                              Policy are effective when they are posted on this
                              page.&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              <span className="wixGuard wixui-rich-text__text">

                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                Contact Us&nbsp;
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{ fontSize: '1.4rem' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{
                                fontFamily:
                                  'avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif'
                              }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{ fontWeight: 'bold' }}
                                className="wixui-rich-text__text"
                              >
                                <span className="wixGuard wixui-rich-text__text">

                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <p
            className="font_8 wixui-rich-text__text"
            style={{ fontSize: '1.4rem' }}
          >
            <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
              <span
                style={{ fontFamily: 'montserrat' }}
                className="wixui-rich-text__text"
              >
                <span
                  style={{ fontWeight: 'normal' }}
                  className="wixui-rich-text__text"
                >
                  <span
                    style={{ fontStyle: 'normal' }}
                    className="wixui-rich-text__text"
                  >
                    <span className="wixui-rich-text__text">
                      <span
                        style={{ color: 'rgb(0, 0, 0)' }}
                        className="wixui-rich-text__text"
                      >
                        <span
                          style={{ letterSpacing: '0em' }}
                          className="wixui-rich-text__text"
                        >
                          <span
                            style={{
                              fontFamily:
                                'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                            }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ fontSize: '1.4rem' }}
                              className="wixui-rich-text__text"
                            >
                              If you have any questions about this Privacy Policy, You
                              can contact us:&nbsp;
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </p>
          <ul className="font_8 wixui-rich-text__text" style={{ fontSize: 16 }}>
            <li style={{ lineHeight: '2em' }} className="wixui-rich-text__text">
              <p
                className="font_8 wixui-rich-text__text"
                style={{ fontSize: '1.4rem' }}
              >
                <span style={{ fontSize: '1.4rem' }} className="wixui-rich-text__text">
                  <span
                    style={{ fontFamily: 'montserrat' }}
                    className="wixui-rich-text__text"
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      className="wixui-rich-text__text"
                    >
                      <span
                        style={{ fontStyle: 'normal' }}
                        className="wixui-rich-text__text"
                      >
                        <span className="wixui-rich-text__text">
                          <span
                            style={{ color: 'rgb(0, 0, 0)' }}
                            className="wixui-rich-text__text"
                          >
                            <span
                              style={{ letterSpacing: '0em' }}
                              className="wixui-rich-text__text"
                            >
                              <span
                                style={{
                                  fontFamily:
                                    'avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif'
                                }}
                                className="wixui-rich-text__text"
                              >
                                <span
                                  style={{ fontSize: '1.4rem' }}
                                  className="wixui-rich-text__text"
                                >
                                  By email:&nbsp;
                                  <span
                                    style={{ color: '#002D9C' }}
                                    className="wixui-rich-text__text"
                                  >
                                    <span
                                      style={{ textDecoration: 'underline' }}
                                      className="wixui-rich-text__text"
                                    >
                                      <a
                                        href={`mailto:${process.env.REACT_APP_ORG_WEBSITE}`}
                                        target="_self"
                                        className="wixui-rich-text__text"
                                      >
                                        {process.env.REACT_APP_ORG_MAIL}
                                      </a>
                                    </span>
                                    &nbsp;
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ul>
        </div>
      </div>

    </SimpleBackground>
  );
}
