import { Toolbar } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useIsDesktop } from '../../Helper/hooks';

import { ReactComponent as PoweredByTrential } from '../../Assets/poweredByTrential.svg';

export default function BottomComponent({ children, noFlex = false, sx = {} }) {
  const isDesktop = useIsDesktop();
  const ref = useRef(null);
  const [bottomHeight, setBottomHeight] = useState('50px');

  useEffect(() => {
    setTimeout(() => {
      if (ref?.current?.clientHeight) {
        setBottomHeight(`${ref.current?.clientHeight}px`);
      }
    }, 600);
  }, []);


  return isDesktop ? <>

    {noFlex ? null : <div style={{ flex: 1 }} />}
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      margin: '1em 0',
      // backgroundColor: '#ffffff',
      zIndex: 102,
      ...sx
    }}>

      {children}
    </div>
    {process.env.REACT_APP_SHOW_TRENTIAL_BRANDING ?
    <div>
      <Toolbar
        id="footer-offset" sx={{
          minHeight: `${bottomHeight ?? '2em'} !important`
        }} />
      <div
        ref={ref}
        style={{
          padding: '1em 0',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px -4px 12px 0px #0000000D',
          width: '100%',
          backgroundColor: '#ffffff',
          padding: '1em 10px 0.6em 10px',
          backgroundColor: '#ffffff',
          zIndex: 102
        }}>
        <PoweredByTrential />
      </div>
    </div> : null}
  </> : (
    <>
      <Toolbar sx={{
        minHeight: `${bottomHeight ?? '2em'} !important`
      }} />
      {/* <div style={{ margin: '0.4em', width: '1em'}}></div> */}
      <div
        ref={ref}
        style={{
          padding: '1em 0',
          position: 'fixed',
          bottom: 0,
          left: isDesktop ? 'auto' : 0,
          right: isDesktop ? 'auto' : 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // boxShadow: '0px -4px 12px 0px #0000000D',
          width: '100%',
          backgroundColor: '#ffffff',
          padding: '1em 10px 0.6em 10px',
          backgroundColor: '#ffffff',
          zIndex: 102
        }}>
        {children}
        {process.env.REACT_APP_SHOW_TRENTIAL_BRANDING ? <PoweredByTrential /> : null}
      </div>
    </>

  );
}
