/* eslint-disable */
import React from 'react'
import { Grid, Typography, Box, Button, useTheme, useMediaQuery } from '@mui/material'
import nsdcTrust from "../../../../../Assets/wes-login/nsdc-trust.svg"
import { FOOTER_LINKS } from './constants'
import { Link } from 'react-router-dom'
import styles from './styles'

const officeAddress = "National Skill Development Corporation 5th & 6th floor, Kaushal Bhawan, New Moti Bagh, New Delhi 110023"

function Footer() {
  const theme = useTheme();
  const greaterThanSM = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Box sx={{padding: {xs: '5rem 5vw', sm: '5rem 9vw'}, pb: '3rem !important', backgroundColor: '#fff'}}>
      <Grid container sx={{pb: '3.25rem', borderBottom: '1px solid rgba(0, 0, 0, 0.06)', justifyContent: 'space-between'}}>
        <Grid item xs={12} sm={6}>
          <img src={nsdcTrust} style={{objectFit: 'contain', height: '8.5rem'}}/>
          <Typography sx={{fontSize: '2.1rem', fontStyle: 'italic', color: '#000', fontWeight: '800', mb: '2.5rem'}}>NSDC TRUST</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container sx={{justifyContent: 'space-between'}} spacing={greaterThanSM ? '4.5' : '0'}>
            {
              FOOTER_LINKS.map(itm => 
             <Grid item xs={itm.gridSize.xs} sm={itm.gridSize.sm}>
             <Typography className='wes-auth-footer-section-title' sx={styles.linkHeading}>
                {itm.label}
              </Typography>
              <Box sx={{display: 'flex', flexFlow: 'column', rowGap: '1rem'}}>
                {
                  itm.links.map(row => 
                    row.elementId ?
                    <Button disableRipple disableFocusRipple
                      onClick={() => document.getElementById(row.elementId)?.scrollIntoView({behavior: 'smooth'})}
                      className='wes-auth-caption' sx={styles.linkBtn}>
                      {row.label}
                    </Button> :
                    <Button disableRipple disableFocusRipple
                      component={Link} to={row.url ?? null}
                      target={row.target}
                      className='wes-auth-caption' sx={styles.linkBtn}>
                      {row.label}
                    </Button> 
                  )
                }
              </Box>
             </Grid>
              )
            }
          </Grid>
          <Grid container >
            <Grid item xs={6} sx={{mt: '4.5rem'}}>
              <Typography className='wes-auth-footer-section-title' sx={styles.linkHeading}>
                Office
              </Typography>
              <Typography className='wes-auth-caption' sx={{color: '#000 !important', fontWeight: '500', lineHeight: '30px'}}>{officeAddress}
            </Typography>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{display: 'flex', justifyContent: 'flex-end', mr: '10vw', mt: '3rem'}}>
        <Box sx={{display: 'flex', columnGap: '2rem', alignItems: 'center'}}>
          <Button disableRipple disableFocusRipple component={Link} to={'/terms'} className='wes-auth-caption'
            sx={{...styles.linkBtn, color: '#60656C !important'}}>
            Terms & Conditions
          </Button>
          <Typography className='wes-auth-caption' sx={{color: '#60656C !important'}}>.</Typography>
          <Button disableRipple disableFocusRipple component={Link} to={'/privacy-policy'} className='wes-auth-caption' sx={{...styles.linkBtn, color: '#60656C !important'}}>
            Privacy Policy
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer