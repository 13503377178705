import AddressEditable from './addressEditable';
import NoAadhaarContactUs from './noAadhaarContactUs';


export const customForms = (key, fn, triggerChildFunctionRef, loading) => {
  console.log('loading2', loading);
  switch (key?.name) {
    case 'address': return <AddressEditable
      fn={(e) => {
        try {
          const wrapperFn = eval(key?.body);
          fn(wrapperFn(e));
        } catch (error) {
          console.log('Error parsing specificForm\'s Submit function', error);
          fn(e);
        }
      }} ref={triggerChildFunctionRef} loading={loading} />;
    case 'currentAddress': return <AddressEditable
      fn={(e) => {
        try {
          const wrapperFn = eval(key?.body);
          fn(wrapperFn(e));
        } catch (error) {
          console.log('Error parsing specificForm\'s Submit function', error);
          fn(e);
        }
      }}
      label={key?.label}
      name={key?.name}
      ref={triggerChildFunctionRef}
      loading={loading} />;
    case 'permanentAddress': return <AddressEditable
      fn={(e) => {
        try {
          const wrapperFn = eval(key?.body);
          fn(wrapperFn(e));
        } catch (error) {
          console.log('Error parsing specificForm\'s Submit function', error);
          fn(e);
        }
      }}
      label={key?.label}
      name={key?.name}
      ref={triggerChildFunctionRef} loading={loading} />;
    case 'noAadhaarContactUs': return process.env.REACT_APP_ORG_MAIL ? <NoAadhaarContactUs fn={fn} /> : null;

    default: return <></>;
  }
};
