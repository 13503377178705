import { Lock, Menu } from '@mui/icons-material';
import { Grid, IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import emptyBox from '../../Assets/lottie/emptyBox.json';
import recentVerifications from '../../Assets/recentVerifications.svg';
import HomeCarousel from '../../Components/Carousel/static';
import BottomComponent from '../../Components/UI/bottomComponent';
import { getForms } from '../../Config/api';
import { UIStore, updateStore } from '../../Config/store';
import { appendLoggedIn, camelCaseToString, getFetchProcessInterval, triggerCancellationController, updateDocumentTitle } from '../../Helper/helpers';
import { useIsDesktop } from '../../Helper/hooks';
import RecentVerifications from './requests';

export default function Home() {
  const isDesktop = useIsDesktop();
  const isAuthenticated = UIStore.useState((s) => s.isAuthenticated);

  useEffect(() => {
    updateDocumentTitle(['Home']);
    clearInterval(getFetchProcessInterval());
    triggerCancellationController();
  }, []);


  useEffect(() => {
    window?.history?.replaceState({}, document.title, appendLoggedIn(document.URL));

    getForms()
        .then((form) => setRecentVerificationsData(form));
  }, []);


  const [recentVerificationsData, setRecentVerificationsData] = useState([]);
  // const [pendingRequestsData, setPendingRequestsData] = useState([]);
  console.log('recentVerificationsData', recentVerificationsData);

  const getTitle = () => {
    if (process.env.REACT_APP_ORG == 'nsdc') {
      return 'Welcome to NSDC Trust Wallet';
    };
    return `Welcome to your ${process.env.REACT_APP_SHOW_TRENTIAL_BRANDING ? 'Trential' : '' } wallet`;
  };

  return (
    <Grid sx={{ width: '100%' }}>
      <ListItem
      >
        {(isAuthenticated && !isDesktop) ?

          <ListItemAvatar sx={{ }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={() => updateStore('drawer', true)}
            >
              <Menu />
            </IconButton>
          </ListItemAvatar> :

          null}

        <ListItemText
          sx={{ margin: '0.6em', marginRight: 'auto' }}
          primary={getTitle()}
          secondary={<span style={{ display: 'flex', alignItems: 'center' }}>
            <Lock sx={{ fontSize: '1.2rem', marginRight: '4px' }} />
            {' '}
            {process.env.REACT_APP_SHOW_TRENTIAL_BRANDING ?
              'Your data is safeguarded and secure, ensuring 100% protection.' :
              'Verify yourself with ease and security.'}
            {' '}
          </span>}
          primaryTypographyProps={{
            sx: {
              fontSize: '1.4rem',
              fontWeight: 500,
              margin: 0,
              color: '#444444',
              textAlign: 'left'
            }
          }}
          secondaryTypographyProps={{
            sx: {
              fontSize: '1.2rem',
              fontWeight: 500,
              margin: 0,
              color: '#8B8B8B',
              textAlign: 'left'
            }
          }}
        />
      </ListItem>

      {/* {true ? <InsufficiencyCard /> : null} */}
      <HomeCarousel />
      {/*
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          padding: '1em',
        }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
          }}>
          <Typography
            variant="body1"
            sx={{display: 'flex', alignItems: 'center'}}>
            <img src={recentVerifications} alt="recentVerifications" style={{marginRight: '5px'}} />
                Recent verifications
          </Typography>
          <Button variant="text" sx={{width: 'fit-content', padding: 0}}>
                View all
          </Button>
        </Grid>
        <Box sx={{
          overflowX: 'auto',
          maxWidth: '90vw',
          display: '-webkit-box',
        }}>
          {recentVerificationsData?.map((item, i) => <VerificationCard
            key={`VerificationCard-${ i}`}
            data={item} />)}
        </Box>
      </Grid> */}

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          padding: '1em',
        }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: '1em',
            bgcolor: '#FAFAFA'
          }}>
          <img
            src={recentVerifications} alt="recentVerifications" style={{ marginRight: '5px' }} />
          <Typography
            variant="h6"
            sx={{ display: 'flex', alignItems: 'center' }}>
            Verification Request
          </Typography>
          {/* <Button variant="text" sx={{ width: 'fit-content', padding: 0 }}>
            View all
          </Button> */}
        </Grid>
        {recentVerificationsData?.length > 0 ? recentVerificationsData?.map((item, i) => <RecentVerifications
          key={`RecentVerifications-${i}`}
          data={{
            link: item?.link,
            name: item?.orgName,
            image: item?.orgLogo,
            credentials: item?.credentials?.map((j) => camelCaseToString(j)),
            orgId: item?.orgId,
            formId: item?.formId,
            formName: item?.name,
            txnId: item?.txnId,
            state: item?.state
          }} />) : <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Lottie
              animationData={emptyBox}
              loop={false}
              style={{ width: '50%' }} />
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
            No Verifications Available
            </Typography>
          </Grid>}
      </Grid>
      <BottomComponent>
        <></>
      </BottomComponent>
    </Grid>
  );
}
