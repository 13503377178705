import { CheckCircle } from '@mui/icons-material';
import { Card, CardContent, Divider, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

export default function CompletedInsufficiency({ response, raisedOn }) {
  const theme = useTheme();
  console.log('response status:', response);
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    const fil = response?.insuf?.filter((insuf, i) => (response?.resolution?.[insuf?.insufficiencyId]?.text ||
      response?.resolution?.[insuf?.insufficiencyId]?.files?.length > 0));
    console.log('CompletedInsufficiency:', fil);
    setFiltered(fil);
  }, [response]);


  return (
    <Grid
      container
      direction="column"
      justifyContent={'center'}
      alignItems="center"
      rowGap={1}
      rowSpacing={1}
      sx={{ marginTop: '1em' }}>

      {filtered?.length > 0 ?
        <Divider fullWidth sx={{ width: '100%' }}>
          <Typography
            variant="body1" sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.palette.success.main
            }}>
            <CheckCircle sx={{ fontSize: 20, marginRight: '5px' }} />
            Resolved Insufficiency
          </Typography>
        </Divider> :
        null}

      {filtered?.map((insuf, i) => <Card
        variant="outlined" sx={{
          width: '100%',
          border: 'none',
          borderRadius: '8px',
          boxShadow: '0px 4px 10px 0px #ADADAD29',
          // border: `1px solid ${theme.palette.primary.main}`,
          // color: theme.palette.warning.main,
        }}>
        <CardContent
          sx={{
            padding: '0px !important',
            width: '100%'
          }}>
          <Typography
            variant="body2"
            sx={{
              margin: 0,
              padding: '1em',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              bgcolor: theme.palette.success.light,
              color: theme.palette.success.main,
              fontWeight: 800
            }}>
            {insuf?.displayName}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent={'space-between'}
            alignItems="center"
            sx={{
              padding: '1em',
              width: '100%'
            }}>
            <Grid item xs={12}>
              <Typography
                variant="body2" sx={{
                }}>
                {response?.resolution?.[insuf?.insufficiencyId]?.text}
              </Typography>
              {response?.resolution?.[insuf?.insufficiencyId]?.files?.length > 0 ?
                <>
                  <Typography variant="body2">
                    <b>
                      Files Uploaded:
                    </b>
                  </Typography>
                  <ul style={{ fontSize: '1.2rem' }}>
                    {response?.resolution?.[insuf?.insufficiencyId]?.files?.map(((zz) => <li>
                      <a href={zz?.document.url} target="_blank" rel="noreferrer">
                        {zz?.fileName}
                      </a>
                    </li>))}

                  </ul>
                </> :

                null}
            </Grid>

            {/* <Grid
            item xs={4} sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}>
            <Chip
              color="primary" variant="outlined"
              sx={{
                'border': 'none',
                '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
              }}
              size="small"
              icon={<CheckCircleOutline />}
              label="Submitted" />
          </Grid> */}
          </Grid>
        </CardContent>
      </Card>)}
    </Grid>

  );
}
