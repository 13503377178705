import computerIcon from '../../../../../Assets/wes-login/computer-icon.svg';
import indiaIcon from '../../../../../Assets/wes-login/india-icon.svg';
import workflowIcon from '../../../../../Assets/wes-login/workflow-icon.svg';
import nsdcTrust from '../../../../../Assets/wes-login/nsdc-trust.svg';
import handshakeIcon from '../../../../../Assets/wes-login/handshake-icon.svg';
import wesLogo from '../../../../../Assets/wes-login/wes-logo.svg';

export const BRANDING = [
  {
    imgSrc: nsdcTrust,
    imgStyle: {height: {xs: '3.4rem', md: '4.2rem', xl: '4.4rem'}}
  },
  {
    imgSrc: handshakeIcon,
    imgStyle: {height: {xs: '2.55rem', md: '3.2rem', xl: '3.4rem'}}
  },
  {
    imgSrc: wesLogo,
    imgStyle: {height: {xs: '2.7rem', md: '3.3rem', xl: '3.5rem'}}
  },
];

export const TRENTIAL_PERKS = [
  {
    label: 'Easy Digital Verification',
    imgSrc: computerIcon,
    imgStyle: {
      height: {xs: '3rem', sm: '3.6rem', xl: '5rem'}
    }
  },
  {
    label: 'PAN India coverage',
    imgSrc: indiaIcon,
    imgStyle: {
      height: {xs: '3rem', sm: '3.6rem', xl: '5rem'}
    }
  },
  {
    label: 'Automated Workflows',
    imgSrc: workflowIcon,
    imgStyle: {
      height: {xs: '3rem', sm: '3.6rem', xl: '5rem'}
    }
  }
];

export const TOP_BAR_LINKS = [
  {
    label: 'Watch Demo',
    elementId: 'wes-auth-watch-demo'
  },
  {
    label: 'Instructions',
    elementId: 'wes-auth-instructions'
  },
  {
    label: 'FAQs',
    elementId: 'wes-auth-faq'
  }
];
