import { ArrowDropDown } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import { countries } from 'countries-list';
import { debounce } from 'lodash';
import * as React from 'react';
import Flag from 'react-world-flags';
import CustomTextField from './Input/customTextField';

const ITEM_HEIGHT = 48;

export default function CountryInput({ countryCode, setCountryCode, setCountry = ()=>{}, phoneRef }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setCountriesList2(Object.keys(countries)?.map((zz) => ({
      code: zz,
      ...countries[zz]
    })));
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    setAnchorEl(null);
    setCountryCode(`+${value.phone?.[0]}`);
    setFlagId(value.code);
  };

  const [flagId, setFlagId] = React.useState('IN');
  const [countriesList2, setCountriesList2] = React.useState(Object.keys(countries)?.map((zz) => ({
    code: zz,
    ...countries[zz]
  })));
  const handleChange = (event) => {
    setCountryCode(`+${countries[value].phone?.[0]}`);
    setFlagId(value);
  };
  //   console.log('countriesList2', countriesList2);

  const search = React.useCallback(debounce(async (q) => {
    setCountriesList2(Object.keys(countries)
        .filter((j) => {
          if (!countries[j]) {
            return false;
          }
          q = q.replace(/[^a-zA-z0-9\s]/gi, '');
          const rg = new RegExp(q, 'ig');
          return (countries[j]?.name).match(rg)?.length > 0 || (countries[j]?.phone?.[0] ?
          (countries[j]?.phone?.[0])?.toString().match(rg)?.length > 0 :
          false);
        })
        ?.map((zz) => ({
          code: zz,
          ...countries[zz]
        })));
  }, 500), []);
  return (
    <div>
      <Box
        sx={{
          display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', gap: 0.5,
          cursor: 'pointer',
          marginRight: '0.5em',
          paddingLeft: '5px'
        }}
        onClick={handleClick}>
        <Flag code={flagId} style={{ width: '30px', margin: '0 4px' }} />
        {countryCode}
        <ArrowDropDown sx={{ margin: '-1px' }} />
      </Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: phoneRef.current?.clientWidth ?? '100%',
            position: 'relative',
            marginLeft: '-12px',
            marginTop: '12px'
          },
        }}
      >
        <CustomTextField
          fullWidth
          variant="standard"
          onKeyDown={(x) => {
            x.stopPropagation();
          }}
          onChange={(x) => {
            x.target.value = x.target.value.trimStart();
            x.preventDefault();
            search(x.target.value);
          }}
          sx={{
            paddingLeft: '5px',
            position: 'sticky',
            top: '0px',
            backgroundColor: '#ffffff',
            zIndex: 9999
          }} />
        {countriesList2?.map((option) => (
          <MenuItem
            key={option.name}
            value={option.name}
            selected={option.name === flagId} onClick={() => handleClose(option)}
          >
            <Flag code={option.code} style={{ width: '30px', margin: '0 4px' }} />
            {option?.name}
            {' (+'}
            {option?.phone?.[0]}
            {') '}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
