/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(timezone);
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
dayjs.extend(utc);
const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few secs',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d d',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
});

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

// export function getTimeAgo(h) {
//   const ago = new Date(parseInt(h, 16) * 1000); // convert to decimal unix time
//   const d = dayjs(ago).fromNow()
//   return d;
// }
export function getTimeAgo(h, displayAgoFormat = false) {
  const ago = new Date(h); // convert to decimal unix time
  return dayjs(ago).fromNow(!displayAgoFormat);
}

export function displayTime(h = new Date(), format = 'DD/MM/YYYY hh:mm') {
  h = dateFormatCorrection(h);
  if (h.isValid) {
    const d = dayjs(h.val).format(format);
    return d;
  } else {
    return h.val;
  }
}
// compares if b is bigger than a
export function isADateBiggerThanB(a = new Date(), b = new Date()) {
  a = new Date(a); // convert to decimal unix time
  b = new Date(b); // convert to decimal unix time
  const d = dayjs(b).isBefore(a, 'day');
  return d;
}


export function addTime(h, duration = [1, 'd'], format = 'LLLL') {
  const d = dayjs(new Date(h)).add(duration[0], duration[1]);
  return d.format(format);
}

export const addOneDay = (value) => {
  const date = new Date(value);
  return date.setDate(date.getDate() + 1);
};

export const getTimeInfo = (value) => {
  const d = new Date(value);
  return new Date(0, 0, 0, d.getHours(), d.getMinutes());
};

export function isDateValid(h = new Date()) {
  try {
    const time = dayjs(h).isValid();
    if (time) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

export function isDateValidInFormatDDMMYYYY(h = '') {
  try {
    const time = h.toString()?.match(/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/);
    if (time) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
export function isDateValidInFormatDMMYYYY(h = '') {
  try {
    const time = h.toString()?.match(/^[0-9]{1}-[0-9]{2}-[0-9]{4}$/);
    if (time) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

export function parseTimeFromOneFormatToAnother(h = new Date(), format = 'DD/MM/YYYY hh:mm', newFormat = 'DD/MM/YYYY') {
  if (isDateValidInFormatDDMMYYYY(h)) {
    const date = dayjs(h, 'DD-MM-YYYY', true);
    return date.format(newFormat);
  } else if (isDateValidInFormatDMMYYYY(h)) {
    const date = dayjs(h, 'D-MM-YYYY', true);
    return date.format(newFormat);
  } else if (dayjs(h).isValid()) {
    const date = dayjs(h, format, true);
    return date.format(newFormat);
  } return h;
}


export const dateFormatCorrection = (date) => {
  if (isDateValidInFormatDDMMYYYY(date)) {
    return {
      isValid: true,
      val: parseTimeFromOneFormatToAnother(date, 'DD-MM-YYYY', 'YYYY-MM-DD')
    };
  } else {
    return {
      isValid: dayjs(date).isValid(),
      val: date
    };
  }
};
