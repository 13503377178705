/* eslint-disable */
import { Navigate, Route, Routes } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import './App.css';
import Header from './Components/Header';
import OrgSpecific from './Components/Wrappers/OrgSpecific';
import { UIStore } from './Config/store';
import Auth from './Containers/Auth';
import EceAuth from './Containers/Auth/Custom/ECE';
import GceAuth from './Containers/Auth/Custom/GCE';
import NSDCAuth from './Containers/Auth/Custom/NSDC';
import WESAuth from './Containers/Auth/Custom/WES';
import DocRequest from './Containers/DocRequest';
import Insufficiency from './Containers/DocRequest/insufficiency';
import UserInput from './Containers/DocRequest/userInput';
import Digilocker from './Containers/DocResponse/digilocker';
import DisplayInfo from './Containers/DocResponse/displayInfo';
import UnderProcess from './Containers/DocResponse/noDataFound';
import ReadOnlyData from './Containers/DocResponse/readOnlyData';
import Scan from './Containers/DocResponse/scan';
import Home from './Containers/Home';
import Information from './Containers/Information';
import Liveness from './Containers/Liveness';
import MandatoryFormsToFill from './Containers/MandatoryFormsToFill';
import MyProfile from './Containers/MyProfile';
import Notifications from './Containers/Notifications';
import Payment from './Containers/Payment';
import PaymentProvider from './Containers/Payment/provider';
import TransactionHistory from './Containers/Payment/transactionHistory';
import TnC from './Containers/TnC';
import PrivacyPolicy from './Containers/TnC/privacyPolicy2';
import Completed from './Containers/Verify/completed';
import FormPayments from './Containers/Verify/formPayments';
import Verify from './Containers/Verify/main';
import ProfileVerifications from './Containers/Verify/main/profileVerifications';
import { links } from './Utils/enums';

export const oauthLink = () => {
  const uid = uuid();
  return `/verify?response_type=code&client_id=test1&scope=presentation:passport:read+presentation:voter-id:read+presentation:aadhaar:read+presentation:driving-license:read+presentation:pan:read+presentation:liveness:read&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&state=${uid}`;
};

const orgsWithCustomAuth = [process.env.REACT_APP_WES_AUTH, process.env.REACT_APP_ECE_AUTH, process.env.REACT_APP_GCE_AUTH, process.env.REACT_APP_NSDC_AUTH]
export default function Router() {
  const isAuthenticated = UIStore.useState((s) => s.isAuthenticated);

  const renderAuthPage = () => {
    if (process.env.REACT_APP_NSDC_AUTH) return <NSDCAuth />
    if (process.env.REACT_APP_WES_AUTH) return <WESAuth />
    if (process.env.REACT_APP_ECE_AUTH) return <EceAuth />
    if (process.env.REACT_APP_GCE_AUTH) return <GceAuth />
    return <Auth />
  }

  return (
    <Routes>
      <Route
        exact path="/digilocker" element={<Header>
          <Digilocker />
        </Header>} />

      {/* <Route
        exact path="/profile" element={<Header>
          <Profile />
        </Header>} /> */}
      <Route
        path={`/${links.EKYC}/:formId/:txnId?`} element={<Header showSplash>
          <OrgSpecific>
            <Verify />
          </OrgSpecific>
        </Header>} />
      {orgsWithCustomAuth.includes('true') ? <>
        <Route
          path={`/faq`} element={<Information />} />
        <Route
          path={`/instructions`} element={<Information />} />
      </> : null}
      <Route
        path={`/terms`} element={<Header>
          <TnC />
        </Header>} />
      <Route
        path={`/privacy-policy`} element={<Header>
          <PrivacyPolicy />
        </Header>} />
      {/* <Route
          path={`/submitted`} element={<Header>
            <Completed />
          </Header>} /> */}
      {
        isAuthenticated ? (
          <>
            <Route path="/" element={<Header />}>
              <Route exact path="/profile-form" element={<ProfileVerifications />} />
              <Route exact path="/checkin/scan" element={<Scan />} />
              <Route exact path="/displayInfo" element={<DisplayInfo />} />
              <Route exact path="/info" element={<ReadOnlyData />} />
              <Route exact path="/setup" element={<MandatoryFormsToFill />} />
              <Route exact path="/liveness" element={<Liveness />} />
              <Route exact path="/insufficiency" element={<Insufficiency />} />
              <Route exact path="/docs" element={<DocRequest />} />
              <Route exact path="/notifications" element={<Notifications />} />
              <Route exact path="/profile" element={<MyProfile />} />
              <Route exact path="/user-input" element={<UserInput />} />
              <Route exact path="/choose-payment-region" element={<PaymentProvider />} />
              <Route exact path="/payment" element={<Payment />} />
              <Route exact path="/form-payments" element={<FormPayments />} />
              <Route exact path="/transaction-history" element={<TransactionHistory />} />

              <Route exact path="/under-process" element={<UnderProcess />} />
              <Route exact path="/" element={<Home />} />
              <Route
                path={`/submitted`} element={<Completed />} />
            </Route>

            <Route path="*" element={<Navigate to='/' />} />
          </>
        ) : (
          <>
            <Route
              path="/login" element={<Header>
                {renderAuthPage()}
              </Header>} />
            {/* <Route path={`/${links.EKYC}`} element={<UnAuthenticated />} /> */}
            <Route path="*" element={<Navigate to='/login' />} />
          </>

        )
      }
    </Routes>

  );
}


