import { CameraAlt, CameraswitchOutlined, InfoOutlined } from '@mui/icons-material';
import { Button, Dialog, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Camera } from 'react-camera-pro';
import { UIStore } from '../../Config/store';
import { useIsDesktop } from '../../Helper/hooks';
import { MAX_WIDTH, MAX_WIDTH_HALF } from '../../Utils/enums';


const WebcamCapture = ({ setImage, buttonRef, flipButtonRef, aspectRatio }) => {
  const camViewRef = useRef(null);
  const isDesktop = useIsDesktop();
  const webcamFacingMode = UIStore.useState((s) => s.webcamFacingMode) ?? 'environment';

  const [openNoCamDetectedDialog, setOpenNoCamDetectedDialog] = useState(false);
  // const [camView, setCamView] = useState({})


  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
      () => {try {
        const imageSrc = webcamRef.current.takePhoto();
        console.log('imageSrc', imageSrc);
        setImage(imageSrc);
      } catch (error) {
        setOpenNoCamDetectedDialog(error.message);
      }
      },
      [setImage, webcamRef]
  );
  console.log(webcamFacingMode);

  // useEffect(() => {
  //   setTimeout(() => {
  //     try {
  //       const numberOfCameras = webcamRef.current.getNumberOfCameras();
  //       console.log('numberOfCameras', numberOfCameras);
  //       if (numberOfCameras <= 0) {
  //         setOpenNoCamDetectedDialog('No Camera was detected. Please add a camera or try another device.');
  //       }
  //     } catch (error) {
  //       setOpenNoCamDetectedDialog(error.message);
  //     }
  //   }, 2000);
  // }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        padding: '1em', width: '100%', maxWidth: `${MAX_WIDTH}px`,
        background: '#000000', height: '100%', margin: 'auto',
      }}>


      <div
        ref={camViewRef}
        id="camera-container"
        style={{
          maxWidth: `${MAX_WIDTH}px`,
          border: '3.5px dashed #FFFFFF',
          width: isDesktop ? '50%' : '100%',
          color: 'white'
        }}>
        <Camera
          ref={webcamRef}
          facingMode={webcamFacingMode}
          aspectRatio={aspectRatio}
          numberOfCamerasCallback={(noOfCams) => {
            console.log('noOfCams', noOfCams);
          }} />
      </div>
      {/* <img src={rectangle} alt="rectangle" style={{
                position: 'absolute',
                ...camView,
                zIndex: 9999
            }} /> */}
      <Button
        ref={buttonRef}
        variant='outlined'
        color="secondary"
        sx={{
          display: 'none',
          padding: '1em 2em',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '45%',
          maxWidth: MAX_WIDTH_HALF
        }}
        onClick={() => {
          capture();
        }}>
        <CameraAlt />
        &nbsp;
        Capture
      </Button>

      <Button
        ref={flipButtonRef}
        variant='outlined'
        color="brand"
        sx={{
          display: 'none',
          padding: '1em 2em',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'absolute',
          top: '5em',
          right: '2em',
          width: '45%',
          maxWidth: MAX_WIDTH_HALF
        }}
        onClick={() => {
          try {
            UIStore.update((s) => {
              s.webcamFacingMode = webcamFacingMode === 'user' ? 'environment' : 'user';
            });
            webcamRef.current.switchCamera();
          } catch (error) {
            setOpenNoCamDetectedDialog(error.message);
          }
        }}>
        <CameraswitchOutlined />
        &nbsp;
        Flip Camera
      </Button>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={openNoCamDetectedDialog}
        onClose={() => { setOpenNoCamDetectedDialog(null); }}
        sx={{ padding: '1em' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: '1em' }}>

          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}>
            <InfoOutlined color="primary" sx={{ fontSize: 40, marginBottom: '0.4em' }} />
            {openNoCamDetectedDialog}
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: '1em' }}>
            <Button
              variant="outlined"
              id="no-cam-detected-dialog-button"
              sx={{ width: '100%' }}
              onClick={async () => {
                navigate(-1);
              }}>
              Ok
            </Button>
          </Grid>

        </Grid>
      </Dialog>
    </Grid>
  );
};

export default WebcamCapture;
