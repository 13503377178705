import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FullScreenDialog from '../../Components/UI/fullScreenDialog';
import WebcamCapture from '../../Components/Webcam';
import { uploadDocGetAttributes } from '../../Config/api';
import { UIStore } from '../../Config/store';
import { useIsDesktop } from '../../Helper/hooks';
import { MAX_WIDTH, samplePassportImage, statesEnum } from '../../Utils/enums';

import { DoNotDisturbAltOutlined, RemoveRedEyeOutlined, WbSunnyOutlined } from '@mui/icons-material';
import { ReactComponent as PassportInstructions } from '../../Assets/passportInstructions.svg';
import BottomComponent from '../../Components/UI/bottomComponent';
import theme from '../../Config/theme';

// const aspectRatios = {
//   'Passport': 4 / 3,
//   'Voter ID Card': 3 / 4
// };

export default function Scan({ skip = () => { } }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const isDesktop = useIsDesktop();
  const flipButtonRef = useRef(null);
  const webCamCaptureRef = useRef(null);
  const proceedButtonRef = useRef(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [loadingSkip, setLoadingSkip] = React.useState(false);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const form = UIStore.useState((s) => s.form);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];
  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  const title = currentCredentialUnderFetch?.name;
  const docVal = currentCredentialUnderFetch?.key ?? 'passport';
  console.log('image', image);

  return open ? (
    <FullScreenDialog
      open={open}
      setOpen={() => {
        setOpen(false);
        navigate(-1);
      }}
      title={title}
      sx={{ maxHeight: '100vh', background: '#000000', height: '100%' }}
    >
      {process.env.REACT_APP_TEST ? <div style={{
        display: 'hidden'
      }}>
        <button
          style={{ display: 'hidden' }}
          onClick={(e) => setImage(samplePassportImage)}
          id="hidden-input-for-camera" />
      </div> : null}
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          background: '#000000', height: '100%',
        }}>
        {image ? <img
          src={image}
          alt="captured"
          style={{
            width: isDesktop ? '50%' : '100%',
            maxWidth: `${MAX_WIDTH}px`,
            margin: '1em auto'
          }} /> :

          <WebcamCapture
            enable={!image}
            setImage={setImage}
            buttonRef={webCamCaptureRef}
            flipButtonRef={flipButtonRef}
            aspectRatio={currentCredentialUnderFetch?.data?.authOptions?.filter((x) =>
              x.aspectRatio)[0].aspectRatio}
          />}

        {image ? <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: 'absolute', bottom: '5px', width: '95%', margin: '0 8px',
          }}>
          <Button
            variant="contained" color="brand"
            sx={{ width: '45%' }}
            onClick={async () => {
              setImage(null);
            }}>
            Retake
          </Button>
          <Button
            autoFocus={false}
            disableFocusRipple
            disableRipple
            variant="contained"
            color="brand"
            sx={{ width: '45%', bgcolor: '#044DA1 !important' }}
            onClick={async (event) => {
              event.preventDefault();
              setLoading(true);
              const attributes = await uploadDocGetAttributes(image, docVal);
              const obj = {};
              if (attributes) {
                attributes?.forEach((x) => {
                  obj[x.name] = x.value;
                });

                UIStore.update((s) => {
                  s.docData = {
                    docVal: docVal,
                    documentName: title,
                    // image: image,
                    ...obj
                  };

                  s.selectedCredentialAttributes = attributes;
                });
                navigate('/displayInfo', { state: { showRetry: true } });
              }
              setLoading(false);
            }}>
            Proceed
          </Button>
        </Grid> : <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: 'absolute', bottom: '5px', width: '95%', margin: '0 8px',
          }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '95%'
            }}>
            {formInfo[currentCredentialUnderFetch?.key]?.skipAllowed && !formInfo[currentCredentialUnderFetch?.key].markedAsSkip &&
            (
              formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum.NOT_INITIALIZED ||
              formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum.DATA_SAVED
            ) ? <Button
              variant="outlined"
              color="brand"
              loading={loadingSkip}
              sx={{ width: '45%', margin: '1em auto' }}
              onClick={async () => {
                skip();
              }}>
              Skip
            </Button> : null}

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: '100%'
              }}>
              <Button
                variant="contained" color="brand"
                sx={{ width: '45%' }}
                id="flip-camera"
                onClick={() => {
                  flipButtonRef.current.click();
                }}>
                Flip Camera
              </Button>
              <Button
                ref={proceedButtonRef}
                autoFocus={false}
                disableFocusRipple
                disableRipple
                id="capture"
                variant="contained" color="brand"
                sx={{ width: '45%' }}
                onClick={async (event) => {
                  event.preventDefault();
                  proceedButtonRef.current?.blur();
                  webCamCaptureRef.current.click();
                }}>
                Capture
              </Button>
            </Grid>
          </Grid>

        </Grid>}
      </Grid>

      {loading ? <CircularProgress
        sx={{ position: 'absolute', top: '48%', left: '48%' }}
        color="brand" /> : null}
    </FullScreenDialog>
  ) : <Grid
    container
    direction="column"
    justifyContent={'center'}
    alignItems="center"
    sx={{ marginTop: '1em', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
    <Grid item xs={4}>
      <PassportInstructions />
    </Grid>
    <Grid
      item xs={8} sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
      }}>
      <Typography variant="h4" sx={{ fontWeight: 700, textAlign: 'center', width: '100%' }}>
        How to scan
      </Typography>

      <Typography variant="body1" sx={{ textAlign: 'left', color: theme.palette.grey.main }}>
        Position your
        {' '}
        {currentCredentialUnderFetch.name}
        {' '}
        in the frame
      </Typography>

      <Typography variant="body1" sx={{ textAlign: 'left', fontWeight: 700 }}>
        Tips:
      </Typography>

      <Typography
        variant="body1" sx={{
          textAlign: 'left', display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          columnGap: 1
        }}>
        <WbSunnyOutlined />
        <span style={{ color: theme.palette.grey.main }}>
          Find an area with good lighting
        </span>

      </Typography>
      <Typography
        variant="body1" sx={{
          textAlign: 'left', display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          columnGap: 1
        }}>
        <RemoveRedEyeOutlined />
        <span style={{ color: theme.palette.grey.main }}>
          Make sure all details are visible
        </span>

      </Typography>
      <Typography
        variant="body1" sx={{
          textAlign: 'left', display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          columnGap: 1
        }}>
        <DoNotDisturbAltOutlined />
        <span style={{ color: theme.palette.grey.main }}>
          Photocopies are not accepted
        </span>

      </Typography>
      <BottomComponent>
        <Button
          id="capture"
          variant="contained" color="primary"
          sx={{ width: '100%' }}
          onClick={async (event) => {
            setOpen(true);
          }}>
          Start Scanning
          {' '}
          {'>'}
        </Button>
      </BottomComponent>

    </Grid>
  </Grid>;
}
