import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DataCard from '../../Containers/DocResponse/dataCard';
import { displayTime, isDateValid } from '../../Helper/dates';
import { camelCaseToString, checkIfJson, getImageData } from '../../Helper/helpers';
import { ignoreKeysToDisplayCommon } from '../../Utils/enums';
import { defaultFormat } from '../ListDataUI';


export default function ListJson({ sx = {}, data, format = null, item = '' }) {
  const [info, setInfo] = useState('');
  if (!data) {
    return null;
  }

  useEffect(() => {
    setInfo(checkIfJson(data).data);
  }, []);
  console.log('Listjson:format::', data, item, info, format);
  return (
    <ul style={{
      ...sx, padding: '0em 1em', textAlign: 'left', marginTop: typeof info === 'object' ?
        (!Array.isArray(info) ? '8px' : '-4px') : '6px',
      paddingLeft: typeof info !== 'object' ? '0em' : '1em'
    }}>
      {typeof info === 'object' ?
        Object.keys(info)?.map((key, i) => {
          if (ignoreKeysToDisplayCommon.includes(key)) {
            return null;
          }
          const check = checkIfJson(info[key]);
          if (typeof info[key] === 'object' || check.isObject) {
            return <li
              key={`list-${i}`}
              style={{ overflowWrap: 'anywhere' }}>
              <>
                {
                  !Array.isArray(info) && (
                    <b>
                      {camelCaseToString(key)}
                      {': '}
                    </b>
                  )
                }
              </>

              <ListJson
                data={check.isObject ? check?.data : info[key]}
                format={{ ...format, ...defaultFormat }} />
            </li>;
          } else if (typeof info[key] === 'string' || typeof info[key] === 'number') {
            return <li
              key={`list-${i}`}
              style={{ overflowWrap: 'anywhere' }}>
              {Array.isArray(info) ? null : <>
                <b>
                  {camelCaseToString(key)}
                </b>
                :
              </>}
              {' '}
              {
                info[key]?.includes('https://') ?
                  formats.url(info[key]) :
                  Boolean(format && Boolean(format[key])) ?
                    formats[format[key]](info[key]) :
                    (isDateValid(info[key]) ? formats.date(info[key]) : info[key])
              }

            </li>;
          } else {
            return <li
              key={`list-${i}`}
              style={{ overflowWrap: 'anywhere' }}>
              <b>
                {camelCaseToString(key)}
              </b>
              {' '}
              :
              {' '}
              {(info[key]?.toString()?.includes('http://') ?
                formats.url(info[key]?.toString()) : info[key]?.toString())}

            </li>;
          }
        }) : <span style={{ overflowWrap: 'anywhere' }}>
          {Boolean(format && Boolean(format[item])) ? formats[format[item]](info) :
            ((typeof info === 'string' && info?.includes('https://')) ?
              formats.url(info) : info)}

        </span>}
    </ul>
  );
}

const formats = {
  date: (e) => e > 0 ? displayTime(e) : e,
  formattedDate: (e) => displayTime(e, 'DD-MMM-YYYY'),
  epoch: (e) => e > 0 ? displayTime(e * 1000) : e,
  url: (e) => (<Typography
    sx={{ width: '100%', display: 'block', marginTop: 0, paddingTop: '2px', overflowWrap: 'anywhere' }}
    component="a"
    href={e}
    target="_blank"
    rel="noreferrer">
    Link
  </Typography>),
  image: (e) => (<img src={getImageData(e)} alt="user" style={{ maxHeight: '10vh' }} />),
  address: (e) => (<DataCard
    data={e}
    type="address"
    sx={{ margin: 0 }}
  />)
};
