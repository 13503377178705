import { useState } from 'react';

export default function useInfiniteScroll({ fetchData, LIMIT, ref = null }) {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [seed, setSeed] = useState(0);
  const [searchText, setSearchText] = useState('');

  // eslint-disable-next-line default-param-last
  const fetchFn = async (s = seed, count = LIMIT, searchValue) => {
    if (!loading || (loading && list.length === 0)) {
      setLoading(true);
      const res = await fetchData(s, count, searchValue);
      console.log('LIST', list);
      if (res?.list?.length > 0) {
        await Promise.resolve()
            .then(() => { setTotal(res.total); })
            .then(() => { setSeed(s + LIMIT); })
            .then(() => {
              setTimeout(() => {
                setList((x) => {
                  if (s === 0) {
                    return res.list;
                  }
                  return [...x, ...res.list];
                });
                setLoading(false);
                setTimeout(() => {
                  if (ref?.current && s !== 0) {
                    ref.current.scrollBy({ top: 100, behaviour: 'smooth' });
                  }
                }, 200);
              }, 200);
            });
      } else if (s === 0 && res?.list?.length === 0) {
        setTotal(0);
        setSeed(s);
        setList([]);
        setLoading(false);
      }
      setLoading(false);
    }
  };
  const scroll = (event) => {
    const listboxNode = event.currentTarget;
    // eslint-disable-next-line eqeqeq
    if (parseInt(listboxNode.scrollTop + listboxNode.clientHeight) >= (listboxNode.scrollHeight - 10)) {
      if (list?.length < total) {
        fetchFn(seed, LIMIT, searchText);
      }
    }
  };
  return { scroll, setSearchText, total, fetchFn, list, loading };
}
