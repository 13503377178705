/* eslint-disable new-cap */
import { Close } from '@mui/icons-material';
import { IconButton, ListItem, ListItemText } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import './App.css';
import Spinner from './Components/Spinner';
import { rehydrateStore } from './Config/store';
import TawkTo from './Containers/TawkTo';
import { useQuery } from './Helper/helpers';
import Router from './Router';
import { MAX_WIDTH, tawkToConfig } from './Utils/enums';
import { startRefreshAccessTokenAtRegularInterval } from './Utils/tokenUtils';

export const oauthLink = () => {
  const uid = uuid();
  return `/verify?response_type=code&client_id=test1&scope=presentation:passport:read+presentation:voter-id:read+presentation:aadhaar:read+presentation:driving-license:read+presentation:pan:read+presentation:liveness:read&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&state=${uid}`;
};

export default function App() {
  const isMounted = useRef(false);
  const tawkToRef = useRef(null);
  const query = useQuery();
  const tawkTo = tawkToConfig(process.env.REACT_APP_ORG);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [hash, setHash] = useState('');
  const location = useLocation();

  if (!isMounted.current) {
    rehydrateStore();
    // your code here, executes before render
    const r = document.querySelector(':root');
    r.style.setProperty('--MAX_WIDTH', `${MAX_WIDTH}px`);

    window.snackbar = (variant, msg) => {
      // console.log(msg);
      if (msg) {
        console.info('in snackbar');
        const key = enqueueSnackbar(
            (<ListItem
              sx={{ width: '100%' }}
              secondaryAction={<IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => closeSnackbar(key)}
                // sx={{ position: 'absolute', top: '0px', right: '0px' }}
              >
                <Close fontSize="small" />
              </IconButton>}>
              <ListItemText
                primary={msg?.slice(0, 1).toUpperCase().concat(msg?.slice(1))}
                primaryTypographyProps={{ variant: 'body2', sx: { color: '#ffffff' } }}
              />
            </ListItem>),
            {
              variant,
              style: { overflowWrap: 'anywhere', zIndex: '9999999 !important' },
              className: 'snackbar',
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
              autoHideDuration: 10000
            // action: (key) => (
            //   <IconButton
            //     sx={{ display: 'none' }}
            //     size="small"
            //     aria-label="close"
            //     color="inherit"
            //     onClick={() => closeSnackbar(key)}
            //   >
            //     <Close fontSize="small" />
            //   </IconButton>
            // ),
            }
        );
      }
    };

    const sso = query.get('sso');
    if (sso || localStorage?.getItem('refreshToken')) {
      startRefreshAccessTokenAtRegularInterval(sso);
    }
    window.navigate = navigate;
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    ReactGA.pageview(location?.pathname + location?.search);
    // mixpanel.track(location?.pathname + location?.search);
  }, [location]);

  useEffect(() => {
    // Initial render has been completed
    // make the flag true
    isMounted.current = true;
  }, []);


  return loading ? <Spinner /> : (
    <>
      {process.env.REACT_APP_TEST ? <a href="" id="test-navigator" style={{display: 'none'}}>
        test-navigator
      </a> : null}
      <Router />
      <TawkTo />
    </>
  );
}


