import { Divider, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getPreStartQuestionList, submitAnswersToPreStartQuestionList } from '../Config/api';
import { UIStore } from '../Config/store';
import { ConstructFormInputs } from '../Containers/DocRequest/form/constructFormInputs';
import { displayTime, isDateValid } from './dates';

export default function usePreStartQuestionList() {
  const [preStartQuestionList, setPreStartQuestionList] = React.useState([]);
  const [input, setInput] = useState([]);
  const [error, setError] = useState([]);
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const form = UIStore.useState((s) => s.form);

  const preStartQuestionIsDisabled = preStartQuestionList?.some((x) => {
    if (x.required && (x.validation?.fn ?
      !(eval(x?.validation?.fn)(input[0][x.fieldName])) : !input[0][x.fieldName]
    )) {
      return true;
    }
  });
  const updateField = (fieldName, event, indexOfRecordSet, makeUpperCase = false, deleteThisKey = false) => {
    const temp1 = { ...input[indexOfRecordSet] };
    if (!deleteThisKey) {
      temp1[fieldName] = event.target.value;
    } else {
      delete temp1[fieldName];
    }
    const temp2 = [...input];
    console.log('TEMP:1', temp2, temp1);
    temp2[indexOfRecordSet] = temp1;
    console.log('TEMP:2', temp2);
    setInput(temp2);
    // handleSubmitButtonDisability(temp1);
    // updateStore('fieldContent', temp);
  };
  useEffect(() => {
    if (form?.purposeRequired) {
    // Get pre-start question list and set it in the state
      getPreStartQuestionList(searchedQuery.formId, searchedQuery.orgId).then((res) => {
        console.log('result:', res);
        const tempInput = {};
        if (res) {
          const fields = res?.map((item) => {
            const inputX = {
              ...item,
              'type': item.options ? 'autocomplete' : 'text',
              'inputType': item.options ? 'autocomplete' : 'text',
              'label': '',
              'question': item?.question,
              'options': item.options?.map((option) =>{
                if (!option.name) {
                  return {
                    value: option.value,
                    name: option.value,
                  };
                } else {
                  return option;
                }
              })
            };
            tempInput[item._id] = input[item._id] || '';
            return ({
              'validation': {
                'fn': '(e) => { return e?.length > 0; }',
                'message': `${inputX.question} should not be empty`
              },
              ...inputX,
              'required': true,
              'placeholder': '',
              'fieldName': item._id,
              'value': input[item._id],
              'isEditable': true
            });
          });
          setInput([tempInput]);
          setPreStartQuestionList(fields);
        }
      });}
  }, [searchedQuery, form?.purposeRequired]);


  const onPreStartAnswersSubmission = async () => {
    const obj = [];
    const tempErr = [];
    preStartQuestionList?.forEach((x) => {
      if (x.required && (x.validation?.fn ?
        !(eval(x?.validation?.fn)(input[0][x.fieldName])) : !input[0][x.fieldName]
      )) {
        tempErr[x.fieldName] = { message: x.validation.message };
        return false;
      }
      let val = input[0][x.fieldName];
      if (x.inputType === 'date') {
        if (isDateValid(val)) {
          console.log('val::', val);
          val = displayTime(val, x.format);

          console.log('val::2', val, x.format);
        }
      }
      obj.push({ questionId: x.fieldName, answer: val});
      return true;
    });

    setError(tempErr);
    if (tempErr.length > 0) {
      return false;
    }

    return submitAnswersToPreStartQuestionList({
      txnId: searchedQuery.txnId,
      data: obj,
      orgId: searchedQuery.orgId,
    });
  };

  console.log('preStartQuestionList', preStartQuestionList, input);

  const preStartQuestionComponent = preStartQuestionList?.length > 0 ? <Grid container direction={'column'}>
    <Grid item>
      <Typography variant="body1" sx={{textAlign: 'center'}}>
        Please provide the following information
      </Typography>
      <Divider sx={{ width: '100%' }} />
    </Grid>
    <Grid container direction={'column'}>
      {preStartQuestionList
          ?.map((preStartQuestion, i) => <Grid item key={`preStartQuestion-${i}`}>
            <Typography variant="body2" sx={{mb: 0, fontWeight: 600}}>
              {preStartQuestion.question}
            </Typography>
            <ConstructFormInputs
              input={preStartQuestion}
              j={i}
              indexOfRecordSet={0}
              updateField={updateField}
              setError={setError}
              error={error}
              progress={false}
              recordsSet={input[0]}
              disabled={false}
            />
          </Grid>)}

    </Grid>
  </Grid> : null;


  return {
    preStartQuestionComponent,
    onPreStartAnswersSubmission,
    preStartQuestionIsDisabled,
    preStartQuestionListLength: preStartQuestionList.length > 0
  };
}
