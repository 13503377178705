import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { configs } from './configs';

export default function Instructions() {
  // document.title = `Instructions`;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{ width: { sm: '90%', md: '80%' }, margin: 'auto', padding: '1em' }}
    >
      <Typography variant="body1">
        To receive your digital credentials, kindly follow the steps below:
      </Typography>
      <br />
      <Stepper orientation="vertical">
        {configs?.instructions?.map((step, index) => (
          <Step
            active key={`step-instruction${index}`}
            sx={{
              '& .MuiStepIcon-text': {
                fontSize: '1.2rem'
              }
            }}>
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
