/* eslint-disable */
import React, { Fragment } from 'react'
import { Box, Typography } from '@mui/material'
import DividerRow from "../divider-row"

function WatchDemo() {
  return (
    <div id={'wes-auth-watch-demo'}>
    {/* <DividerRow /> */}
    <Box sx={{width :'100%', backgroundColor: '#0A0912', display: 'flex', flexFlow: 'column', alignItems: 'center', pb: '5.5rem', pt: '4.5rem'}}>
       <Box sx={{width: {xs: '80%', lsm: '70%', lmd: '40%', display: 'flex', flexFlow: 'column', alignItems: 'center', textAlign: 'center'}}}>
        <Typography className='wes-auth-caption' sx={{color: '#fff'}}>
          Watch Demo
        </Typography>
        <Typography className='wes-auth-subtitle' sx={{color: '#fff'}}>
          How to <Box component={'span'} sx={{color: '#4D9BE3'}}>Fill Verification Form</Box>
        </Typography>
       </Box>
        <Box component={'video'} sx={{ width: {xs: '95%', md: '80%', lmd: '50%', lg: '45%'}, margin: 'auto', borderRadius: '12px' }} controls controlsList="nodownload">
        <source src={process.env.REACT_APP_DEMO_VIDEO_LINK} type="video/mp4" />
          Your browser does not support HTML video.
        </Box>
    </Box>
    </div>
  )
}

export default WatchDemo