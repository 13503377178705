import { Avatar, Button, CircularProgress, Divider, Grid, ListItemAvatar, Tooltip, Typography, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import emptyBox from '../../Assets/lottie/emptyBox.json';
import SimpleBackground from '../../Components/Background/Simple';
import BackgroundLetterAvatars from '../../Components/UI/backgroundLetterAvatars';
import { getNotificationsData } from '../../Config/api';
import { displayTime, getTimeAgo } from '../../Helper/dates';
import useInfiniteScroll from '../../Helper/useInfiniteScroll';

const LIMIT = 10;

export default function Notifications() {
  const theme = useTheme();
  const location = useLocation();
  const notificationsContainerRef = useRef(null);

  const { setSearchText, total, scroll, fetchFn, list: ntfs,
    loading } = useInfiniteScroll({
    LIMIT,
    fetchData: getNotificationsData,
    ref: notificationsContainerRef
  });

  useEffect(() => {
    fetchFn(0, 20);
  }, [location?.pathname]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        padding: '0px 1em',
        width: '96%',
        backgroundColor: '#ffffff', borderRadius: '16px', margin: '1em 0 1em 1em',
      }}>
      {!['notifications'].includes(location.pathname.split('/')[1]) ?
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: '0em 0', width: '100%', flexWrap: 'nowrap' }}>
          <Typography variant="body1" sx={{ textAlign: 'left' }}>

            Notifications
          </Typography>
          <Button
            variant="text"
            onClick={() => {
              navigate('/notifications');
            }}>
            See All
          </Button>
        </Grid> :
        <SimpleBackground
          sx={{
            //  maxWidth: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            //  height: '100%',
            padding: '0 1em',
            margin: 'auto'
          }}
          title={'Notifications'}></SimpleBackground>}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: '1em 0', width: '100%' }}>


        {ntfs?.length > 0 ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 1, sm: 12, md: 12, lg: 12 }}
            sx={{
              backgroundColor: '#ffffff',
              rowGap: '1em',
              columnGap: '1em',
              padding: '0',
              maxHeight: {
                xs: window.innerHeight,
                md: '90vh'
              },
              margin: '1em 0 !important',
              overflowY: 'auto',
              width: '100% !important',
              paddingBottom: '1em',
              overflowX: 'hidden',
              scrollBehavior: 'smooth',
            }}
            onScroll={scroll}
            ref={notificationsContainerRef}
          >
            <div
              animation="fly left"
              duration={800}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                overflowX: 'hidden',
                width: '100%',
                margin: 0,
              }}>
              {ntfs?.map((x, i) => (
                <>
                  <Grid
                    container
                    sx={{
                      flexDirection: { xs: 'column', sxl: 'row' },
                      justifyContent: { xs: 'center', sxl: 'flex-start' },
                      alignItems: { xs: 'center', sxl: 'flex-start' },
                      margin: '1em 0'
                    }}>
                    <Grid item sx={{ width: 'fit-content', marginRight: '4px', textAlign: 'center' }}>
                      <ListItemAvatar sx={{ padding: '0em', display: 'flex', justifyContent: { xs: 'center', sxl: 'flex-start' } }}>
                        {x?.image ? <Avatar src={x?.image ?? ''} /> : <BackgroundLetterAvatars
                          name={x?.title ?? ''}
                          sx={{ width: '35px', height: '35px' }} />}
                      </ListItemAvatar>
                    </Grid>
                    <Grid item sx={{ flex: 'auto', width: { xs: '100%', sxl: '55%' } }}>
                      <Typography
                        variant="body1" sx={{
                          padding: 0,
                          margin: 0,
                          marginTop: { xs: '0px', sxl: '-2.5px' },
                          fontWeight: 600,
                          textAlign: { xs: 'center', sxl: 'left' },
                          overflowWrap: 'break-word'
                        }}>
                        {x?.title}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: { xs: 'center', sxl: 'left' }, overflowWrap: 'anywhere' }}>
                        {x?.description}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ width: { xs: '100%', sxl: '20%' }, display: 'flex', justifyContent: { xs: 'center', sxl: 'flex-end' } }}>
                      <Tooltip title={displayTime(x?.createdAt, 'DD MMM, YYYY hh:mm:A')}>
                        {getTimeAgo(x?.createdAt, true)}
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {i != ntfs?.length - 1 ? <Divider sx={{ width: '100%' }} /> : null}
                </>

              ))}

            </div>

          </Grid>
        ) : null}
        {loading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <CircularProgress />
          </Grid>
        ) : ((ntfs?.length <= 0 && !loading) ? (<Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center">
          <Lottie
            animationData={emptyBox}
            loop={false}
            style={{ width: '50%' }} />
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            No Notifications Available
          </Typography>
        </Grid>) : null)}
      </Grid>

    </Grid>
  );
}
