import { useQuery } from '../../../Helper/helpers';
import { enums } from '../../../Utils/enums';
import Fallback from './fallback';

export default function OrgSpecific({children}) {
  const query = useQuery();
  switch (isOrgSpecificConditionSatisfied(query.get('orgId'))) {
    case true:
      return <>
        {children}
      </>;

    default: return <Fallback />;
  }
}

export const isOrgSpecificConditionSatisfied = (orgId)=>{
  return orgId === process.env.REACT_APP_PARTNER_ORG_ID || process.env.REACT_APP_PARTNER_ORG_ID === enums.TRENTIAL;
};
