/* eslint-disable */
import { Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, InputAdornment, Typography, useMediaQuery, useTheme } from '@mui/material';
import { intervalToDuration } from 'date-fns';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import OTPInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import eceLogo from '../../../../Assets/ece.svg';
import loginSpiralArtSmall from '../../../../Assets/loginSpiralArt-small.svg';
import loginSpiralArt from '../../../../Assets/loginSpiralArtGrey.svg';
import trentialFullLogo from '../../../../Assets/trential-logo.svg';
import trentialLogo from '../../../../Assets/trentialLogoDark.svg';
import CountryInput from '../../../../Components/UI/CountryInput';
import { createWallet, loginCall, otpCall } from '../../../../Config/api';
import { appendLoggedIn, deleteFromErrors } from '../../../../Helper/helpers';
import { useIsDesktop } from '../../../../Helper/hooks';
import { mixpanel, trackingEvents } from '../../../../Monitoring/Mixpanel';
import { refreshAccessTokenAtRegularInterval } from '../../../../Utils/tokenUtils';
import CustomTextField from '../../../../Components/UI/Input/customTextField';

const minDigitsInPhoneNumber = 4;
const headingText = 'Get education credentials';
const headingText2 = ' ';

const onlyNumbersRegex = /^\d+$/;

const Branding = ({ smallerThanSMScreen, largerThanXLScreen, lowHeightScreen }) => (
  <Box sx={{
    display: 'flex', flexDirection: 'row',
    alignItems: { xs: 'center', md: 'center' }, width: '100%',
    marginTop: '2em',
    justifyContent: 'center'
  }}>
    <Box sx={{
      display: 'flex', flexFlow: 'column', margin: '1em', alignItems: 'flex-start',
      width: '20%',
      maxWidth: '100px'
    }}>

      <img src={eceLogo} style={{ width: '100%' }} />
    </Box>
    <Typography noWrap
      color="#66747F"
      sx={{ fontSize: { xs: '1.2rem', xl: '1.9rem' }, fontWeight: '700' }}>
      In Partnership With
    </Typography>
    {
      !smallerThanSMScreen &&
      <Box sx={{
        display: 'flex', alignItems: 'center',
        maxWidth: '30%', marginLeft: '-0.4em',
      }}>

        <img
          src={trentialFullLogo}
          alt="trential-logo"
          style={{
            width: '100%',
            maxWidth: '150px'
          }}
        />
      </Box>
    }
  </Box>
);

export default function ECEAuth() {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const phoneRef = useRef();
  const smallerThanMDScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smallerThanSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const largerThanXLScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const lowHeightScreen = useMediaQuery('(max-height:760px)');
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [phNo, setPhNo] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('+91');
  const [name, setName] = React.useState('');
  const [loadingPh, setLoadingPh] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [txId, setTxId] = React.useState('');
  const [isLoading, setIsLoading] = React.useState({ requestOTP: false, resendOTP: false, submitOTP: false });
  const [step, setStep] = React.useState(0);
  const [conditionsAgreed, setConditionsAgreed] = React.useState(false);
  const [otp, setOtp] = React.useState('');
  const timerRef = React.useRef(null);
  const [otpTimeoutSeconds, setOtpTimeoutSeconds] = React.useState(process.env.REACT_APP_LOGIN_TIMER_SECONDS);
  const [accountExists, setAccountExists] = React.useState(true);
  const [trackFields, setTrackFields] = React.useState([]);


  useEffect(() => {
    if (step === 1) {
      timerRef.current = setInterval(() => setOtpTimeoutSeconds((prev) => prev - 1), 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [step]);

  useEffect(() => {
    if (otpTimeoutSeconds === 0 && timerRef.current) clearInterval(timerRef.current);
  }, [otpTimeoutSeconds]);

  const getTimeoutTime = () => {
    const time = intervalToDuration({ start: 0, end: otpTimeoutSeconds * 1000 });
    const minutes = (time.minutes?.toString()?.length === 1 ? `0${time.minutes}` : time.minutes) ?? '00';
    const seconds = (time.seconds?.toString()?.length === 1 ? `0${time.seconds}` : time.seconds) ?? '00';
    return `${minutes}  : ${seconds}`;
  };

  const handleRequestOTP = useCallback(debounce(async (q = phNo) => {
    setIsLoading((prev) => ({ ...prev, requestOTP: true }));
    setTrackFields([]);
    mixpanel.track(trackingEvents.auth.events.sign.in.started, {
      username: `${countryCode}-${q}`
    });
    loginCall(`${countryCode}-${q}`)
      .then((z) => {
        console.log('z', z);
        if (z?.code === 0) {
          mixpanel.track(trackingEvents.auth.events.sign.in.requestedOTP, {
            username: `${countryCode}-${q}`
          });
          setTxId(z?.data?.txId);
          setAccountExists(true);
          setTrackFields(['otp']);
          setStep(1);
        } else if (z?.code === 105) {
          mixpanel.track(trackingEvents.auth.events.sign.in.requestedOTP, {
            username: `${countryCode}-${q}`
          });
          setStep(1);
          setTrackFields(['name', 'otp']);

          const id = '';
          mixpanel.track(trackingEvents.auth.events.sign.up.started, {
            username: `${countryCode}-${q}`
          });
          createWallet(id, `${countryCode}-${q}`)
            .then((z) => {
              console.log(z);
              setTxId(z?.data?.txId);
              if (z) {
                mixpanel.track(trackingEvents.auth.events.sign.up.requestedOTP, {
                  username: `${countryCode}-${q}`
                });
                // setCurrentState('otp');
                setTrackFields(['name', 'otp']);
                // setAccountExists(false);
                // setOtp('');
                // setOtpSentTime(new Date());
                // setEnableResendOtp(false);
                // setTimeout(() => {
                //   countDownRef.current.start();
                // }, 1000);
              }
            }).finally((z) => {
              //   setLoading(false);
            });
        }
      }).finally((z) => {
        //   setLoading(false);
        setIsLoading((prev) => ({ ...prev, requestOTP: false }));
      });
  }, 1000), [phNo, countryCode]);

  const startReqOTP = () => {
    const tempErrors = {};
    if (phNo?.length < minDigitsInPhoneNumber) tempErrors.phNo = `At least ${minDigitsInPhoneNumber} digits are needed.`;
    if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);
    setIsLoading((prev) => ({ ...prev, requestOTP: true }));
    handleRequestOTP();
  };
  const handleEnterKeyPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13 && conditionsAgreed && otp?.length === 6 && !isLoading?.submitOTP) submitOtp();
  };

  const handleResendOtp = () => {
    // setLoading('Resend')
    (accountExists ? loginCall(`${countryCode}-${phNo}`) : createWallet(name, `${countryCode}-${phNo}`))
      .then((z) => {
        mixpanel.track(trackingEvents.auth.events.sign.up.started, {
          username: `${countryCode}-${phNo}`
        });
        console.log('z', z);
        if (z?.code === 0) {
          mixpanel.track(trackingEvents.auth.events.sign.up.requestedOTP, {
            username: `${countryCode}-${phNo}`
          });
          setTxId(z?.data?.txId);
          window.snackbar('success', 'OTP sent successfully');
          setOtpTimeoutSeconds(300);
          timerRef.current = setInterval(() => {
            setOtpTimeoutSeconds((prev) => prev - 1);
          }, 1000);
        }
      }).finally((z) => {
        // setLoading(false)
      });
  };

  const submitOtp = () => {
    setIsLoading((prev) => ({ ...prev, submitOTP: true }));
    otpCall(txId, otp)
      .then(async (x) => {
        if (x) {
          mixpanel.track(trackingEvents.auth.events.sign.in.completed, {
            username: `${countryCode}-${phNo}`
          });
          mixpanel.identify(`${countryCode}-${phNo}`);
          refreshAccessTokenAtRegularInterval();
          console.log('trackFields.includes(name)', trackFields.includes('name'));
          // if (trackFields?.includes('name')) {
          //   // if (true) {
          // // await updateWallet(name, x.accessToken);
          // // setShowDetailsForm(true);
          //   return navigate('/profile?loggedIn=true', { state: locationState, disableBack: true });
          // }
          if (locationState?.link) {
            // updateStore('searchedQuery', {
            //   link: state?.from?.pathname + state.from.search,
            // });
            navigate(appendLoggedIn(locationState.link), { state: { ...locationState.nextState } });
          }

          // window.location.reload()
          // navigate(oauthLink())
        }
      }).finally((z) => {
        setIsLoading((prev) => ({ ...prev, submitOTP: false }));
      });
  };
  console.log('countryCode:', countryCode);
  return (
    <Box sx={{
      'display': 'flex', 'flexDirection': { xs: 'column', md: 'row' },
      // rowGap: lowHeightScreen && smallerThanSMScreen ? '2.5vh' : '5.5vh',
      'height': '100%', 'width': '100%', 'alignItems': 'center',
      'justifyContent': { xs: 'space-between', md: 'center' },
      // 'padding': { xs: '1em 2.5em', md: '1vh 5vw', xl: '1vh 8.5vw' },
      // 'pt': { xs: '4.4vh', md: '2vh' }, 'columnGap': '7vw',
      'background': {
        xs: '#ffffff',
        md: lowHeightScreen ? `url(${loginSpiralArtSmall}) left top no-repeat, #ffffff` : `url(${loginSpiralArt}) -25vw 0 no-repeat, #ffffff`
      },
      'backgroundSize': 'contain',
      // 'pb': { xs: '2.8vh', md: 0 }
    }}>
      <Box sx={{
        width: { xs: '100%', md: '45%' },
        height: '80%',
        'display': 'flex',
        'flexDirection': 'column',
        'alignItems': 'center',
        'justifyContent': 'center',
        bgcolor: { xs: '#007BA4', md: 'transparent' },
      }}>
        <div style={{
          position: 'absolute',
          top: '1em',
          right: isDesktop ? '1em' : 'auto',
          margin: 'auto'
        }}>
          <Button
            color="white"
            variant="outlined"
            sx={{
              background: 'transparent',
              width: 'fit-content',
              fontWeight: 500,
              padding: '0.5em 0.6em',
              fontSize: { xs: '1rem', md: '1.2rem' },
              margin: '0.5em'
            }}
            onClick={() => navigate('/faq')}
          >
            Instructions / FAQs
          </Button>
          <Button
            color="white"
            variant="outlined"
            sx={{
              background: 'transparent',
              width: 'fit-content',
              fontWeight: 500,
              padding: '0.5em 0.6em',
              fontSize: { xs: '1rem', md: '1.2rem' },
              margin: '0.5em'
            }}
            onClick={() => navigate('/terms')}
          >
            Terms & Conditions
          </Button>
          <Button
            color="white"
            variant="outlined"
            sx={{
              background: 'transparent',
              width: 'fit-content',
              fontWeight: 500,
              padding: '0.5em 0.6em',
              fontSize: { xs: '1rem', md: '1.2rem' },
              margin: '0.5em'
            }}
            onClick={() => navigate('/privacy-policy')}
          >
            Privacy Policy
          </Button>
        </div>

        <Box sx={{
          display: 'flex',
          flexFlow: 'column',
          width: '100%',
        }}>
          <Typography
            sx={{
              fontSize: { xs: '3.5rem', md: lowHeightScreen ? '3.8rem' : '4.4rem', xl: '5.5rem' },
              fontWeight: '900',
              textAlign: 'center',
              width: '100%',
              margin: 'auto',
              color: { xs: '#ffffff', md: '#404052' }
            }}>
            {headingText}
            <br />
            {headingText2}
            <Box sx={{ color: { xs: '#ffffff', md: '#047FFF' } }}>verified digitally!</Box>
          </Typography>

        </Box>
        {
          !smallerThanMDScreen &&
          <>   <Branding smallerThanSMScreen={smallerThanMDScreen} largerThanXLScreen={largerThanXLScreen} />
            {(process.env.REACT_APP_ORG_MAIL && !smallerThanSMScreen) && <>

              <div style={{
                position: 'absolute',
                bottom: '1em',
                right: isDesktop ? '1em' : 'auto',
              }}>
                <Typography variant="body1" sx={{ color: '#ffffff' }}>
                  For support, contact
                  {' '}
                  <a
                    href={`mailto:${process.env.REACT_APP_ORG_MAIL}`}
                    style={{ color: '#ffffff' }}>
                    {' '}
                    {process.env.REACT_APP_ORG_MAIL}
                  </a>
                </Typography>
              </div>
            </>}
          </>

        }
      </Box>
      <Box sx={{
        width: { xs: '100%', md: '55%' },
        bgcolor: { xs: '#ffffff', md: '#007BA4' },
        height: '100%',
        'display': 'flex',
        'flexFlow': { xs: 'column', md: 'row' },
        'alignItems': 'center',
        'justifyContent': 'center',
      }} >
        <Box sx={{
          marginTop: { xs: '-20vh', md: '0px' }, width: '100%',
          height: '100%', display: 'flex', flexDirection: 'column'
        }}>
          <Card sx={{
            width: { xs: '80%', sm: '60%', md: '80%' },
            margin: 'auto'
          }}>
            {process.env.REACT_APP_UNDER_MAINTAINENCE == 'true' ? <Box sx={{
              display: 'flex', flexFlow: 'column', backgroundColor: '#404052',
              borderRadius: '8px',
              padding: { xs: '.8rem 2.4rem', md: lowHeightScreen ? '2.8rem 5.5rem' : '4rem 5.5rem', xl: '4rem 9rem' },
              width: { xs: '100%', sm: '48%' }
            }}
            >
              <Typography>
                <h1>
                  We&rsquo;ll be back soon!
                </h1>
                <Typography sx={{ fontSize: { xs: '1.2rem', xl: '1.5rem' }, typography: 'p' }}>
                  Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment from
                  {' '}
                  <b>
                    {process.env.REACT_APP_UNDER_MAINTAINENCE_TIME}
                  </b>
                  . If you need to contact, you can
                  {' '}
                  <a href={`mailto:${process.env.REACT_APP_ORG_MAIL}`}>
                    reach out
                  </a>
                  , otherwise we&rsquo;ll be back online shortly!
                </Typography>
              </Typography>
            </Box> : <Box sx={{
              display: 'flex', flexFlow: 'column', backgroundColor: '#fff',
              borderRadius: '8px',
              padding: { xs: '.8rem 2.4rem', md: lowHeightScreen ? '2.8rem 5.5rem' : '4rem 5.5rem', xl: '4rem 9rem' },
              width: '100%'
            }}
            >
              <Typography sx={{ fontSize: { xs: '1.8rem', xl: '2.5rem' }, fontWeight: '700', typography: 'h1' }}>
                Sign in with OTP
              </Typography>
              <Typography sx={{ fontSize: { xs: '1.4rem', xl: '1.85rem' } }}>
                {step === 1 ? 'Enter the OTP' : 'Enter your mobile number'}
              </Typography>
              {
                step === 0 ?
                  <CustomTextField
                    ref={phoneRef}
                    required
                    fullWidth autoFocus
                    sx={{
                      'margin': '1em 0',
                      '& .MuiInputBase-root': {
                        paddingLeft: 0,
                      }, '& .MuiInputBase-input': {
                        borderLeft: `1px solid rgba(0, 0, 0, 0.20)`,
                        borderRadius: 0,
                        paddingLeft: '0.2em'
                      }
                    }}
                    error={errors?.phNo?.length > 0}
                    helperText={errors?.phNo?.length > 0 && errors?.phNo !== 'required' && errors?.phNo}
                    id="Phone Number"
                    value={phNo}
                    onChange={(event) => {
                      event.target.value = event.target.value.trimStart();
                      if (event?.target?.value !== '' && !onlyNumbersRegex.test(event.target.value)) return;
                      if (event.target.value?.length <= 12) {
                        setPhNo(event.target.value);
                      }
                      if (errors?.phNo) deleteFromErrors(errors, setErrors, 'phNo');
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment
                        position="start" sx={{ marginRight: '0px' }}>
                        <CountryInput
                          countryCode={countryCode} setCountryCode={setCountryCode}
                          phoneRef={phoneRef} />
                      </InputAdornment>,
                      endAdornment: loadingPh ? <InputAdornment position="end">
                        <CircularProgress />
                      </InputAdornment> : null,

                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && conditionsAgreed && phNo?.length >= minDigitsInPhoneNumber && !isLoading.requestOTP) {
                        startReqOTP();
                      }
                    }}
                  /> :
                  <Box>
                    <OTPInput
                      value={otp}
                      inputType={'tel'}
                      onChange={(val) => {
                        setOtp(val);
                        if (errors?.otp) deleteFromErrors(errors, setErrors, 'otp');
                      }}
                      numInputs={6}
                      renderSeparator={(index) =>
                        index === 2 ?
                          <div style={{ width: '1.7rem', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', height: '1px', margin: '0 2rem' }} /> :
                          <div style={{ width: '2rem' }}></div>
                      }
                      renderInput={(props) => <input {...props} onKeyPress={handleEnterKeyPress} />}
                      inputStyle={{
                        height: largerThanXLScreen ? '10rem' : smallerThanSMScreen ? '5.8rem' : '8rem',
                        width: largerThanXLScreen ? '8rem' : smallerThanSMScreen ? '9vw' : '6rem',
                        borderRadius: '.4rem',
                        border: errors?.otp?.length > 0 ? '1.5px solid #ff0000' : '1.2px solid #eee',
                        backgroundColor: '#f3f8ff',
                        fontSize: largerThanXLScreen ? '2.25rem' : smallerThanSMScreen ? '1.7rem' : '1.85rem'
                      }}
                      shouldAutoFocus
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '2rem' }}>
                      {
                        otpTimeoutSeconds === 0 ?
                          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 0 }}>
                            <Typography sx={{ fontSize: { xs: '1.3rem', xl: '1.85rem' } }}>
                              {'Haven\'t received OTP?'}
                            </Typography>
                            <Button
                              sx={{ fontSize: { xs: '1.3rem', xl: '1.85rem' }, fontWeight: '700' }}
                              onClick={handleResendOtp}
                            >
                              Resend OTP
                            </Button>
                          </Box> :
                          <>
                            <Typography sx={{ fontWeight: '400', mr: '1rem', fontSize: { xs: '1.25rem', xl: '1.85rem' } }}>
                              OTP Expires in:
                              {' '}
                            </Typography>
                            <Typography sx={{ fontSize: { xs: '1.25rem', xl: '1.85rem' }, fontWeight: '700' }}>
                              {getTimeoutTime()}
                            </Typography>
                          </>
                      }
                    </Box>
                  </Box>
              }
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  label={
                    <Typography sx={{ fontSize: { xs: '1.2rem', xl: '1.7rem' }, color: '#6D6D6D' }}>
                      {'I agree to the '}
                      <a
                        style={{ textDecoration: 'none', color: '#044DA1', cursor: 'pointer' }}
                        href={'https://trential.com/terms'}
                        target="_blank"
                        rel="noreferrer"
                      >
                        terms and conditions
                      </a>
                    </Typography>}
                  control={
                    <Checkbox
                      size={'medium'}
                      checked={conditionsAgreed}
                      onChange={(e) => setConditionsAgreed(e.target.checked)}
                    />
                  }
                />
              </Box>
              <Button
                color="ece"
                variant="contained"
                onClick={() => {
                  if (step === 0) return startReqOTP();
                  submitOtp();
                }}
                disabled={!conditionsAgreed || (step === 0 && phNo?.length < minDigitsInPhoneNumber) || (step === 1 && otp?.length < 6) || isLoading?.requestOTP || isLoading?.submitOTP}
                sx={{ fontSize: { xs: '1.4rem', xl: '1.85rem' } }}
                startIcon={(isLoading?.requestOTP || isLoading?.submitOTP) && <CircularProgress style={{ height: '1.3rem', width: '1.3rem' }} />}
              >
                {step === 0 ? 'Request OTP' : 'Continue'}
              </Button>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: { xs: '.2rem', xl: '3.2rem' } }}>
                <Typography sx={{ fontSize: { xs: '1.4rem', xl: '1.85rem' }, color: '#66747F', mr: '.5rem' }}>
                  Powered by
                </Typography>
                <img src={trentialLogo} style={{ height: smallerThanSMScreen ? '1.9rem' : '2.4rem', width: 'auto' }} />
              </Box>
            </Box>}

          </Card>

          {
            smallerThanMDScreen &&
            <Box sx={{ bottom: '1em', width: '100%' }}>
              <Branding smallerThanSMScreen={!smallerThanMDScreen} largerThanXLScreen={!largerThanXLScreen} />
              <div style={{
                // position: 'absolute',
              }}>
                <Typography variant="body1" sx={{ textAlign: 'center', color: '#000000', }}>
                  For support, contact
                  {' '}
                  <a
                    href={`mailto:${process.env.REACT_APP_ORG_MAIL}`}
                    style={{ color: '#000000' }}>
                    {' '}
                    {process.env.REACT_APP_ORG_MAIL}
                  </a>
                </Typography>
              </div>
            </Box>

          }
        </Box>
      </Box>
    </Box >
  );
}

