import Markdown from 'markdown-to-jsx';
import {ErrorBoundary} from 'react-error-boundary';

export default function MarkdownWrapper({children}) {
  try {
    if (children) {
      return (
        <ErrorBoundary fallback={<>
          {children}
        </>}>
          <Markdown
            options={{
              overrides: {
                a: {
                  props: {
                    target: '_blank',
                  },
                },
                ul: {
                  props: {
                    style: {marginTop: '1em'},
                  },
                },
                li: {
                  props: {
                    style: {marginLeft: '1em'},
                  },
                },
              },
            }}>
            {children}
          </Markdown>
        </ErrorBoundary>

      );
    } else {
      return (<>
        {children}
      </>);
    }
  } catch (e) {
    // console.log(children.toString(), e);
    return children;
  }
}
