export default {
  outerWrapper: {
    width: '100%',
    background: 'linear-gradient(90deg, #1C43C3 0%, #0F2A80 100%)',
    display: 'flex', flexFlow: 'column',
  },
  questionWrapper: {
    'width': '100%',
    'p': '1.35rem 1.5rem',
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'backgroundColor': '#4259A8',
    '&:hover': {
      backgroundColor: '#4259A8',
    }
  },
  answerWrapper: {
    backgroundColor: '#122F8E',
    p: '1.5rem',
    borderLeft: '1px solid rgba(255, 255, 255, 0.3)',
    borderRight: '1px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '0 0 6px 6px'
  }
};
