import { Grid, Typography } from '@mui/material';
import React from 'react';
import SimpleBackground from '../../Components/Background/Simple';
import MarkdownWrapper from '../../Components/UI/markdownWrapper';
import { configTnC } from './configTnC';


export default function TnC() {
  return (
    <SimpleBackground
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        height: '100%',
        padding: '0 1em',
        margin: 'auto'
      }}
      title={'Terms and Conditions'}>
      {' '}
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          margin: '1em 0'
        }}>
        <Typography
          variant="body1" sx={{
          }}>
                Last edited on 19th February 2024
        </Typography>

        {configTnC?.map((x) => <Typography
          variant={x?.type} sx={{
          }}>
          <MarkdownWrapper>
            {x.text}
          </MarkdownWrapper>
          {' '}
        </Typography>)}
      </Grid>
    </SimpleBackground>
  );
}
