import { Card } from '@mui/material';
import React from 'react';
import { useIsDesktop } from '../../Helper/hooks';


export default function MainCard({children, sx, ...props}) {
  const isDesktop = useIsDesktop();
  return (
    <Card
      sx={{
        boxShadow: 'none',
        width: isDesktop ? '30%' : '90%',
        minWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        margin: '1em auto',
        border: '1px solid hsla(0, 0%, 88%, 1)',
        ...sx
      }}
      {...props}
    >
      {children}
    </Card>
  );
}
