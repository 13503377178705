/* eslint-disable */
import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Footer from './footer'
import Login from './login'
import MobileLogin from './login/mobile-ui'
import './wes-auth-styles.css'

function NSDCAuth() {
  const theme = useTheme();
  const smallerThanSMScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box className={'wes-auth'} sx={{fontFamily: "Figtree", width: '100%'}}>
     {
      smallerThanSMScreen ?
      <MobileLogin /> :
      <Login />
      }
      <Footer/>
    </Box>
  )
}

export default NSDCAuth