import { Grid, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SimpleBackground from '../../Components/Background/Simple';
import { getWallet } from '../../Config/api';
import { UIStore } from '../../Config/store';
import BasicDetails from '../Verify/main/basicDetails';

export default function MandatoryFormsToFill({ }) {
  const theme = useTheme();
  const { state: locationState } = useLocation();
  const form = UIStore.useState((s) => s.form);
  const isAuthenticated = UIStore.useState((s) => s.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      getWallet()
          .then((res) => {
            if (res?.code === 0) {
            }
          });
    }
  }, [isAuthenticated]);

  console.log('Form configsForMandatoryFormsToFill:', form);
  return (
    <SimpleBackground
      sx={{
        // maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        height: '100%',
        padding: '0 1em',
        margin: 'auto'
      }}
      title={'Submit the following steps below to complete profile'}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          borderRadius: '0px 0px 36px 36px',
          padding: 0,
          flex: 1,
          // height: '100%',
          flexWrap: 'nowrap'
        }}>
        <BasicDetails />
      </Grid >
    </SimpleBackground>

  );
}
