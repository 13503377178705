import React, { useEffect, useState } from 'react';
import { getCaptcha } from '../../Config/api';
import { CaptchaInput } from './input';

export default function Captcha({ captchaId, argsForFetcher, setCaptchaId, captchaInput, setCaptchaInput }) {
  const [captcha, setCaptcha] = useState(null);

  console.log(':: argsForFetcher ::', argsForFetcher);
  async function fetchCaptcha() {
    const res = await getCaptcha(argsForFetcher)
        .then((c) => {
          setCaptcha(c?.captchaSvg);
          setCaptchaId(c?.captchaId);
        });
  }

  useEffect(() => {
    let captchaInterval = '';

    fetchCaptcha();
    captchaInterval = setInterval(() => {
      fetchCaptcha();
    }, 1.8 * 60 * 1000);

    return () => {
      clearInterval(captchaInterval);
    };
  }, []);
  return (
    <CaptchaInput
      captcha={captcha}
      fetchCaptcha={fetchCaptcha}
      value={captchaInput}
      onChange={(e) => {
        setCaptchaInput(e.target.value);
      }}
    />
  );
}
