import React from 'react';
import nsdcMotif from '../../Assets/nsdc.jpg';
import trentialMotif from '../../Assets/trentialMotif.svg';
import './styles.css';

export default function Spinner() {
  return (
    <div
      className="loader-wrapper"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        position: 'absolute',
        top: 0,
        left: 0,
      }}>

      <span
        id="loader"
        style={{
          // backgroundColor: '#ffffff',
          borderBottom: `2px solid #037FFF`,
          borderTop: `2px solid #037FFF`,
        }}>
        <span
          id=""
          style={{
            backgroundColor: '#ffffff',
          }} />

      </span>
      <img
        className="loading-img"
        src={process.env.REACT_APP_WES_AUTH ? nsdcMotif : trentialMotif}
        alt="spinner" />
    </div>
  );
}
