import { Button, Typography, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import error from '../../../Assets/lottie/error.json';
import { UIStore } from '../../../Config/store';
import { useIsDesktop } from '../../../Helper/hooks';
import AnimatedCard from '../../UI/animatedCard';
import BottomComponent from '../../UI/bottomComponent';

export default function Fallback({ data }) {
  const navigate = useNavigate();
  const lottieRef = useRef(null);


  return <AnimatedCard lottieRef={lottieRef}>
    <Lottie
      lottieRef={lottieRef}
      animationData={error} loop={false}
      style={{ width: '26%' }}
      autoplay={false} />
    <Typography
      variant={'h5'}
      color="black"
      sx={{
        margin: '1em 0',
        textAlign: 'center',
        fontWeight: 600
      }}>
      You do not have permission to access this form
    </Typography>
    {/* <Typography
      variant={'h6'}
      color="grey"
      sx={{
        margin: '0em 1em 0em',
        textAlign: 'center',
        fontWeight: 400
      }}>
      We have begun your verification process. Check back again in some time to view the verification status.
    </Typography> */}


    <BottomComponent>
      <Button
        variant="text" color="primary"
        onClick={() => {
          navigate('/');
        }}>
        Go back to Home
      </Button>
      <br />
      <br />
    </BottomComponent>


  </AnimatedCard>;
}
