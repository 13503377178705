/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Grid } from '@mui/material'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ForwardArrowIcon from '../../Assets/forward-arrow.svg'
import { useLocation, useNavigate } from 'react-router-dom';

export default function ProviderCard({data}) {
  const navigate = useNavigate();
  return (
    <Box onClick={()=> navigate(data?.link)} sx={{ width: "100%", mt: 2, cursor: "pointer" }}>
       {/* <Typography sx={{ fontSize: 14 }} variant="p" color="#8F8F92" gutterBottom>
        {data?.label}
      </Typography> */}
      <Card variant="outlined">
          <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
            <Grid item xs={3} lg={2}>
            <img src={data?.logo} style={{ width: '100px',  }} />

            </Grid>
            <Grid item xs={8} lg={9} sx={{ml: 1}}>
              <Typography sx={{ fontSize: 16 }} variant="h1" color="#000" gutterBottom>
                {data?.name}
                <Typography color="#515151">
                {data?.info}
              </Typography>
              </Typography>
            </Grid>
            <Grid item>
                <img src={ForwardArrowIcon} />
            </Grid>
          </Grid>
      </Card>
    </Box>
  );
}