import { CheckCircleOutline } from '@mui/icons-material';
import { Alert, Button, Card, CardContent, Chip, Collapse, Grid, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import FileInput from '../../../Components/FileUpload';
import CustomTextField from '../../../Components/UI/Input/customTextField';
import { UIStore } from '../../../Config/store';

export default function InsufficiencyCard({ index, id, bgv, item, setInfo,
  infoStatusPerRequestId, setInfoStatusPerRequestId, setInfoToSubmit, field, setField, files, setFiles,
  response }) {
  const theme = useTheme();
  const [errorInText, setErrorInText] = useState(false);
  const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
  const [filesError, setFilesError] = useState({});
  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  console.log('infoStatusPerRequestId?.[item.insufficiencyId]', infoStatusPerRequestId, item);

  // useEffect(() => {
  //   if (response?.done) {
  //     setInfoStatusPerRequestId({ ...infoStatusPerRequestId, [item.insufficiencyId]: true });
  //     console.log('response?.data? status:', response);
  //     const temp = {
  //       textDataMap: response?.response?.[index]?.data?.textDataMap,
  //       documents: response?.response?.[index]?.documents
  //     };
  //     setInfoToSubmit(temp);
  //     console.log('boohoo: status:', temp, item?.displayName);
  //     setField(temp?.textDataMap?.[item?.displayName]);
  //   }
  // }, []);


  return (
    <Card
      variant="outlined" sx={{
        width: '100%',
        border: 'none',
        borderRadius: '8px',
        // boxShadow: '0px 4px 10px 0px #ADADAD29'

      }}>
      <CardContent
        sx={{
          padding: '0px !important',
          width: '100%'
        }}>
        <Typography
          variant="body2"
          sx={{
            margin: 0,
            padding: '1em',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            bgcolor: infoStatusPerRequestId?.[item.insufficiencyId] ? theme.palette.success.light : theme.palette.error.light,
            color: infoStatusPerRequestId?.[item.insufficiencyId] ? theme.palette.success.main : theme.palette.error.main,
            fontWeight: 800
          }}>
          {item?.displayName}
        </Typography>

        <Collapse in={!infoStatusPerRequestId?.[item.insufficiencyId]}>
          <Grid
            container
            direction="column"
            justifyContent={'center'}
            alignItems="center"
            sx={{
              padding: '1em',
              width: '100%'
            }}>
            <Typography
              variant="body2" sx={{
                width: '100%'
              }}>
              {item?.description}
            </Typography>
            {item?.remark ?
              <Alert
                severity="info" color="warning"
                sx={{ width: '100%' }}>
                {/* <AlertTitle>
                  Remark
                </AlertTitle> */}
                {item?.remark}
              </Alert> : null}

            {/* <CustomTextField
              fullWidth
              variant={'outlined'}
              type={'text'}
              sx={{ margin: '1em 0' }}
              inputProps={{
                style: { paddingRight: '16px' },
              }}
              label={`User Input`}
              value={field || ''}
              onChange={(event) => {
                setErrorInText('');
                setField(event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
              helperText={errorInText || <b style={{ fontSize: '1.1rem' }} >
                Enter the missing information
              </b>}
              FormHelperTextProps={{
                sx: {
                }
              }}
              error={Boolean(errorInText || false) || false}
            /> */}

            <CustomTextField
              multiline
              minRows={1}
              maxRows={4}
              fullWidth
              variant={'outlined'}
              type={'text'}
              sx={{ margin: '1em 0' }}
              inputProps={{
                style: { paddingRight: '16px' },
              }}
              id="insufficiency-text"
              label={`User Input`}
              value={field || ''}
              onChange={(event) => {
                event.target.value = event.target.value.trimStart();
                setErrorInText('');
                setField(event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
              helperText={errorInText || <b style={{ fontSize: '1.1rem' }} >
                Enter the missing information
              </b>}
              FormHelperTextProps={{
                sx: {
                }
              }}
              error={Boolean(errorInText || false) || false}
            />

            {Object.keys(files)?.map((file, i) => <Collapse
              in
              sx={{ width: '100%' }}>
              {' '}
              <FileInput
                key={`file-${i}`}
                input={{
                  fieldName: file,
                  required: false,
                  label: 'Would you like to upload a file'
                }}
                onChange={(a, b) => {
                  setFiles({ ...files, [a]: b });
                }}
                error={{ file: { message: '' } }}
                data={files}
                defaultHelperText={(<b style={{ fontSize: '1.1rem' }} >
                  ⚡Upload supporting documents for faster resolution
                </b>)} />

            </Collapse>)}

            {Object.keys(files)?.length < Number(process.env.REACT_APP_NUMBER_OF_FILE_UPLOADS_IN_INSUFFICIENCY) ?
              <Button onClick={() => setFiles({ ...files, [Object.keys(files).length]: null })}
              >
                Add Another File
              </Button> : null}

          </Grid>
        </Collapse>
        {infoStatusPerRequestId?.[item.insufficiencyId] ?
          <Grid
            container
            direction="row"
            justifyContent={'space-between'}
            alignItems="center"
            sx={{
              padding: '1em',
              width: '100%'
            }}>
            <Grid item xs={8}>
              <Typography
                variant="body2" sx={{
                }}>
                Remarks:
                {' '}
                {field}
              </Typography>
              {Object.keys(files).filter((zz) => files[zz] != null)?.length > 0 ?
                <>
                  <Typography variant="body2">
                    Files Uploaded
                  </Typography>
                  <ul>
                    {Object.keys(files)?.filter((x) => files[x])?.map(((zz) => <li>
                      {files[zz]?.name}
                    </li>))}
                  </ul>
                </> :

                null}
            </Grid>

            <Grid
              item xs={4} sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}>
              <Chip
                color="primary" variant="outlined"
                sx={{
                  'border': 'none',
                  '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
                }}
                size="small"
                icon={<CheckCircleOutline />}
                label="Saved" />
            </Grid>
          </Grid> :
          null}
      </CardContent>
    </Card>
  );
}
