import { AccessTime } from '@mui/icons-material';
import { Alert, Button, Grid, Typography, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import greenTick from '../../Assets/lottie/greenTick.json';
import Spinner from '../../Components/Spinner';
import AnimatedCard from '../../Components/UI/animatedCard';
import BottomComponent from '../../Components/UI/bottomComponent';
import { getTransactionsInfo } from '../../Config/api';
import { UIStore } from '../../Config/store';
import { displayTime } from '../../Helper/dates';
import { getFetchProcessInterval } from '../../Helper/helpers';
import { useIsDesktop } from '../../Helper/hooks';
import { statesEnum } from '../../Utils/enums';

export default function Completed({ data }) {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const lottieRef = useRef(null);
  const form = UIStore.useState((s) => s.form);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const [showSmall, setShowSmall] = useState(null);

  useEffect(() => {
    getTransactionsInfo(searchedQuery?.orgId, searchedQuery?.txnId)
        .then((x) => {
          console.log('getTransactionsProcess', x);
          if (x?.shared) {
            const vcngs = x.shared?.filter((z) => z.state === 'COMPLETED');
            if (vcngs?.length === x.exists?.length) {
              setShowSmall(false);
            } else {
              setShowSmall(true);
            }
          }
        });
  }, []);

  useEffect(() => {
    if (form.state === statesEnum.COMPLETED) {
      setTimeout(() => {
        setShowSmall(false);
      }, 1000);
    }


    setTimeout(() => {
      clearInterval(getFetchProcessInterval());
    }, 20000);
  }, [form.state]);


  return showSmall === null ? <Spinner /> : (
    <AnimatedCard lottieRef={lottieRef}>

      <Lottie
        lottieRef={lottieRef}
        animationData={greenTick} loop={false}
        style={{ width: '26%' }}
        autoplay={false} />
      <Typography
        variant={'h5'}
        color="black"
        sx={{
          margin: '1em 0',
          textAlign: 'center',
          fontWeight: 600
        }}>
        {
          (formInfo?.data?.payment?.formAcceptsPayment) ?
            'Your Payment was Successful' : 'Verifications Submitted Successfully'
        }
      </Typography>
      <Typography
        variant={'h6'}
        color="grey"
        sx={{
          margin: '0em 1em 0em',
          textAlign: 'center',
          fontWeight: 400
        }}>
        We have begun your verification process. Check back again in some time to view the verification status.
      </Typography>

      {formInfo?.data?.verificationEstimatedCompletionDate ? <Alert
        color="info"
        icon={<></>}
        sx={{
          'width': '95%',
          'margin': '4em 0em',
          'bgcolor': '#F4FCFD',
          '& .MuiAlert-message': {
            width: '100%',
            padding: 0,
          }
        }}>
        <Grid
          container direction="column"
          justifyContent="center" alignItems="center"
          sx={{ flexWrap: 'nowrap', width: '100%', padding: '1em' }}>
          <AccessTime color="primary" sx={{ fontSize: '2rem' }} />
          <Typography
            variant="body1" sx={{
              width: '100%',
              overflowWrap: 'anywhere',
              textAlign: 'center',
            }}>
            Expected Verification completion by
          </Typography>

          <Typography
            variant="body2" sx={{
              textAlign: 'center',
              width: '100%',
              fontSize: '2rem',
              fontWeight: 700,
              margin: '-0.4rem',
              padding: 0,
              color: '#094754'
            }}>
            {displayTime(formInfo?.data?.verificationEstimatedCompletionDate, 'DD MMMM YYYY')}
          </Typography>

        </Grid>
      </Alert> : null}
      <BottomComponent>
        <Button
          variant="text" color="primary"
          onClick={() => {
            navigate('/');
          }}>
          Go back to Home
        </Button>
        <br />
        <br />
      </BottomComponent>


    </AnimatedCard>
  );
}
