import * as Sentry from '@sentry/react';

const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set "tracePropagationTargets" to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: process.env.REACT_APP_ENV === 'development' ? 0 : 1.0, // Capture 100% of the transactions
    environment: process.env.REACT_APP_ENV,
    // Session Replay
    replaysSessionSampleRate: process.env.REACT_APP_ENV === 'development' ? 0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: process.env.REACT_APP_ENV === 'development' ? 0 : 1.0, // If you"re not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export { initSentry };
