import { Grid, Skeleton } from '@mui/material';
import React from 'react';

export default function SkeletonFormScreen() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        borderRadius: '8px',
        flexWrap: 'nowrap',
      }}>

      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: '8px',
          margin: '1em auto',
        }}
        width={'95%'}
        height={180} />
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: '8px',
          margin: '1em auto',
        }}
        width={'95%'}
        height={120} />
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: '8px',
          margin: '1em auto',
        }}
        width={'95%'}
        height={80} />
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: '8px',
          margin: '1em auto',
        }}
        width={'95%'}
        height={80} />
    </Grid>
  );
}
