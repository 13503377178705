
import { CheckCircleOutline, ChevronRight, SwitchAccessShortcut } from '@mui/icons-material';
import { Avatar, Chip, CircularProgress, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import greenCheckThorned from '../../../Assets/greenCheckThorned.svg';
import { getDataOfCompletedForm, getTransactionsInfo, getTransactionsProcess } from '../../../Config/api';
import { UIStore, updateStore } from '../../../Config/store';
import { getFetchProcessInterval, triggerCancellationController, useQuery, vInfoCheck } from '../../../Helper/helpers';
import { categoriesOrder, getCategories, states, statesEnum, typesOfForm } from '../../../Utils/enums';


export default function VerifyList({ enabledDocs, setEnabledDocs, isUserInputRequired, setOpenStartingProcessDialog }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();
  const { state } = useLocation();
  const lottieRef = useRef(null);
  const [loading, setLoading] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [selecetedCred, setSelecetedCred] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const form = UIStore.useState((s) => s.form) ?? {};
  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  const isAuthenticated = UIStore.useState((s) => s.isAuthenticated);
  const formUnderProcess = UIStore.useState((s) => s.formUnderProcess);
  const configs = UIStore.useState((s) => s.configs);
  const userInput = UIStore.useState((s) => s.userInput);
  const formInfo = UIStore.useState((s) => s.formInfo);

  const currentUserData = UIStore.useState((s) => s.currentUserData);
  const [txInfo, setTxInfo] = React.useState({});
  const txnId = params?.txnId || searchedQuery?.txnId;
  const userInputCompletedCheck = userInput?.additionalInfo?.authOptions[0]?.page?.config?.[0]?.inputs?.[0]?.value &&
    ![statesEnum.NOT_INITIALIZED].includes(form?.state);

  useEffect(() => {
    const acc = {};
    const isAllow = Boolean(form?.manualProfileRequired ? form?.
        manualProfileCompleted :
      (currentUserData?.profileDetails?.name));
    formUnderProcess?.[searchedQuery?.txnId]?.sortedList
        ?.forEach((x) => {
          acc[x] = isAllow ? 'DONE' : false;
        });

    setEnabledDocs(acc);
  }, [form.list, currentUserData]);

  const handleVerificationClick = async (c) => {
    const showUserInput = isUserInputRequired(() => {
      updateStore('isAutoplay', false);
      setSelecetedCred(null);
    });
    if (showUserInput) {
      return null;
    }


    if (form?.state === 'NOT_INITIALIZED') {
      const responseForStarting = await getTransactionsProcess();
      if (responseForStarting?.error) {
        return null;
      }
    }
    clearInterval(getFetchProcessInterval());
    triggerCancellationController();
    if (selecetedCred !== c) {
      // if (!(
      //   formInfo[c]?.state === statesEnum.NOT_INITIALIZED ||
      //   formInfo[c]?.state === statesEnum.DATA_SAVED
      // ) && form?.list?.[c]?.markedAsSkip) {
      //   window.snackbar('info', 'You have skipped this verification');
      //   return null;
      // }

      if (form?.list?.[c]?.dataInWalletButNotInForm) {
        updateStore('currentWorkingStepperStep', c);
        updateStore('docData', {
          data: [{
            responseList: [{
              presentation: configs.credentials[c]
            }]
          }]
        });
        return navigate('/info');
      }
      // not skipped, exist in wallet/has insufficiency
      if (form?.list?.[c]?.error || (form?.list?.[c]?.existInWallet && !form?.list?.[c]?.markedAsSkip)) {
        const subData = await getDataOfCompletedForm(c, searchedQuery?.orgId, searchedQuery?.txnId);
        if (subData) {
          updateStore('isAutoplay', false);
          clearInterval(getFetchProcessInterval());
          triggerCancellationController();
          updateStore('currentWorkingStepperStep', c);
          const tempz = subData;

          updateStore('docData', tempz);
          if (!form?.list?.[c]?.error) {
            if (subData.data?.[0].state === 'NOT_INITIALIZED') {
              return navigate('/docs', { state: { onlyShow: true, state: 'NOT_INITIALIZED' } });
            } else {
              if (form?.list?.[c]?.data.acceptMultipleSubmissions && ![statesEnum.IN_PROGRESS,
                statesEnum.COMPLETED]?.includes(formInfo?.[c]?.state)) {
                return navigate('/docs', {
                  state: {
                    openThisFlow: true,
                    subTypes: tempz?.data?.reduce((acc, x) => {
                      acc[x.subType] = x?.request?.data?.documentList?.find(((x) => x.label === 'Marksheet'))?.url;
                      return acc;
                    }, {})
                  }
                });
              }
              if (vInfoCheck(subData.data?.[0])) {
                return navigate('/info', { state: { onlyShow: true } });
              } else {
                return navigate('/under-process', { state: { name: form?.list?.[c]?.name } });
              }
            }
          } else {
            if (vInfoCheck(subData.data?.[0])) {
              return navigate('/info', { state: { name: c } });
            } else {
              return navigate('/under-process', { state: { name: form?.list?.[c]?.name } });
            }
          }
        } else {
          return navigate('/under-process', { state: { name: form?.list?.[c]?.name } });
        }
      }
      // doesnot exist in wallet / marked as skip
      if (!form?.list?.[c]?.existInWallet || form?.list?.[c]?.markedAsSkip) {
        setSelecetedCred(c);
        // if (c === 'liveness') {
        //   // window.snackbar('info', 'Liveness check is done while adding a new credential to wallet.');
        // } else {
        updateStore('isAutoplay', false);
        clearInterval(getFetchProcessInterval());
        triggerCancellationController();
        const acc = {};
        const len = form.list?.[c]?.data?.authOptions?.filter((x) => x
            .type === typesOfForm.DIGILOCKER) ?? [];
        if (len?.length > 0) {
          acc[c] = len[0];
        }
        // if (form?.state === 'NOT_INITIALIZED') {
        //   getTransactionsProcess();
        // }
        const checkRes = await getTransactionsInfo(query.get('orgId'), txnId)
            .then((res) => {
              const form2 = res;
              console.log('form2', form2);
              if (form2.error && form2?.code !== 0) {
                setShowError(form2.msg);
                return null;
              } else {
                return form2;
              }
            });
        if (checkRes) {
          updateStore('currentWorkingStepperStep', c);
          console.log('acc: ', acc, Object.keys(acc)?.length > 0);
          setTimeout(() => {
            if (Object.keys(acc)?.length > 0) {
              updateStore('credsWithDigilocker', acc);
              updateStore('docData', {});
              navigate('/docs', { state: { openDigilocker: true } });
            } else {
              updateStore('docData', {});
              navigate('/docs');
            }
            setSelecetedCred(null);
          }, 500);
        }
        // }
      }
      else {
        window.snackbar('info', 'Details are being submitted, kindly wait a few minutes');
      }

      setSelecetedCred(null);
    }
  };

  return (
    <>
      {(form?.list && formUnderProcess?.[searchedQuery?.txnId]?.sortedList) ?
        form?.categories ? categoriesOrder
            ?.map((category, ci) => {
              return (form?.categories[category]?.length > 0) ? (<React.Fragment key={`${category}-${ci}`}>
                <Typography
                  color={getCategories(category).title === 'Urgent Action Required' ?
                  'error' : 'text'} variant="h6">
                  {getCategories(category).title}
                </Typography>
                {
                  form?.categories[category]
                      ?.map((c, i) => <ListItem
                        component={ListItemButton}
                        key={`credsRequested-${i}`}
                        onClick={async () => {
                          const showUserInput = isUserInputRequired(() => {
                            updateStore('isAutoplay', false);
                            setSelecetedCred(null);
                          });
                          if (showUserInput) {
                            return null;
                          }

                          if (form?.state === 'NOT_INITIALIZED' && form?.purposeRequired) {
                            setOpenStartingProcessDialog({
                              verification: c,
                              callback: async () => {
                                return handleVerificationClick(c);
                              }
                            });
                          } else {
                            handleVerificationClick(c);
                          }
                        }}
                        disableRipple={form?.list?.[c]?.existInWallet}
                        // disabled={!enabledDocs[c]}
                        sx={{
                          'flexGrow': 0,
                          'border': `1px solid ${!form?.list?.[c]?.error ?
                        ('#E7EDF6') :
                        (formInfo[c]?.state === statesEnum.FAILED ? '#E7EDF6' : theme.palette.error.main)}`,
                          'borderRadius': '8px',
                          'padding': '2em 0em',
                          'margin': '1.5em 0em',
                          'cursor': 'pointer',
                          '&:hover': {
                            // you want this to be the same as the backgroundColor above
                            backgroundColor: 'none'
                          },
                          'bgcolor': form?.list?.[c]?.closed ?
                        theme.palette[states[statesEnum.CLOSED].palette].light :
                        (!form?.list?.[c]?.error ? (!form?.list?.[c]?.markedAsSkip ?
                          (form?.list?.[c]?.done ? theme.palette.success.light :
                            ((form?.list?.[c]?.existInWallet || form?.list?.[c]?.dataInWalletButNotInForm) ?
                              theme.palette.secondary.light : '#ffffff')) : theme?.palette?.secondary?.veryLight) :
                          ((formInfo[c]?.state !== statesEnum.FAILED ? '#ffffff' : theme?.palette?.error?.light))),

                          'boxShadow': '0px 4px 6px 0px #0000001A',

                          ...(!enabledDocs[c] ? {
                            bgcolor: theme.palette.grey.light,
                            color: '#ffffff',
                            pointerEvents: 'none',
                            cursor: 'not-allowed',
                          } : (Object.values(enabledDocs)?.find((x) => x === true) ? {
                            border: `2px solid ${theme.palette.primary.main}`
                          } : {}))


                        }}
                        secondaryAction={
                          (!form?.list?.[c]?.error && !form?.list?.[c]?.closed) ?
                      (!form?.list?.[c]?.markedAsSkip ? (
                        form?.list?.[c]?.done ?
                          <Chip
                            color="success" variant="outlined"
                            sx={{
                              'border': 'none',
                              '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
                            }}
                            size="small"
                            icon={<img
                              src={greenCheckThorned}
                              style={{ width: '20px' }} />}
                            label="Verified" /> :
                          ((form?.list?.[c]?.existInWallet || form?.list?.[c]?.dataInWalletButNotInForm) ?
                            <Chip
                              color="primary"
                              variant="outlined"
                              sx={{
                                'border': 'none',
                                '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
                              }}
                              size="small"
                              icon={<CheckCircleOutline
                                style={{ width: '20px' }} />}
                              label="Submitted" /> :
                            (selecetedCred === c ?
                              <CircularProgress /> :
                              <ChevronRight
                                color="grey" />))
                      ) : <Chip
                        color="black"
                        variant="outlined"
                        sx={{
                          'border': 'none',
                          '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
                        }}
                        size="small"
                        icon={<SwitchAccessShortcut
                          style={{ width: '20px', transform: 'rotate(90deg) ' }} />}
                        label="Skipped" />) :
                      (<div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                        <Chip
                          color={states[formInfo[c]?.state].theme}
                          variant="outlined"
                          sx={{
                            'border': 'none',
                            '& .MuiChip-label': { fontSize: '12px', fontWeight: 'bold' }
                          }}
                          size="small"
                          icon={states[formInfo[c]?.state]?.icon}
                          label={states[formInfo[c]?.state]?.title} />
                        <ChevronRight
                          color="grey" />
                      </div>)}
                      >
                        <ListItemAvatar sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 0,
                          padding: 0,
                          minWidth: '40px'
                        }}>

                          <Avatar style={{
                            // border: '1px solid #E7EDF6',
                            backgroundColor: '#F6F6F6',
                            height: '30px',
                            width: '30px', margin: '0 1em'
                          }}>
                            <img
                              src={form?.list?.[c].icon} style={{
                                minHeight: '15px',
                                maxHeight: '25px', margin: '0', objectFit: 'contain'
                              }} alt="logo" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ padding: '0 0.4rem', maxWidth: '66%' }}
                          primary={form?.list?.[c].name}
                          //     secondary={(<span>
                          //       {/* {form?.list?.[c]?.dependency?.credential ? <>
                          //   <b>
                          //     Requires:&nbsp;
                          //     <span style={{ color: form?.list?.[c?.dependency?.credential]?.done ? 'green' : 'red' }}>
                          //       {getCredentialName(form?.list?.[c]?.dependency?.credential)}
                          //     </span>
                          //   </b>
                          //   <br />
                          // </> : null} */}

                          //       {form?.list?.[c].orgName}
                          //     </span>)}
                          primaryTypographyProps={{
                            variant: 'body2',
                            noWrap: true,
                            sx: {
                              margin: '0em',
                              // color: c?.dependency?.credential ?
                              //   (form?.list?.[c.dependency.credential]?.done ?
                              //     (form?.list?.[c]?.done ? '#000000' : '#000000') :
                              //     '#aaaaaa') :
                              //   (form?.list?.[c]?.done ? '#000000' : '#000000')
                            }
                          }}
                          secondaryTypographyProps={{
                            variant: 'body2',
                            noWrap: true,
                            sx: { margin: '0em', padding: '0em', maxWidth: '80%' }
                          }}
                        />
                      </ListItem>)
                }
              </React.Fragment>) : null;
            }) : null : <>
              {[0, 1]?.map((item, i) => <ListItem
                key={`verify-skeleton-${i}`}
                sx={{
                  // borderBottom: selectedCred[c.name] ? '2px solid green' : '1px solid #DADADA',
                  // borderRadius: '8px',
                  paddingLeft: '1em',
                  margin: '0px 0em',
                  cursor: 'pointer',
                }}
              >
                <ListItemAvatar sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 0
                }}>
                  <Skeleton variant="circular" width={60} height={60} />
                </ListItemAvatar>
                <ListItemText
                  sx={{ padding: '0 0.4rem', maxWidth: '100%' }}
                  primary={<Skeleton
                    variant="rectangular"
                    sx={{}}
                    width={'100%'}
                    height={20} />}
                  secondary={<Skeleton
                    variant="rectangular"
                    sx={{}}
                    width={'100%'}
                    height={20} />} />
              </ListItem>)}
            </>}


    </>
  );
}
