/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-danger */
import { Refresh } from '@mui/icons-material';
import { FormControl, Grid, IconButton, Skeleton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { getImageData } from '../../Helper/helpers';
import CustomTextField from '../UI/Input/customTextField';

export function CaptchaInput({ captcha, fetchCaptcha, value, onChange, onKeyDown }) {
  const inputRef = React.useRef();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [isFocused, setIsFocused] = React.useState();

  return (
    <Grid
      style={{
        width: '100%', padding: '1em',
        flexWrap: 'nowrap'
      }}
      container
      direction={'column'}
      justifyContent="space-between"
      alignItems="stretch">
      <Typography
        variant="body1" sx={{
          fontWeight: 500, margin: '1em 0'
        }}>
        Please enter the following text in the box below
      </Typography>
      <Grid item xs={12} sm={12} md={12} lg={matches ? 5 : 6}>
        {captcha ? (
          <FormControl
            sx={{
              width: 'fit-content',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {/* <div
              id="captcha"
              style={{margin: 'auto'}}
              dangerouslySetInnerHTML={{__html: captcha}}
            /> */}

            <img
              src={getImageData(captcha)}
              id="captcha"
              style={{
                margin: '0.6em',
                maxHeight: '50px'
              }}
            />
            <IconButton
              onClick={() => fetchCaptcha()}>
              <Refresh />

            </IconButton>
          </FormControl>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ marginRight: 'auto', width: 'fit-content' }}
            width={150}
            height={50} />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        sx={{ margin: { xs: '1em 0', sm: '1em 0', md: '1em 0', lg: '0' } }}>
        <CustomTextField
          sx={{
            width: '100%',
            //   border: inputRef?.current?.focus ? `2px solid ${env.theme.main}` : `1px solid ${env.theme.main}`,
            borderRadius: '4px',
            margin: '0',
            padding: 0

          }}
          inputProps={{
            style: {
              padding: '14px 10px'
            },
            id: 'captcha-input',
            onFocus: () => setIsFocused(true),
            onBlur: () => setIsFocused(false)
          }}
          label="Enter captcha "
          InputProps={{
          }}
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            onChange(e);
          }} />
      </Grid>

    </Grid>
  );
}
