import React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { INSTRUCTIONS } from './constants';
import styles from './styles';

function Instructions() {
  const theme = useTheme();
  const greaterThanMD = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box id={'wes-auth-instructions'} sx={{width: '100%', display: 'flex', flexFlow: 'column', alignItems: 'center', p: '7.5rem 0', backgroundColor: '#FAFAFA'}}>
      <Box sx={{width: {xs: '98.5%', sm: '85%', md: '65%', lmd: '50%', blg: '45%'}, display: 'flex', flexFlow: 'column', alignItems: 'center', textAlign: 'center'}}>
        <Typography className='wes-auth-caption' sx={{color: '#0A0912 !important'}}>
          Instructions
        </Typography>
        <Typography className='wes-auth-subtitle' sx={{color: '#0A0912 !important'}}>
          Steps to
          {' '}
          <Box component={'span'} sx={{color: '#1C43C3'}}>
          Receive your Digital Credentials
          </Box>
        </Typography>
      </Box>
      <Grid container spacing={'2.4'} sx={styles.gridContainer}>
        {
          INSTRUCTIONS.map((row, index) =>
            <Grid item xs={12} sm={6} md={row.gridSize}>
              <Box sx={styles.gridItemWrapper}>
                {
              row.gridSize === 6 && greaterThanMD ?
              <Box sx={{display: 'flex', alignItems: 'center', columnGap: '3.5rem'}}>
                <Box>
                  <Typography sx={styles.stepTxt}>
                    {`STEP 0${index + 1}`}
                  </Typography>
                  <Typography className='wes-auth-card-title' sx={{marginBottom: '1.5rem !important'}}>
                    {row.title}
                  </Typography>
                  <Typography className='wes-auth-caption' sx={styles.descTxt}>
                    {row.desc}
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box component={'img'} src={row.imgSrc} sx={row.imgStyle} />
                </Box>
              </Box> :
              <Box sx={{display: 'flex', height: '100%', flexFlow: 'column', justifyContent: 'space-between'}}>
                <Box>
                  <Typography sx={styles.stepTxt}>
                    {`STEP 0${index + 1}`}
                  </Typography>
                  <Typography className='wes-auth-card-title' sx={{marginBottom: '0rem !important'}}>
                    {row.title}
                  </Typography>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                  <Box component={'img'} src={row.imgSrc} sx={row.imgStyle} />
                </Box>
                <Typography className='wes-auth-caption' sx={styles.descTxt}>
                  {row.desc}
                </Typography>
              </Box>
                }
              </Box>
            </Grid>)
        }
      </Grid>
    </Box>
  );
}

export default Instructions;
