export const configsForMandatoryFormsToFill = {
  'formDisabled': true,
  'orgName': '',
  'orgImage': '',
  'list': {
    '0': {},
    'aadhaar': {
      'metadata': {
        'description': 'Unique Identity Number',
        'issuerName': 'UIDAI',
        'issuerLogo': 'https://upload.wikimedia.org/wikipedia/en/thumb/c/cf/Aadhaar_Logo.svg/1200px-Aadhaar_Logo.svg.png',
        'iconUrl': 'https://storage.googleapis.com/indisi-images-dev/50da02de5b7ed48dd388fe010839a3d4babca9fc3a7d4e94717a0118da936cfe',
        'label': 'Aadhaar',
        'consentMessage': 'I authenticate NSDC Trust to receive my AADHAAR information from UIDAI for authentication purposes and agree to NSDC International\'s privacy policy and Terms and conditions and receive communication from NSDC Trust via SMS, E-mail and Whatsapp.',
        'skipAllowed': false
      },
      'category': {
        'id': '656d61faced2eb5819b9a628',
        'name': 'Identity'
      },
      'bgvProviderList': [],
      'acceptMultipleSubmissions': false,
      '_id': '658abeebc25aa45ff1fbb4c7',
      'name': 'Aadhaar',
      'attributes': [
        {
          'name': 'Aadhaar ID',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Date of Birth',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Date of Birth Day',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Date of Birth Month',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Date of Birth Year',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Gender',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Name',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Care Of',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Country',
          'mime-type': 'text/plain'
        },
        {
          'name': 'District',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Locality',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Pin Code',
          'mime-type': 'text/plain'
        },
        {
          'name': 'State',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Vtc',
          'mime-type': 'text/plain'
        },
        {
          'name': 'House',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Street',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Landmark',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Post Office',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Sub-District',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Address',
          'mime-type': 'text/plain'
        },
        {
          'name': 'Photo',
          'mime-type': 'image/png'
        }
      ],
      'credentialBatchId': '6582f0f920bcac596a918775',
      'credentialDefinitionId': 'VAzwPLN9qQDgtKbQiAiHJs:3:CL:4809:Unique',
      'proofTemplateId': '651e70017f559350247dec27',
      'credits': 0,
      'ocr': false,
      'createdAt': '2023-12-14T11:26:50.205Z',
      'updatedAt': '2023-12-26T14:14:58.993Z',
      'modeList': [
        'automatic'
      ],
      'supportedFormList': [
        'ekyc'
      ],
      'reportConfigList': [],
      'existInWallet': false,
      'done': false,
      'key': 'aadhaar',
      'data': {
        'name': 'Aadhaar',
        'authOptions': [
          {
            'org': 'UIDAI',
            'type': 'standalone',
            'name': 'Enter Aadhaar Number',
            'nameLogo': 'https://upload.wikimedia.org/wikipedia/en/thumb/c/cf/Aadhaar_Logo.svg/1200px-Aadhaar_Logo.svg.png',
            'credits': '2',
            'page': {
              'count': 2,
              'config': [
                {
                  'inputs': [
                    {
                      'label': 'Enter Aadhaar Number to Continue',
                      'placeholder': '',
                      'minLength': 12,
                      'maxLength': 12,
                      'inputType': 'block',
                      'numberOfTextInEachBlock': 4,
                      'validation': {
                        'fn': '(e) => { return e?.length === 12; }',
                        'message': 'Aaadhar Number must be at least of 12 digits'
                      },
                      'fieldName': 'aadhaarNumber'
                    }
                  ],
                  'showSpecificForm': [
                    'noAadhaarContactUs'
                  ],
                  'submitButtonCheck': 'I authenticate NSDC Trust to receive my AADHAAR information from UIDAI for authentication purposes and agree to NSDC International\'s privacy policy and Terms and conditions and receive communication from NSDC Trust via SMS, E-mail and Whatsapp.',
                  'submitButtonText': 'Get OTP',
                  'api': {
                    'endpoint': `${process.env.REACT_APP_ISSUER_TRENTIAL_URL}/api/1.0/credential/ekyc/aadhaar`,
                    'method': 'POST',
                    'successCode': 200,
                    'body': '(fields, formResponses) => { return { ...fields, digiLocker:false, } }'
                  }
                },
                {
                  'inputs': [
                    {
                      'label': 'Enter OTP',
                      'description': '(e) => { return "Enter OTP sent to your mobile (Valid for 10 minutes)"; }',
                      'placeholder': '',
                      'minLength': 6,
                      'maxLength': 6,
                      'numberOfTextInEachBlock': 1,
                      'inputType': 'block',
                      'validation': {
                        'fn': '(e) => { return e?.length === 6; }',
                        'message': 'OTP must be at least of 6 digits'
                      },
                      'fieldName': 'ekycOtp'
                    }
                  ],
                  'bottomInfo': '<div>Wait a few minutes for the OTP<br><b>Do not close or refresh</b></div>',
                  'submitButtonCheck': 'I authenticate NSDC Trust to receive my AADHAAR information from UIDAI for authentication purposes and agree to NSDC International\'s privacy policy and Terms and conditions and receive communication from NSDC Trust via SMS, E-mail and Whatsapp.',
                  'submitButtonText': 'Submit',
                  'api': {
                    'endpoint': `${process.env.REACT_APP_BASE_URL}/api/1.0/profile/verification-detail`,
                    'method': 'PUT',
                    'successCode': 200,
                    'body': '(fields, formResponses) => { return { ...fields, digiLocker:false, "verificationName": "aadhaar", refId:formResponses[0].response.ref_id } }'
                  }
                }
              ]
            }
          },
          {
            'org': 'Digilocker',
            'type': 'digilocker',
            'credits': '1',
            'doctype': 'ADHAR',
            'api': {
              'endpoint': `${process.env.REACT_APP_BASE_URL}/api/1.0/profile/verification-detail`,
              'method': 'PUT',
              'successCode': 200,
              'body': '(fields, formResponses) => { return { ...fields, digiLocker:true,"verificationName": "aadhaar" } }'
            }
          }
        ],
        'submitToVc': '(formResponses) => { return { ...formResponses } }',
        'getAttributes': '(formResponses) => { return formResponses[1].response.attributes }'
      },
      'icon': 'https://storage.googleapis.com/indisi-images-dev/50da02de5b7ed48dd388fe010839a3d4babca9fc3a7d4e94717a0118da936cfe',
      'description': 'Unique Identity Number',
      'orgName': 'UIDAI',
      'logo': 'https://upload.wikimedia.org/wikipedia/en/thumb/c/cf/Aadhaar_Logo.svg/1200px-Aadhaar_Logo.svg.png',
      'error': false
    },
  },
  'remaining': [
    'aadhaar'
  ],
  'shared': [{
    'response': {
      'presentation': [],
      'onGrid': []
    },
    'request': {
      'onGrid': []
    },
    'name': 'aadhaar',
    'mode': 'automatic',
    'skipAllowed': false,
    'markedAsSkip': false,
    'consentMessage': 'I authenticate NSDC Trust to receive my AADHAAR information from UIDAI for authentication purposes and agree to NSDC International\'s privacy policy and Terms and conditions and receive communication from NSDC Trust via SMS, E-mail and Whatsapp.',
    'note': 'Candidate added',
    'state': 'NOT_INITIALIZED',
    'stateList': [
      {
        'state': 'NOT_INITIALIZED',
        'createdOn': 1707722702356,
        'note': 'Candidate added'
      }
    ]
  }],
  'exists': [],
  'state': 'IN_PROGRESS',
  'allTriggered': false,
  'categories': {
    'Identity': [
      'aadhaar'
    ],
  },
  'verificationListAsObj': {
    'aadhaar': {
      'response': {
        'presentation': [],
        'onGrid': []
      },
      'request': {
        'onGrid': []
      },
      'name': 'aadhaar',
      'mode': 'automatic',
      'skipAllowed': false,
      'markedAsSkip': false,
      'consentMessage': 'I authenticate NSDC Trust to receive my AADHAAR information from UIDAI for authentication purposes and agree to NSDC International\'s privacy policy and Terms and conditions and receive communication from NSDC Trust via SMS, E-mail and Whatsapp.',
      'note': 'Candidate added',
      'state': 'NOT_INITIALIZED',
      'stateList': [
        {
          'state': 'NOT_INITIALIZED',
          'createdOn': 1707722702356,
          'note': 'Candidate added'
        }
      ]
    }
  },
  'sortedList': [
    'aadhaar'
  ]
};
