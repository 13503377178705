/* eslint-disable react/display-name */
import TextField from '@mui/material/TextField';
import React, { forwardRef } from 'react';

// Higher-order component to add custom onChange functionality
const withCustomOnChange = (Component) => {
  return forwardRef(({ onChange, ...props }, ref) => {
    const handleChange = (event) => {
      // Modify event.target.value as needed
      event.target.value = event.target.value.trimStart();
      if (event.target.value !== ' ' && onChange) {
        onChange(event);
      }
    };

    return <Component {...props} ref={ref} onChange={handleChange} />;
  });
};

const CustomTextField = withCustomOnChange(TextField);

export default CustomTextField;
