import { axiosBaseInstanceWithSentryOnly } from '../Config/axios';
import { routes } from '../Config/routes';
import { login, logout } from '../Config/store';

export const getAccessToken = () => {
  // console.log('fetchFromStore(authRes)', fetchFromStore('authRes'))
  // return fetchFromStore('authRes').accessToken;
  console.log('store: token', localStorage?.getItem('token'));
  return localStorage?.getItem('token') || false;
};

export const decodeAccessToken = (token) => {
  return new Promise((resolve, reject) => {
    resolve({});
  });
};

export const decodeAccessTokenAndCheckIfItIsAllowed = async (token) => {
  const decoded = await decodeAccessToken(token);
  console.log('decoded access token1', decoded);

  if (process.env.REACT_APP_ORG_ID_WHITELIST?.split(',').includes(decoded.clientId)) {
    console.log('true');
    return true;
  } else {
    console.log('false');
    throw new Error('This organization is not authorized to access this portal');
  }
};


export const generateLoginTokenFromRefreshToken = async (refreshToken) => {
  try {
    const response = await axiosBaseInstanceWithSentryOnly.put(`${routes.refreshToken}`, {}, {
      headers: {
        'Authorization': `Bearer ${refreshToken}`,
      }
    });
    login(response.data.data);
    return response;
  } catch (e) {
    console.log('Error==>', e);
    if (e.code === 'ECONNABORTED') {
      return false;
    }
    // window?.dispatch(logout())
    return 'LOGOUT';
  }
};

window.intervalId = null;
export const refreshAccessTokenAtRegularInterval = async () => {
  try {
    console.log('refresh hit');

    const refreshTokenExpiresIn = localStorage?.getItem('refreshTokenExpiresIn') ?? 3600;
    window.intervalId = setInterval(async () => {
      const refreshToken = localStorage?.getItem('refreshToken');
      if (refreshToken) {
        await generateLoginTokenFromRefreshToken(refreshToken);
      }
    }, (Number(refreshTokenExpiresIn) - 60) * 1000);
  } catch (e) {
    console.error(e);
  }
};

export const startRefreshAccessTokenAtRegularInterval = async (rToken) => {
  try {
    const refreshToken = rToken || localStorage?.getItem('refreshToken');
    if (refreshToken) {
      const res = await generateLoginTokenFromRefreshToken(refreshToken);
      if (res.status === 200) {
        // await decodeAccessTokenAndCheckIfItIsAllowed(res.data.access_token)
        refreshAccessTokenAtRegularInterval();
        return true;
      } else if (res === 'LOGOUT') {
        logout();
        return false;
        // window.open('/user/login', '_self');
      }
      return true;
    } else {
      logout();
      return false;
    }
  } catch (e) {
    console.log('error in startRefreshAccessTokenAtRegularInterval', e);
    if (e?.response?.data?.error_description || e?.message) {
      window.snackbar('error', e?.response?.data?.error_description ?? e?.message);
    }
    // window.dispatch(logout())
    return false;
  }
};

export const getQuickStore = () => {
  try {
    const user = sessionStorage.getItem('store');
    if (user !== null) {
      // logout();
      return {};
    }
    const data = JSON.parse(user);
    return data;
  }
  catch (e) {
    return {};
  }
};
