import { Clear, OpenInNew } from '@mui/icons-material';
import { IconButton, InputAdornment, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import CustomTextField from '../UI/Input/customTextField';
import './styles.css';

export default function FileInput({ id = '', input, onChange = () => { }, progress, error, data, defaultHelperText, sx = {},
  disabled = false }) {
  const fileInputRef = useRef();
  const theme = useTheme();
  const [width, setWidth] = React.useState(0);
  const [images, setImages] = React.useState([]);
  const [height, setHeight] = React.useState(0);
  const [pdfRendering, setPdfRendering] = React.useState('');
  const [pageRendering, setPageRendering] = React.useState('');
  const acceptedFileTypes = input?.fileTypeAccept ? input?.fileTypeAccept : 'application/pdf, image/png, image/jpeg, image/jpg';
  console.log('defaultHelperText', defaultHelperText, error, disabled);
  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        id="file-to-upload"
        accept={acceptedFileTypes}
        hidden
        datacy={`file-to-upload-${input.fieldName}`}
        onChange={(e) => {
          if (e?.target?.files?.[0]?.size > +process.env.REACT_APP_MAX_FILE_SIZE) {
            return window.snackbar('warning', `File size can't be more than 20MB`);
          }
          if (e?.target?.files[0]?.type) {
            if (acceptedFileTypes.includes(e.target.files[0]?.type)) {
              onChange(input.fieldName, e.target.files[0]);
            } else {
              window.snackbar('warning', `Please upload a file of type: ${acceptedFileTypes.split(',')?.map((x) => x.split('/')[1])
                  .join(', ')}`);
            }
          } else {
            e?.stopPropagation();
            onChange(input.fieldName, null);
            fileInputRef.current.value = null;
          }
        }}
      />
      <CustomTextField
        id={id}
        fullWidth
        disabled={disabled}
        required={input.required}
        type={'text'}
        value={data?.[input?.fieldName]?.name ?? ''}
        placeholder={disabled ? 'Disabled' : 'Click to upload (Max 20MB)'}
        // disabled
        inputProps={{
          style: {
            paddingRight: '16px',
            color: theme.palette.text.primary,
            cursor: 'pointer',
            caretColor: 'transparent'
          }
        }}
        label={`${input.label}`}
        sx={{
          'cursor': 'pointer',
          '& .MuiInputBase-root': {
            paddingRight: '0px',
          },
          ...sx
        }}
        InputProps={{
          endAdornment:
            <InputAdornment
              position="end" sx={{
                padding: 0,
                margin: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {data?.[input?.fieldName]?.name ?
                <IconButton
                  onClick={(e) => {
                    if (!disabled) {
                      e.stopPropagation();
                      onChange(input.fieldName, null);
                      fileInputRef.current.value = null;
                    }
                  }}>
                  <Clear />
                </IconButton> : null}
              <button
                style={{
                  width: '87px',
                  height: '54px',
                  backgroundColor: error?.[input.fieldName]?.message ?
                    theme.palette.error.main :
                    (disabled ? theme.palette.grey.main : theme.palette.primary.main),
                  color: '#ffffff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  outline: 'none',
                  zIndex: 2
                }}
                disabled={disabled}
              // onClick={() => fileInputRef?.current?.click()}
              >
                Upload
              </button>
            </InputAdornment>

        }}
        onClick={() => {
          if (!disabled) { fileInputRef?.current?.click(); }
        }}
        InputLabelProps={{ shrink: true }}
        helperText={(defaultHelperText && !error?.[input.fieldName]?.message) ?
          ((typeof defaultHelperText === 'string' && defaultHelperText?.includes('http')) ?
            <a
              onClick={(e) => {
                e.stopPropagation();
                window.open(defaultHelperText, '_blank');
              }}
              style={{ overflowWrap: 'anywhere' }}>
              Click here to see the uploaded file
              <OpenInNew sx={{ fontSize: '1.2rem', marginLeft: '0.4em' }} fontSize="small" />
            </a> :
            defaultHelperText) : (error?.[input.fieldName]?.message || '')}
        FormHelperTextProps={{
          sx: {
            marginTop: '-10px'
          }
        }}
        error={Boolean(error?.[input.fieldName]?.message || false) || false}
      />
    </>

  );
}
