/* eslint-disable guard-for-in */
/* eslint-disable no-useless-escape */
import { Buffer } from 'buffer';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { statesEnum, typesOfForm } from '../Utils/enums';


export const getClippedLengthTitle = (name, limit = 30) => name?.length > limit ? `${name?.substring(0, limit)}...` : name;

export const underscoreCaseToWords = (str) => {
  return str
      .split('_')
      ?.map((word) => word.replace(word.charAt(0), word.charAt(0).toUpperCase()))
      .join(' ');
};

// const camelCaseToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
// const camelCaseToWords = str => underscoreCaseToWords(camelCaseToSnakeCase(str))

export const stringToCamelCase = (str) => {
  return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const camelCaseToString = (camelCasedStr) => {
  if (!camelCasedStr) {
    return camelCasedStr;
  }
  const temp = camelCasedStr.replace(/([A-Z])/g, ' $1');
  return temp.charAt(0).toUpperCase() + temp.slice(1);
};

export const convertEventValueToUpperCase = (event) => {
  const start = event.target.selectionStart;
  const end = event.target.selectionEnd;
  event.target.value = event.target.value.toLocaleUpperCase();
  event.target.setSelectionRange(start, end);

  return event;
};

export const comparator = (property, orderBy = 1, setSortOrder) => {
  setSortOrder(-1 * orderBy);
  return function(a, b) {
    if (a[property] > b[property]) return orderBy;
    else if (a[property] < b[property]) return -1 * orderBy;
    else return 0;
  };
};

export const convertToNestedForm = (candidate) => {
  const formattedCandidate = {};

  Object.keys(candidate).forEach((key) => {
    if (key === 'email' || key === 'mobile' || key === 'name' || key === '_id' || key === 'updatedAt' || key === 'createdAt') {
      formattedCandidate[key] = candidate[key];
    } else {
      if (formattedCandidate['profile']) {
        formattedCandidate['profile'][key] = candidate[key];
      } else {
        formattedCandidate['profile'] = {};
        formattedCandidate['profile'][key] = candidate[key];
      }
    }
  });
  return formattedCandidate;
};

export const convertToNonNestedForm = (data) => data?.map((candidate) => {
  const formattedCandidate = Object.assign({}, candidate);
  if (candidate.profile) {
    Object.keys(candidate.profile).forEach((key) => {
      formattedCandidate[key] = candidate.profile[key];
    });
    delete formattedCandidate['profile'];
  }
  delete formattedCandidate['PRIVATE_INVALID'];
  return formattedCandidate;
});

export const checkIfObjectIsNotEmpty = (obj) => {
  let check = false;
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      check = true;
    }
  });
  return check;
};

export const changeToSentenceCase = (text) => {
  if (text.includes('ifsc')) return 'IFSC Code';
  if (text.includes('dob')) return 'Date of Birth';
  // in case of Id, eg: userId
  if (text.charAt(text?.length - 1) === 'd' && text.charAt(text?.length - 2) === 'I') {
    const strToModify = text.split('Id')[0];
    const result = strToModify.replace(/([A-Z])/g, ' $1');
    return `${result.charAt(0).toUpperCase() + result.slice(1)} ID`;
  }
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const deleteFromErrors = (errors, setErrors, fieldToDelete, isArray) => {
  const tempErrors = { ...errors };
  if (isArray) fieldToDelete.forEach((field) => delete tempErrors[field]);
  else delete tempErrors[fieldToDelete];
  setErrors(tempErrors);
};

export const downloadFile = (value, fileName) => {
  const a = document.createElement('a');
  a.href = value;
  a.download = fileName;
  a.click();
};

export const downloadJsonFile = (exportObj, exportName) => {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(exportObj)}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${exportName}.json`);
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const b64toURL = async (b64Data) => {
  const blob = await (await fetch(b64Data)).blob();
  return URL.createObjectURL(blob);
};

export const isValidUrl = (urlString) => {
  const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const isValidUrlLite = (urlString) => {
  return urlString.includes('http://') || urlString.includes('https://');
};

export function parseStringTemplate(str, obj) {
  const parts = str.split(/\{\{(?!\d)[\wæøåÆØÅ]*\}}/);
  const args = str.match(/[^{\}]+(?=})/g) || [];
  const parameters = args?.map((argument) => obj[argument] || (obj[argument] === undefined ? '' : obj[argument]));
  return String.raw({ raw: parts }, ...parameters);
}

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};


export const getTrimmedText = (title, length) => {
  if (title?.length >= length) return `${title.slice(0, length)}...`;
  return title;
};


export const downloadQR = (name) => {
  const canvas = document.querySelector('canvas');
  const link = document.createElement('a');
  link.href = canvas.toDataURL();
  link.download = `${name}-QR.png`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function delay(time = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}


export const scrollIntoViewHelper = (errors) => {
  const firstError = errors[0];

  const el = document.getElementById(`${firstError}`);

  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};

export const doArraysShareElements = (outerArr, innerArr) => {
  const typeArr = [...outerArr, ...innerArr];
  const typeSet = new Set(typeArr);
  return typeArr?.length > typeSet.size;
};


// this function checks whether the image has base64 tag in prefix or not, if not then append it
export const getImageData = (data) => {
  if (!data)
  // Sample Image
  {
    return '';
  }
  if (data?.includes('base64')) {
    return data;
  }
  if (data?.includes('https://')) {
    return data;
  }
  return `data:image/png;base64,${data}`;
};

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
export function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      blob.name = fileName;
      resolve(blob);
    }, 'image/jpeg', 1);
  });
}


export const base64ToBinary = async (url) => {
  return fetch(url)
      .then((res) => res.blob());
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function jsonToBase64(object) {
  const json = JSON.stringify(object);
  return Buffer.from(json).toString('base64');
}

export function base64ToJson(base64String) {
  const json = Buffer.from(base64String, 'base64').toString();
  return JSON.parse(json);
}

export function removeQueryFromURL() {
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  window.history.pushState({ path: newurl }, '', newurl);
}

export function getCalc(a = '100vh', b, operator = '-') {
  return `calc(${a} ${operator} ${b})`;
}


export function hasDigilocker(a) {
  return a?.data?.authOptions?.
      filter((x) => x?.type === typesOfForm.DIGILOCKER);
}

export function triggerCancellationController(a) {
  try {
    // const controller = fetchFromStore('cancellationController');
    // if (controller && controller.abort) {
    //   controller.abort();
    // };
  } catch (e) {
    console.log(e);
  }
}

export const loadPdfScripts = (callback) => {
  const scripts = [{
    id: 'pdf-js-1',
    link: 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.3.200/pdf.js'
  }, {
    id: 'pdf-js-2',
    link: 'https://cdnjs.com/libraries/pdf.js'
  }, {
    id: 'dwolla-js',
    link: 'https://cdn.dwolla.com/1/dwolla.js'
  }];

  scripts.forEach((s) => {
    const existingScript = document.getElementById(s.id);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = s.link;
      script.id = s.id;
      document.body.appendChild(script);
    }
  });
};

export async function encodeImageFileAsURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function() {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
}


export const appendLoggedIn = (url) => {
  if (url.includes('&loggedIn=true') || url.includes('?loggedIn=true')) {
    return url;
  } else if (url.includes('?')) {
    return `${url}&loggedIn=true`;
  } else {
    return `${url}?loggedIn=true`;
  }
};
export const removeLoggedIn = (url) => {
  if (url.includes('&loggedIn=true')) {
    return url.replace('&loggedIn=true', '');
  } else if (url.includes('?loggedIn=true')) {
    return url.replace('loggedIn=true', '');
  } else {
    return url;
  }
};

export const checkIfExists = (obj, template) => {
  return Object.keys(template).every((key) => {
    if (obj[key]) {
      if (typeof obj[key] === 'string') {
        return obj[key]?.length > 0;
      } else if (typeof obj[key] === 'object') {
        if (Array.isArray(obj[key])) {
          return obj[key]?.length > 0;
        } else {
          return checkIfExists(obj[key], template[key]);
        }
      }
    } else {
      return false;
    };
  });
};

export const hasJsonStructure = (str) => {
  if (typeof str !== 'string') return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]' ||
      type === '[object Array]';
  } catch (err) {
    return false;
  }
};

export const convertToTitleCase = (text) => {
  // returns false if text includes whitespace, _, or -.
  const isCamelCase = !text.match(/[\s_-]/g);
  let titleCase = '';
  if (isCamelCase) {
    const result = text.replace(/([A-Z])/g, ' $1');
    titleCase = result.charAt(0).toUpperCase() + result.slice(1);
  } else {
    titleCase = text.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()).replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
  }
  return titleCase;
};


export const createConfig = (verifierName, verifierImageUrl, inputs) => {
  return ({
    'metadata': {
      'description': 'Basic Details',
      'issuerName': verifierName,
      'issuerLogo': verifierImageUrl,
      'iconUrl': verifierImageUrl,
      'label': 'Basic Details'
    },
    'category': {
      'id': '656d61faced2eb5819b9a628',
      'name': ''
    },
    '_id': '659fc40aa63a770b96245319',
    'name': 'user-input',
    'attributes': [],
    'credentialBatchId': '6582e36c20bacc596a918428',
    'credentialDefinitionId': 'VAzwPLN9qQDgtKbQiAiHJs:3:CL:6339:user-input',
    'proofTemplateId': '6582e36c20bacc596a918428',
    'credits': 200,
    'ocr': false,
    'createdAt': '2023-12-20T14:31:23.983Z',
    'updatedAt': '2023-12-26T22:45:57.765Z',
    'modeList': [
      'manual'
    ],
    'supportedFormList': [
      'bgv'
    ],
    'additionalInfo': {
      'name': 'Basic Details',
      'authOptions': [
        {
          'org': verifierName,
          'type': 'standalone',
          'name': 'Enter details',
          'credits': '1',
          'disableBuildCredential': true,
          'page': {
            'count': 1,
            'config': [
              {
                'inputs': inputs,
                'submitButtonText': 'Add Details',
                'api': {
                  'endpoint': `${process.env.REACT_APP_EKYC_URL}/api/1.0/transactions/process`,
                  'method': 'POST',
                  'successCode': 200,
                  'body': '(fields, formResponses) => {return { "userInputList": fields.userInputList, verificationFields:fields.verificationFields }}'
                }
              },
              {
                'inputs': [
                  {
                    'label': 'Enter OTP',
                    'description': '(e) => { return "Enter OTP sent to your "+e?.[0]?.response?.userInputIdentificationValidation?.otpSentTo?.reduce((acc,d)=>{ acc.push(d?.type?.toLowerCase() +" at "+d?.to); return acc; },[])?.join(" and ")+" (Valid for 10 minutes)"; }',
                    'placeholder': '',
                    'minLength': 6,
                    'maxLength': 6,
                    'numberOfTextInEachBlock': 1,
                    'inputType': 'block',
                    'validation': {
                      'fn': '(e) => { return e?.length === 6; }',
                      'message': 'OTP must be at least of 6 digits'
                    },
                    'fieldName': 'otpValue',
                    'extraComponent': ['resend-otp'],
                    'required': true,
                    'alwaysEnabled': true
                  }
                ],
                'bottomInfo': '<div>Wait a few minutes for the OTP<br><b>Do not close or refresh</b></div>',
                'submitButtonCheck': '',
                'submitButtonText': 'Submit',
                'api': {
                  'endpoint': `${process.env.REACT_APP_EKYC_URL}/api/1.0/transactions/verify/identification`,
                  'method': 'POST',
                  'successCode': 200,
                  'body': '(fields, formResponses) => { const q= fields?.reduce((acc,q)=>{acc[q.label]=q.value; return acc;},{}); return {...q,  otpTxnId:formResponses[0]?.response?.userInputIdentificationValidation?.otpTxnId } }'
                }
              }
            ]
          }
        }
      ],
      'submitToVc': '(formResponses) => { return formResponses }',
      'getAttributes': '(formResponses) => { return null }'
    },
    'bgvProviderList': [
      ''
    ],
    'reportConfigList': []
  });
};

export const checkIfJson = (data) => {
  try {
    console.log('json:', data, typeof data === 'object');
    const json = JSON.parse(data);
    console.log('json', json, typeof json[0] === 'object');
    return {
      isObject: true,
      data: json
    };
  } catch (e) {
    console.log(e);
    return {
      isObject: false,
      data: data
    };
  }
};

export const nameValueArrayToJson = (ar) => {
  return ar?.reduce((acc, atr, i) => {
    acc[atr.name] = atr.value;
    return acc;
  }, {});
};

export const templateOfResponseExists =
{
  'name': 'aadhaar',
  'state': 'COMPLETED',
  'note': 'Verification Completed',
  'request': {
    'data': {}
  },
  'responseList': [
    {
      'presentation': [],
      'proofRequestId': 'QQ'
    }
  ],
  'mode': 'automatic'
};

export const vInfoCheck = (data) => {
  // for insuff
  let isInsufficiencyPresent = false;
  const insuffList = data?.responseList?.filter((i) => i?.insufficiencyData);
  if (insuffList?.length > 0) {
    isInsufficiencyPresent = true;
  }

  // for BGV verification = submitted data & insuff resolved
  const isInsuffResolved = data?.request?.data && Object.keys(data?.request?.data)?.length > 0;

  // for automatic verification = presentation
  let isPresentationPresent = false;
  const presentationList = data?.responseList?.filter((i) => i?.presentation);
  if (presentationList?.length > 0) {
    isPresentationPresent = true;
  }

  // for BGV verification = submitted data
  const isSubmittedDataPresent = data?.request?.insufficiencyDataList && data?.request?.insufficiencyDataList?.length > 0;

  if (isInsufficiencyPresent || isInsuffResolved || isPresentationPresent || isSubmittedDataPresent) {
    return true;
  }

  return false;

  // return (data.request?.data && Object.keys(data.request?.data).length > 0) ||
  //   (data.responseList?.length > 0 && (data.responseList[0]?.presentation?.length > 0)) ||
  //   (data.responseList?.length > 0 && (data.responseList[0]?.insufficiencyData?.length > 0)) ||
  //   data?.request?.insufficiencyDataList?.length > 0;
};

export const shouldAllowModification = (key, stateInProfile, stateInForm, verificationState) => {
  return key && ![stateInForm, verificationState].includes(statesEnum.CLOSED) && (
    (stateInProfile) ?
      // state in profile should be NOT_INITIALIZED or DATA_SAVED
      ([statesEnum.DATA_SAVED, statesEnum.NOT_INITIALIZED].includes(stateInProfile) &&
        // if exists in form ? state in form should be NOT_INITIALIZED or DATA_SAVED otherwise true
        (stateInForm ? [statesEnum.DATA_SAVED, statesEnum.NOT_INITIALIZED].includes(stateInForm) :
          true)
      ) :
      // if its not a degree with marksheet:: state in form should be NOT_INITIALIZED or DATA_SAVED
      [statesEnum.DATA_SAVED]?.includes(verificationState));
};

export const getFetchProcessInterval = () => sessionStorage.getItem('fetchProcessInterval');

export const updateDocumentTitle = (params = []) => {
  const title = params.reduce((acc, param) => {
    param = param?.trim();
    if (param) {
      acc += ` ${acc?.length > 0 ? `- ${param}` : param}`;
    }
    return acc;
  }, '');
  document.title = title?.length > 1 ? `${title} - ${window?._env_?.title}` : window?._env_?.title;
};

export const callTheCallbackIfNotNull = (item, callback) => {
  if (item) {
    return callback();
  }
  return null;
};

export const isLiteralObject = (a) => {
  return !!a && a.constructor === Object;
};

export function trimFields(data) {
  if (typeof data === 'string') {
    return data.trim();
  } else if (Array.isArray(data)) {
    return data?.map(trimFields);
  } else if (data !== null && isLiteralObject(data)) {
    const trimmedObject = {};
    for (const key in data) {
      trimmedObject[key] = data[key] ? trimFields(data[key]) : data[key];
    }
    return trimmedObject;
  } else {
    return data;
  }
}
