import { FormHelperText, Grid, OutlinedInput } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

export default function BlockTextfield({id = '', input, updateField, error}) {
  const refs = useRef([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    if (input.maxLength && input.numberOfTextInEachBlock) {
      const numberOfBlocks = Number(input.maxLength / input.numberOfTextInEachBlock);
      const ar = Array(numberOfBlocks).fill('');
      setFields(ar);
    }
  }, []);


  const modifyCurrentFields = (text, index) => {
    setFields((x) => {
      const temp = [...x];
      temp[index] = text;
      updateField(temp.join(''));
      return temp;
    });
  };

  const first = (t, i) => {
    if (t?.length > input.numberOfTextInEachBlock) {
      modifyCurrentFields(t.charAt(t?.length - 1), i + 1);
      refs.current[i + 1].focus();
    } else {
      modifyCurrentFields(t, i);
      if (t?.length === input.numberOfTextInEachBlock) {
        refs.current[i + 1].focus();
      }
    }
  };
  const middle = (t, i) => {
    if (t?.length > input.numberOfTextInEachBlock) {
      modifyCurrentFields(t.charAt(t?.length - 1), i + 1);
      refs.current[i + 1].focus();
    } else if (t?.length === 0) {
      modifyCurrentFields(t, i);
      refs.current[i - 1].focus();
    } else {
      modifyCurrentFields(t, i);
      if (t?.length === input.numberOfTextInEachBlock) {
        refs.current[i + 1].focus();
      }
    }
  };
  const last = (t, i) => {
    if (t?.length <= input.numberOfTextInEachBlock) {
      modifyCurrentFields(t, i);
    }
    if (t?.length === 0) {
      refs.current[i - 1].focus();
    }
  };

  console.log('input.fieldName', input.fieldName);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      id={id}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        columnSpacing={{xs: 1, sm: 2, md: 3}}
        sx={{width: '100%', marginBottom: '0px'}}>
        {fields?.map((fs, i) => <Grid
          key={`${input.fieldName}-${i}`}
          item xs={12 / Number(input.maxLength / input.numberOfTextInEachBlock)}
          sm={12 / Number(input.maxLength / input.numberOfTextInEachBlock)}
          md={12 / Number(input.maxLength / input.numberOfTextInEachBlock)}
          lg={12 / Number(input.maxLength / input.numberOfTextInEachBlock)}>
          <OutlinedInput
            autoFocus={i === 0}
            sx={{padding: 0}}
            value={fs}
            type={'text'}
            id={`${input.fieldName}-${i}`}
            inputProps={{
              style: {
                padding: '8px 10px', fontSize: '1.8rem',
                letterSpacing: fs ? '4px' : '10px',
                textAlign: 'center'
              },
              ref: (el) => refs.current[i] = el,
              inputmode: 'numeric'
            }}
            onChange={(e) => {
              const t = e.target.value;
              // t = t + getDots(e.target.value)
              if (!isNaN(t)) {
                if (i === 0) {
                  first(t, i);
                } else if (i === (input.maxLength / input.numberOfTextInEachBlock) - 1) {
                  last(t, i);
                } else {
                  middle(t, i);
                }
              }
            }}
            onKeyDown={(e) => {
              const t = event.keyCode;
              if (t === 8 && fs?.length <= 0) {
                if (i === 0) {

                } else if (i === (input.maxLength / input.numberOfTextInEachBlock) - 1) {
                  refs.current[i - 1].focus();
                } else {
                  refs.current[i - 1].focus();
                }
              }
            }}
          />
        </Grid>)}

      </Grid>
      {error?.[input.fieldName]?.message ?
            <FormHelperText
              error={error?.[input.fieldName]?.message}
              sx={{textAlign: 'left', marginTop: 0}}>
              {error?.[input.fieldName]?.message || ''}
            </FormHelperText> : null}
    </Grid>
  );
}
