import { Button, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import underProcess from '../../Assets/underProcess.svg';
import SimpleBackground from '../../Components/Background/Simple';

export default function UnderProcess() {
  const { state } = useLocation();
  const theme = useTheme();
  return (
    <SimpleBackground
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
        height: '100%',
        width: '100%',
        padding: '0px',
        margin: 0
      }}
      title={state?.name ?? ''}>

      <Grid
        container
        direction="column"
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ flexWrap: 'nowrap', padding: '2em' }}>

        <img src={underProcess} style={{ width: '80%' }} />

        <Typography
          variant="h3" sx={{
            margin: '1em',
            fontWeight: 600,
            color: '#000000',
          }}>
                    Under Process
        </Typography>

        <Typography
          variant="body1" sx={{
            color: theme?.palette?.grey?.main,
            margin: 0,
            textAlign: 'center'
          }}>
                    Your
          {' '}
          {state?.name}
          {' '}
                    verification is under process. Please check back later.
        </Typography>

        <Button
          variant="text" onClick={() => navigate(-1)}
          sx={{
            margin: '1em',
          }}>
                    Go back
        </Button>
      </Grid>
    </SimpleBackground>
  );
}
