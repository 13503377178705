const routes = {
  liveness: {
    sessionToken: '/images/sessionToken',
    sessionId: '/images/liveness',
  },
  compare: {
    image: '/images/compare',
  },
  failed: {
    report: '/failed',
  },
};

export default routes;
