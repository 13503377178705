import { ArrowBack } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useIsDesktop } from '../../Helper/hooks';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ title, fullScreen = true, open, setOpen, sx, children, color = 'black' }) {
  const theme = useTheme();
  const matches = useIsDesktop();
  const isFullScreen = fullScreen === true ? true : (matches ? false : true);
  const handleClose = () => {
    setOpen(false);
  };
  console.log('isFullScreen', isFullScreen, matches);
  return (<Dialog
    fullScreen={isFullScreen}
    maxWidth={fullScreen === true ? 'xl' : (matches ? 'sm' : 'xl')}
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    sx={sx}
  >
    {isFullScreen ? <AppBar sx={{ position: 'relative' }} color={color}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <ArrowBack />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1, color: '#ffffff' }} variant="h6" component="div">
          {title}
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar> : <DialogTitle>
      {title}
    </DialogTitle>}
    {children}
  </Dialog>
  );
}
