import { updateWallet } from '../../Config/api';


const fieldNames = (obj) => ({
  'name': obj['Name'],
  // 'employeeId': obj[info.employeeId,
  'city': obj['District'],
  'gender': obj['Gender'],
  // 'dob11': obj['Date of Birth'],
  // 'dob2': displayTime(obj['Date of Birth'], 'YYYY-MM-DD'),
  // 'dob': `${obj['Date of Birth Year']}-${obj['Date of Birth Month'] < 10 ? `${0}${obj['Date of Birth Month']}` : obj['Date of Birth Month']}-${obj['Date of Birth Day'] < 10 ? `${0}${obj['Date of Birth Day']}` : obj['Date of Birth Day']}`,
  'dob': `${obj['Date Of Birth']?.split('-')?.[2] }-${ obj['Date Of Birth']?.split('-')?.[1] }-${ obj['Date Of Birth']?.split('-')?.[0]}`,
  'fatherName': '-',
  'permanentAddress': {
    'line2': '',
    'line1': `${obj['House'] ? `${obj['House']}, ` : ''}${obj['Street'] ? `${obj['Street']}, ` : ''}${obj['Locality'] ? `${obj['Locality']}` : ''}`,
    'locality': obj['Locality'],
    'landmark': obj['Landmark'],
    'vtc': obj['Vtc'],
    'district': obj['District'],
    'state': obj['State'],
    'pincode': obj['Pin Code'],
    'country': obj['India']
  },
  'currentAddress': {
    'line2': '',
    'line1': `${obj['House'] ? `${obj['House']}, ` : ''}${obj['Street'] ? `${obj['Street']}, ` : ''}${obj['Locality'] ? `${obj['Locality']}` : ''}`,
    'locality': obj['Locality'],
    'landmark': obj['Landmark'],
    'vtc': obj['Vtc'],
    'district': obj['District'],
    'state': obj['State'],
    'pincode': obj['Pin Code'],
    'country': obj['India']
  }
});

export const useAadhaarDetailToFillProfile = async (info) => {
  // const attrs = info?.reduce((acc, item, i) => {
  //   acc[item.name] = item.value;
  //   return acc;
  // }, {});
  // console.log('info:', attrs);
  const packaged = fieldNames(info);
  const res = updateWallet(packaged);
  if (!res) {
    throw new Error('Couldn\'t update Profile, we will try again later');
  }
};


export const listOfSideProcesses = {
  // aadhaar: async (e) => updateWallet(e)
};
