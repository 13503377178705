import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabsX from '@mui/material/Tabs';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import SwipeableViews from 'react-swipeable-views';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    'sx': { textTransform: 'none', fontSize: '1.4rem' }
  };
}

export default function Tabs({ tabHeaders, tabsContent }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (location.pathname === '/faq') {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      navigate(`/faq${window.location.search}`);
    } else {
      navigate(`/instructions${window.location.search}`);
    }
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    if (index === 1) {
      navigate(`/faq${window.location.search}`);
    } else {
      navigate(`/instructions${window.location.search}`);
    }
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: { sm: '90%', md: '80%' } }}>
      <TabsX
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        {tabHeaders?.map((tabHeader, i) => (
          <Tab
            label={tabHeader}
            key={tabHeader}
            {...a11yProps(0)} />
        ))}

      </TabsX>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabsContent?.map((tab, i) => (
          <TabPanel
            key={`tab-${i}`}
            value={value}
            index={i}
            dir={theme.direction}
            sx={{
              width: '90%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {tab}
          </TabPanel>
        ))}

      </SwipeableViews>
    </Box>
  );
}
