import { Avatar, Button, CardActions, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PoweredByTrential } from '../../Assets/poweredByTrential.svg';
import MainCard from '../../Components/UI/mainCard';
import { UIStore } from '../../Config/store';


export default function Splash({ orgName = '',
  orgImage = null,
  moveNext, loadingOrgDetails,
  additionalDetail }) {
  const navigate = useNavigate();
  const isAuthenticated = UIStore.useState((s) => s.isAuthenticated);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rememberThisChoice, setRememberThisChoice] = useState(false);
  console.log('additionalDetail', additionalDetail);
  return (
    <div style={{
      width: '100vw', height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'radial-gradient(circle, rgba(224,237,250,1) 0%, rgba(255,255,255,1) 100%)'
    }}>
      <MainCard raised>
        <CardContent sx={{paddingBottom: '0px'}}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              padding: '0em'
            }}>

            <img
              src={'https://storage.googleapis.com/indisi-images-dev/32480f1826a387d400f0306af96866a1317841f8dded892a30b1222af0939edc'}
              style={{ width: '64px', maxWidth: '260px', marginBottom: '2em' }}
              alt="splash" />
            <div style={{
              height: '80px',
              width: '80px', maxWidth: '240px', marginBottom: '1em',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              {!loadingOrgDetails ?
                (orgImage ? <img
                  src={orgImage} style={{
                    width: '80px', maxWidth: '240px'
                  }} alt="splash" /> :
                  <Avatar style={{
                    height: '80px',
                    width: '80px', maxWidth: '240px',
                  }}>
                    {orgName}
                  </Avatar>) :
                <Skeleton
                  variant="circular" style={{
                    height: '80px',
                    width: '80px', maxWidth: '240px',
                    transition: 'all 0.5s fade-out'
                  }} />}
            </div>


            <Typography
              variant="h5" sx={{
                textAlign: 'center',
                width: '100%',
                fontWeight: 800,
                marginTop: '1em'
              }}>
              {orgName}
            </Typography>
            <Typography
              variant="body1" sx={{
                textAlign: 'center',
                width: '100%',
              }}>
              All-in-one certificate verification platform
            </Typography>
          </Grid>

          {additionalDetail?.partenerImage ? <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: '1em', flexWrap: 'nowrap',
            }}>

            <Typography variant="body2">
              Partnered with
            </Typography>

            <div style={{
              height: '40px',
              width: '40px',
              maxWidth: '240px',
              margin: '0 1em',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              {!loadingOrgDetails ?
                (additionalDetail?.partenerImage ? <img
                  src={additionalDetail?.partenerImage} style={{
                    width: '40px', maxWidth: '240px',
                  }} alt="splash" /> :
                  <Avatar style={{
                    height: '40px',
                    width: '40px', maxWidth: '240px',
                  }}>
                    {additionalDetail?.parternerText?.charAt(0)}
                  </Avatar>) :
                <Skeleton
                  variant="circular" style={{
                    height: '40px',
                    width: '40px', maxWidth: '240px',
                    transition: 'all 0.5s fade-out'
                  }} />}
            </div>
          </Grid> : null}
        </CardContent>

        <CardActions sx={{ display: 'flex', flexDirection: 'column', padding: '1em' }}>

          <Button
            sx={{ marginBottom: '1em' }}
            variant="contained"
            color="primary"
            id="splash-continue-button"
            onClick={() => {
              console.log('isAuthenticated: splash', isAuthenticated);
              moveNext();
              if (!isAuthenticated) {
                // updateStore('searchedQuery', {
                //   link: window.location.href.split(window.location.origin)[1],
                //   // formId: params?.formId,
                //   // txnId: params?.txnId
                // });
                navigate('/login', {
                  state: {
                    link: window.location.href.split(window.location.origin)[1],
                    nextState: { moveNext: true }
                  }
                });
              } else {
                // if (isDesktop) {
                //   setOpenDrawer(false);
                // } else {
                //   setOpenDrawer(true);
                // }
              }
            }}>
            Continue
          </Button>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              width: '100%',
              marginBottom: '1em'
            }}>
            <PoweredByTrential />
          </div>
        </CardActions>
      </MainCard>
      {/* <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Typography
          variant="body1" sx={{
            textAlign: 'center',
            width: '100%',
            fontSize: '1rem',
            fontWeight: 'bold',
            margin: '1em 0'
          }}>
          Open with
        </Typography>
        <Divider sx={{ width: '100%' }} />
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{
            padding: '1em'
          }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid
              item sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
              <Avatar src={chrome} sx={{ marginRight: '1em' }} />
              <Typography
                variant="body1" sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '0.8rem',
                  fontWeight: 'bold',
                }}>
                Chrome
              </Typography>
            </Grid>

            <Button
              variant="outlined" color="primary"
              onClick={() => {
                setOpenDrawer(false);
                moveNext();
                // navigate('/login');
              }}
              sx={{ width: '100px' }}>
              Continue
            </Button>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid
              item sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
              }}>
              <Avatar src={trentialMotif} sx={{ marginRight: '1em' }} />
              <Typography
                variant="body1" sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '0.8rem',
                  fontWeight: 'bold',
                }}>
                Trential Wallet
              </Typography>
            </Grid>

            <Button
              variant="outlined" color="primary"
              onClick={() => setOpenDrawer(false)}
              sx={{ width: '100px' }}>
              Open
            </Button>
          </Grid>
          <FormControlLabel
            control={<Checkbox
              checked={rememberThisChoice}
              onChange={(e) => setRememberThisChoice(e.target.checked)}
            />}
            sx={{
              fontSize: '8rem',
            }}
            size="small"
            label="Remember my choice"
            componentsProps={{
              typography: {
                sx: {
                  fontSize: '0.8rem',
                }
              }
            }} />
        </Grid>
      </Drawer> */}
    </div>
  );
}
