import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import Countdown from 'react-countdown';
import { OTP_COUNTDOWN } from '../../Utils/enums';

export default function ResendOtp({ props }) {
  const countDownRef = useRef();

  const [enableResendOtp, setEnableResendOtp] = React.useState(false);
  const [otpSentTime, setOtpSentTime] = React.useState('');

  useEffect(() => {
    setOtpSentTime(new Date());
    setEnableResendOtp(false);
    const timeout = setTimeout(() => {
      countDownRef?.current?.start();
    }, 1000);

    () => {
      clearTimeout(timeout);
    };
  }, []);


  const countDownRenderer = ({ hours, minutes, seconds, completed }) => {
    console.log('hours, minutes, seconds, completed', hours, minutes, seconds, completed);
    if (completed) {
      // Render a completed
      if (!enableResendOtp) {
        // setTimeout(() => {
        setEnableResendOtp(true);
        // }, 1000);
      }
      return (
        <LoadingButton
          sx={{ width: 'fit-content', fontWeight: 700, fontSize: '1.4rem', margin: '0', padding: 0 }}
          onClick={async () => {
            const triggeredResponse = props?.resendApiTrigger();
            if (triggeredResponse) {
              setOtpSentTime(new Date());
              setEnableResendOtp(false);
              setTimeout(() => {
                countDownRef?.current?.start();
              }, 1000);
            }
          }}
        >
          Resend OTP
        </LoadingButton>
      );
    }
    // Render a countdown
    return (
      <span>
        {' '}
        Resend OTP in
        {' '}
        <strong>
          {minutes < 10 ? `${0}${minutes}` : minutes}
          :
          {seconds < 10 ? `${0}${seconds}` : seconds}

        </strong>
        {' '}
        second
      </span>
    );
  };

  return (<Grid
    container
    direction="row"
    justifyContent="flex-end"
    alignItems="center"
    sx={{
      padding: '0em',
      margin: '0em',
      // maxHeight: '100vh',
      width: '100%',
      flexWrap: 'nowrap',
    }}>
    <Countdown
      ref={countDownRef}
      date={(otpSentTime || new Date()).getTime() + OTP_COUNTDOWN}
      renderer={countDownRenderer}
    />
  </Grid>
  );
}
