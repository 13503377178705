import { MenuItem } from '@mui/material';
import CustomTextField from './customTextField';

export default function Select({ name,
  label,
  type = 'text',
  maxLength = false,
  onChange = () => { },
  value,
  startAdornment,
  endAdornment = null,
  options,
  sx,
  ...props
}) {
  const handleChange = (event) => {
    if (maxLength) {
      event.target.value = event.target.value.toString().slice(0, maxLength);
    }
    onChange(event.target.value);
  };
  console.log('options==', options, value);
  return (
    <CustomTextField
      select
      fullWidth={!props.disableFullWidth}
      sx={{
        'marginRight': '1em',
        // 'backgroundColor': '#ffffff',
        '& .MuiInputBase-root': {
          marginBottom: '5px'
        },
        ...sx
      }}
      variant={'outlined'}
      InputLabelProps={{ shrink: true }}
      InputProps={
        {
          startAdornment: startAdornment ?? null,
          endAdornment: endAdornment ?? null
        }
      }
      id={name}
      label={label}
      // type={type}
      value={value ?? props.defaultValue ?? ''}
      onChange={handleChange}
      {...props}>
      <MenuItem value="">
        Select an option
      </MenuItem>
      {options ? options?.map((option) => (
        <MenuItem key={option.value} value={option.value} sx={{ margin: 0 }}>
          {option.name}
        </MenuItem>
      )) : <div />}
    </CustomTextField>
  );
}
