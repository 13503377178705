import { AccountCircleOutlined, Apartment, BabyChangingStation, Call, Home, Person2 } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SimpleBackground from '../../Components/Background/Simple';
import { defaultFormat } from '../../Components/ListDataUI';
import ListJson from '../../Components/UI/list';
import { getWallet } from '../../Config/api';

const displayData = {
  name: {
    icon: <AccountCircleOutlined />,
    label: 'Name'
  },
  mobile: {
    icon: <Call />,
    label: 'Phone Number'
  }, dob: {
    icon: <BabyChangingStation />,
    label: 'Date Of Birth'
  }, gender: {
    icon: <Person2 />,
    label: 'Gender'
  },
  // fatherName: {
  //   icon: <Shield />,
  //   label: 'Guardian Name'
  // },
  // profession: {
  //   icon: <Work />,
  //   label: 'Profession'
  // },
  currentAddress: {
    icon: <Apartment />,
    label: 'Local Address'
  }, permanentAddress: {
    icon: <Home />,
    label: 'Permanent Address'
  }
};

const addressFields = ['line1', 'landmark', 'vtc', 'district', 'state', 'pincode'];
export default function MyProfile() {
  const theme = useTheme();
  useEffect(() => {
    getWallet()
        .then((res) => {
          if (res) {
            const temp = {
              name: res.data?.profileDetails?.name,
              // email: {
              //   value: res.data.email,
              //   verified: true,
              // },
              mobile: res.data.mobile,
              dob: res.data?.profileDetails?.dob,
              fatherName: res.data?.profileDetails?.fatherName,
              profession: res.data?.profileDetails?.profession,
              gender: res.data?.profileDetails?.gender,
              currentAddress: Object.keys(res.data?.profileDetails?.currentAddress || {}).reduce((acc, item) => {
                if (addressFields.includes(item)) {
                  acc[item] = res.data?.profileDetails?.currentAddress[item];
                }
                return acc;
              }, {}),
              permanentAddress: Object.keys(res.data?.profileDetails?.permanentAddress || {}).reduce((acc, item) => {
                if (addressFields.includes(item)) {
                  acc[item] = res.data?.profileDetails?.permanentAddress[item];
                }
                return acc;
              }, {}),
            // image: res.image
            };

            setProfileData(temp);
          }
        });
  }, []);
  const [profileData, setProfileData] = useState({});

  console.log('profileData', profileData);
  return <SimpleBackground
    sx={{
      // maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '100%',
      padding: '0 1em',
      margin: 'auto'
    }}
    title={'Profile'}
    disableBack={false}>
    <List sx={{ width: '100%' }}>
      {Object.keys(profileData)?.length > 0 ?
        Object.keys(displayData)?.map((item, i) => (item !== 'image' && (profileData[item] &&
          (typeof profileData[item] === 'object' ? Object.keys(profileData[item]).length > 0 : true))) ? <ListItem
            divider
            disablePadding
            // secondaryAction={<ChevronRight />}
            sx={{ width: '100%' }}>
            <ListItemIcon
              sx={{
                margin: '8px',
                color: '#ACACAC',
                fontSize: '1.4rem'
              }}>
              {displayData[item].icon}
            </ListItemIcon>
            <ListItemText
              sx={{ margin: '0.6em', marginRight: 'auto' }}
              primary={<span>
                {displayData[item].label}
                {' '}
                {/* {profileData[item]?.verified === undefined ? null :
        <Chip
          sx={{
            'fontSize': '0.6rem',
            'borderRadius': '4px',
            'padding': '5px 6px',
            'marginLeft': '6px',
            'height': 'fit-content',
            '& .MuiChip-label': {
              padding: '0'
            }
          }}
          label={profileData[item]?.verified ? 'Verified' : 'Unverified'}
          color={profileData[item]?.verified ? 'primary' : 'grey'} />} */}
              </span>}
              secondary={<ListJson
                data={profileData[item]}
                format={{ 'documentUrl': 'url', ...defaultFormat }}
                item={item} />}
              primaryTypographyProps={{
                sx: {
                  fontWeight: 700,
                  margin: 0,
                  color: '#444444',
                  textAlign: 'left'
                }
              }}
              secondaryTypographyProps={{
                variant: 'body',
                sx: {
                  fontWeight: 400,
                  margin: 0,
                  color: '#444444',
                  textAlign: 'left'
                }
              }}
            />
          </ListItem> : null) : null}

    </List>
    {/* <ListItem
      disablePadding
      sx={{ width: '100%' }}>
      <ListItemIcon
        sx={{
          margin: '8px',
          color: '#ACACAC'
        }}>
        <DeleteOutline color="error" />
      </ListItemIcon>
      <ListItemButton sx={{ padding: '0' }}>
        <ListItemText
          sx={{ margin: '0.6em', marginRight: 'auto' }}
          primary="Delete my account"
          primaryTypographyProps={{
            sx: {
              fontWeight: 500,
              margin: 0,
              textAlign: 'left'
            },
            color: 'error'
          }}
        />
      </ListItemButton>

    </ListItem> */}
  </SimpleBackground>;
}


