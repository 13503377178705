/* eslint-disable eqeqeq */
import { Loader } from '@aws-amplify/ui-react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import '@aws-amplify/ui-react/styles.css';
import { ErrorOutline } from '@mui/icons-material';
import { Button, Card, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faceMatch } from '../../Config/api';
import { UIStore } from '../../Config/store';
import { LIVENESS_CONFIDENCE_SCORE } from '../../Utils/enums';
import { getSessionId, getSessionResponse, getSessionToken } from './apis';


function FaceLiveness({ faceLivenessAnalysis }) {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [sessionId, setSessionId] = React.useState(null);
  const docData = UIStore.useState((s) => s.docData);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const form = UIStore.useState((s) => s.form);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);

  const fetchSessionToken = async () => {
    /*
         * API call to create the Face Liveness Session
         */
    setLoading(true);
    const sToken = await getSessionToken();
    const sessionIdFromParams = await getSessionId(sToken);
    setSessionId(sessionIdFromParams);
    setLoading(false);
    console.log('TEST close deeplink', 'trential://payload');
    //   setTimeout(()=>{
    //     // window.history.back()
    //     // window.close()
    //     window.location.href = "https://kla123.netlify.app/"
    //   }, 3000)
  };
  useEffect(() => {
    fetchSessionToken();
  }, []);

  /*
   * Get the Face Liveness Session Result
   */
  const handleAnalysisComplete = async (data) => {
    /*
         * API call to get the Face Liveness Session result
         */
    console.log('handleAnalysisComplete', data);
    faceLivenessAnalysis(data?.data);


    const pollingId = setInterval(() => {
      getSessionResponse(sessionId)
          .then(async (response) => {
            if (!response.error) {
              if (response?.data?.status == 'SUCCEEDED') {
                console.log('Session Response', response?.data);
                clearInterval(pollingId);
                if (response.data.confidence < LIVENESS_CONFIDENCE_SCORE) {
                  window.snackbar('error', 'Liveness check failed, Try again');
                  await fetchSessionToken();
                } else {
                  await faceMatch(
                      searchedQuery?.txnId, currentCredentialUnderFetch?.key,
                      {
                        sessionId,
                        orgId: searchedQuery?.orgId,
                        txnId: searchedQuery?.txnId
                      }
                  ).then((res) => {
                    if (res) {
                      // patchStore('credentialsTriggered', { [currentCredentialUnderFetch?.key]: true }, searchedQuery?.txnId);
                      navigate(searchedQuery?.link, { state: { moveToNextVerification: true } });
                    }
                  }).catch(() => {
                  // window.snackbar('error', 'Your face didn\'t match your document, either try again or use another document');
                    navigate(-1);
                  });
                }
              } else if (response?.data?.status == 'EXPIRED' || response?.data?.status == 'FAILED') {
                clearInterval(pollingId);
                window.snackbar('error', 'Liveness check failed, Try again');
                await fetchSessionToken();
              }
            } else {
              window.snackbar('error', 'Liveness check failed, Try again');
              await fetchSessionToken();
            }
          });
    }, 2000); // each count lasts for 2 seconds
    // window.location.href = "https://www.trential.com";
    // window.location.replace("trential://payload")
    // redirectToApp()
  };

  console.log('sessionId', sessionId);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <FaceLivenessDetector
          sessionId={sessionId}
          region="ap-south-1"
          onAnalysisComplete={handleAnalysisComplete}
          onError={(error) => {
            console.error(error);
            // redirectToApp()
          }}
          components={{
            ErrorView: ({ children }) => {
              document.querySelector('.amplify-liveness-cancel-container').style.display = 'none';
              return (
                <Card
                  variant="error" sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '1em'
                  }}>
                  <ErrorOutline color="error" sx={{ margin: '2px', fontSize: 40 }} />
                  <Typography variant='body2'>
                    Face is not aligned properly within the stipulated time
                  </Typography>
                  <Button
                    variant={'contained'} color="primary"
                    sx={{ width: 'fit-content' }}
                    onClick={async () => {
                      await fetchSessionToken();
                    }}>
                    Try Again
                  </Button>
                </Card>
              );
            },
          }}
        />
      )}
    </>
  );
}

export default FaceLiveness;
