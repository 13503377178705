import Gleap from 'gleap';

const GleapInstance = Gleap;

const initGleap = () => {
  GleapInstance.setEnvironment(process.env.REACT_APP_GLEAP_ENV);
  GleapInstance.setUseCookies(true);
  GleapInstance.initialize(process.env.REACT_APP_GLEAP_KEY);
};

export { GleapInstance, initGleap };

