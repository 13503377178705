

export const customConfig = (org) => {
  switch (org) {
    case 'nsdc': return [{
      text: `Welcome to "${process.env.REACT_APP_ORG_FULL_NAME}", a product by ${process.env.REACT_APP_ORG_ALTERNATE_NAME} (hereinafter referred to as ${process.env.REACT_APP_ORG_ALTERNATE_NAME} or ${process.env.REACT_APP_ORG_FULL_NAME}). Throughout these Terms of Service, referred to as "Terms," the terms "Company," "we," "our," and "us" pertain to ${process.env.REACT_APP_ORG_FULL_NAME}. These Terms govern your use of our web pages at https://nsdctrust.nsdcindia.org/terms, as well as our products, APIs, and information obtained through these platforms, collectively known as the "Service." By accessing or utilizing the Service, you agree to abide by these Terms. Additionally, please review our Privacy Policy, an integral part of these Agreements, outlining how we handle information resulting from your use of our web pages. `,
      type: 'body1'
    }, {
      text: `Your acceptance of these Agreements signifies your understanding of their content. If you disagree with these Agreements or are unable to comply with them, please refrain from using the Service. In such cases, communicate your concerns to us via email at ${process.env.REACT_APP_ORG_MAIL} so that we may explore potential solutions. These Terms apply to all individuals, visitors, and users seeking access to or use of the Service. `,
      type: 'body1'
    },
    ];
    case 'ECE': return [{
      text: `Welcome to Trential Technologies Pvt. Ltd (hereinafter referred to as Trential or Trential Technologies). Throughout these Terms of Service, referred to as "Terms," the terms "Company," "we," "our," and "us" pertain to Trential Technologies Pvt. Ltd. These Terms govern your use of our web pages at https://trential.com/terms, as well as our products, APIs, and information obtained through these platforms, collectively known as the "Service." By accessing or utilizing the Service, you agree to abide by these Terms. Additionally, please review our Privacy Policy, an integral part of these Agreements, outlining how we handle information resulting from your use of our web pages. `,
      type: 'body1'
    }, {
      text: `Your acceptance of these Agreements signifies your understanding of their content. If you disagree with these Agreements or are unable to comply with them, please refrain from using the Service. In such cases, communicate your concerns to us via email at support@trential.com so that we may explore potential solutions. These Terms apply to all individuals, visitors, and users seeking access to or use of the Service. `,
      type: 'body1'
    }];
    case 'GCE': return [{
      text: `Welcome to Trential Technologies Pvt. Ltd (hereinafter referred to as Trential or Trential Technologies). Throughout these Terms of Service, referred to as "Terms," the terms "Company," "we," "our," and "us" pertain to Trential Technologies Pvt. Ltd. These Terms govern your use of our web pages at https://trential.com/terms, as well as our products, APIs, and information obtained through these platforms, collectively known as the "Service." By accessing or utilizing the Service, you agree to abide by these Terms. Additionally, please review our Privacy Policy, an integral part of these Agreements, outlining how we handle information resulting from your use of our web pages. `,
      type: 'body1'
    }, {
      text: `Your acceptance of these Agreements signifies your understanding of their content. If you disagree with these Agreements or are unable to comply with them, please refrain from using the Service. In such cases, communicate your concerns to us via email at support@trential.com so that we may explore potential solutions. These Terms apply to all individuals, visitors, and users seeking access to or use of the Service. `,
      type: 'body1'
    }];
    default: return [{
      text: `Welcome to Trential Technologies Pvt. Ltd (hereinafter referred to as Trential or Trential Technologies). Throughout these Terms of Service, referred to as "Terms," the terms "Company," "we," "our," and "us" pertain to Trential Technologies Pvt. Ltd. These Terms govern your use of our web pages at https://trential.com/terms, as well as our products, APIs, and information obtained through these platforms, collectively known as the "Service." By accessing or utilizing the Service, you agree to abide by these Terms. Additionally, please review our Privacy Policy, an integral part of these Agreements, outlining how we handle information resulting from your use of our web pages. `,
      type: 'body1'
    }, {
      text: `Your acceptance of these Agreements signifies your understanding of their content. If you disagree with these Agreements or are unable to comply with them, please refrain from using the Service. In such cases, communicate your concerns to us via email at support@trential.com so that we may explore potential solutions. These Terms apply to all individuals, visitors, and users seeking access to or use of the Service. `,
      type: 'body1'
    }];
  }
};

export const configTnC = [
  {
    text: `Introduction `,
    type: 'h5'
  },
  ...(customConfig(process.env.REACT_APP_ORG)),
  {
    text: `User Agreement`,
    type: 'h5'
  }, {
    text: `This Agreement remains effective as long as you use this Application and its services. By accessing and using the Application, you acknowledge your commitment to the terms outlined herein. It is important to note that these Terms of Service do not grant any rights to you or any third-party beneficiaries. `,
    type: 'body1'
  }, {
    text: `Communications`,
    type: 'h5'
  }, {
    text: `All official communications and notices will be delivered in writing via electronic mail at ${process.env.REACT_APP_ORG_MAIL}`,
    type: 'body1'
  }, {
    text: `Purchases `,
    type: 'h5'
  }, {
    text: `If you intend to make a purchase through our Service, you may be required to provide specific transaction-relevant information. This may include, but is not limited to, credit card details, billing address, and shipping information. By initiating a Purchase, you affirm that you have the legal right to use the provided payment methods and that the information you provide is accurate. We reserve the right to refuse or cancel orders for various reasons, including product availability, errors in product descriptions or pricing, or suspected fraud.`,
    type: 'body1'
  }, {
    text: `Free Trial`,
    type: 'h5'
  }, {
    text: `At its discretion, ${process.env.REACT_APP_ORG_FULL_NAME} may offer a free trial for a limited number of completed transactions ("Free Trial"). If you opt for a Free Trial, you may need to submit billing information, but you will not be charged until the Free Trial period concludes or the credit offered gets utilized fully or expires. ${process.env.REACT_APP_ORG_ALTERNATE_NAME} retains the right to modify or cancel Free Trial offers at any time without notice. `,
    type: 'body1'
  }, {
    text: `Fee Changes`,
    type: 'h5'
  }, {
    text: `${process.env.REACT_APP_ORG_ALTERNATE_NAME} reserves the right to modify Subscription fees at any time, with any changes becoming effective at the end of the current billing cycle. Users will be given reasonable prior notice to terminate their Subscription before the new fee structure takes effect. `,
    type: 'body1'
  }, {
    text: `Refunds`,
    type: 'h5'
  }, {
    text: `${process.env.REACT_APP_ORG_ALTERNATE_NAME} does not entertain any request or demand for refund fees paid towards the subscription for the services. The fees paid towards the subscription shall not be refunded unless such claim arises due the breach in any terms of services by ${process.env.REACT_APP_ORG_ALTERNATE_NAME} `,
    type: 'body1'
  }, {
    text: `Platform Usage`,
    type: 'h5'
  }, {
    text: `The platform used to provide the Services is the exclusive property of ${process.env.REACT_APP_ORG_ALTERNATE_NAME}. Users are prohibited from engaging in unlawful activities such as distribution, modification, transmission, or unauthorized use of the platform. `,
    type: 'body1'
  }, {
    text: `Analytics and Cookies Usage `,
    type: 'h5'
  }, {
    text: `To personalize your interaction with our website/Application, we may assign a unique random number, known as a cookie, to your computer browser. Cookies play a crucial role in enhancing website/application performance by personalizing your experience and facilitating a more convenient visit. ${process.env.REACT_APP_ORG_ALTERNATE_NAME} or its affiliates or vendors may utilize this data to analyze trends and statistics, monitor the domains of origin, and measure visitor activity on the website/application. However, ${process.env.REACT_APP_ORG_FULL_NAME} ensures the anonymity of this information. `,
    type: 'body1'
  }, {
    text: `Eligibility`,
    type: 'h5'
  }, {
    text: `The Service is intended for individuals at least eighteen (18) years old. By accessing or using the Service, you warrant that you are at least eighteen years old and have the authority to enter into these Terms. `,
    type: 'body1'
  }, {
    text: `Accounts`,
    type: 'h5'
  }, {
    text: `When creating an account, users guarantee they are above 18 years old, and the provided information is accurate. Users are responsible for maintaining the confidentiality of their accounts and passwords, and for promptly notifying the Company of any unauthorized access or security breaches. `,
    type: 'body1'
  }, {
    text: `Intellectual Property`,
    type: 'h5'
  }, {
    text: `Unless explicitly mentioned otherwise, the copyright and all intellectual property rights for all content showcased on the Application (including, but not limited to, text, audio, video, or graphical images), as well as trademarks and logos featured on this Application, belong to ${process.env.REACT_APP_ORG_ALTERNATE_NAME}, its parent company, affiliates, and associates. These rights are safeguarded by relevant laws. Any infringement will be robustly defended and pursued to the maximum extent allowed by law. `,
    type: 'body1'
  }, {
    text: `Prohibited Uses`,
    type: 'h5'
  }, {
    text: `You are permitted to utilize the Service only for lawful purposes and in compliance with the specified Terms. You agree not to engage in the following prohibited activities: `,
    type: 'body1'
  }, {
    text: `<ol style="margin:-1em 0 0 0;"><li>Transmit or solicit the sending of any advertising, promotional material, "junk mail," "chain letter," "spam," or similar solicitations. </li> <li>Exploit, harm, or attempt to exploit or harm minors by exposing them to inappropriate content or otherwise. </li> <li>Infringe upon the rights of others or engage in illegal, threatening, fraudulent, or harmful activities, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity. </li> <li>Conduct activities that restrict or inhibit anyone's use or enjoyment of the Service, as determined by us, which may harm or offend Company or users of the Service or expose them to liability. </li> <li>Violate any applicable national or international laws or regulations. </li> <li>Impersonate Company, a Company employee, another user, or any other person or entity.</li></ol> <br/> Additionally, you agree not to: `,
    type: 'body1'
  },
  {
    text: `<ol style="margin-top:-1em;"><li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Service, the server on which the Service is stored, or any server, computer, or database connected to the Service. </li> <li> Use any automated means, such as robots or spiders, to access the Service for any purpose, including monitoring or copying material on the Service. </li> <li> Attack the Service through a denial-of-service attack or a distributed denial-of-service attack. </li> <li> Use any manual process to monitor, copy material on the Service, or for any other unauthorized purpose without our prior written consent. </li> <li> Introduce malicious or technologically harmful material, such as viruses, Trojan horses, worms, logic bombs, or any other harmful elements. </li> <li> Take any action that may damage or falsify the Company rating. </li> <li> Use the Service in a manner that could disable, overburden, damage, or impair the Service or interfere with any other party's use of the Service, including their ability to engage in real-time activities through the Service. </li> <li> Use any device, software, or routine that interferes with the proper functioning of the Service. </li> <li> Otherwise attempt to interfere with the proper functioning of the Service.</li></ol>`,
    type: 'body1'
  }, {
    text: `Rights of ${process.env.REACT_APP_ORG_FULL_NAME} `,
    type: 'h5'
  }, {
    text: `${process.env.REACT_APP_ORG_FULL_NAME} retains the right to suspend your access to the services without prior notice for any breach of the Terms of Use. By providing information through the application/services, you grant us a non-exclusive permission, and irrevocable right to retain and store your personal information, data, and related details. The utilization of your personal information is subject to our Privacy Policy. `,
    type: 'body1'
  }, {
    text: `Additionally, ${process.env.REACT_APP_ORG_FULL_NAME} is permitted to incorporate your organization's name and logo in promotional materials, including on its website. `,
    type: 'body1'
  }, {
    text: `Copyright Policy `,
    type: 'h5'
  }, {
    text: `We respect intellectual property rights. If you believe that content on the Service infringes on copyright, please contact us at ${process.env.REACT_APP_ORG_MAIL} with detailed information. `,
    type: 'body1'
  }, {
    text: `Error Reporting and Feedback `,
    type: 'h5'
  }, {
    text: `Users may provide feedback or report errors to ${process.env.REACT_APP_ORG_MAIL}. By submitting feedback, users acknowledge that the Company may use and develop similar ideas without any obligation of confidentiality. `,
    type: 'body1'
  }, {
    text: `Links to Other Websites `,
    type: 'h5'
  }, {
    text: `The Service may contain links to third-party websites. ${process.env.REACT_APP_ORG_FULL_NAME} is not responsible for the content, privacy policies, or practices of these third-party sites. `,
    type: 'body1'
  }, {
    text: `Disclaimer of Warranty `,
    type: 'h5'
  }, {
    text: `The Service is provided "as is" and "as available." The Company makes no warranties, express or implied, regarding the operation of the Service, its content, or materials included therein. `,
    type: 'body1'
  }, {
    text: `Limitation of Liability `,
    type: 'h5'
  }, {
    text: `Except as prohibited by law, users agree to hold the Company and its officers, directors, employees, and agents harmless for any indirect, punitive, special, or incidental damages arising from the use of the Service. `,
    type: 'body1'
  }, {
    text: `Termination `,
    type: 'h5'
  }, {
    text: `The Company reserves the right to terminate or suspend user accounts and access to the Service for any reason, without prior notice. Provisions that, by their nature, should survive termination will continue to apply. `,
    type: 'body1'
  }, {
    text: `Governing Law`,
    type: 'h5'
  }, {
    text: `These Terms are governed by the laws of India. The courts in Gurugram have exclusive jurisdiction over any disputes arising from these Terms. `,
    type: 'body1'
  },
  {
    text: `Changes to Service  `,
    type: 'h5'
  }, {
    text: `The Company may withdraw or modify the Service at its discretion, without notice. Users are advised to review these Terms regularly, as continued use implies acceptance of any changes. `,
    type: 'body1'
  }, {
    text: `Amendments to Terms `,
    type: 'h5'
  }, {
    text: `Terms may be amended by posting revised terms on the website. Continued use of the Service after such changes constitutes acceptance of the revised terms. `,
    type: 'body1'
  }, {
    text: `Indemnification `,
    type: 'h5'
  }, {
    text: `You agree to defend, indemnify, and hold harmless ${process.env.REACT_APP_ORG_FULL_NAME} and/or its associate entities, their officers, directors, employees, and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your use of the web site/application material or your breach of these Terms and Conditions of Web site/application use. `,
    type: 'body1'
  }, {
    text: `Waiver and Severability `,
    type: 'h5'
  }, {
    text: `No waiver of terms by the Company shall be deemed a continuing waiver, and any invalid provision shall be limited or eliminated, with the remaining provisions remaining in effect. `,
    type: 'body1'
  }, {
    text: `Acknowledgment `,
    type: 'h5'
  }, {
    text: `By using the Service, users acknowledge having read and agreed to these Terms of Service. `,
    type: 'body1'
  }, {
    text: `Entire Agreement `,
    type: 'h5'
  }, {
    text: `<p style="margin-top:0em;">These Terms of Service constitute the complete agreement between the parties regarding the subject matter and override all prior or contemporaneous written or oral agreements. 
    <br/>
    <br/>
    Utilizing internet and chat-based information and research dissemination services carries inherent risks. Users/subscribers should be aware that service failures may occur due to hardware, software, or Internet connection issues. While we ensure timely delivery of emails/messages, the responsibility for delivering them to the user/subscriber's inbox or message center lies with the email/messenger service provider network. Technical issues, solely addressable by the service provider, may cause delays or non-delivery on certain days. ${process.env.REACT_APP_ORG_FULL_NAME}, the owner of ${process.env.REACT_APP_ORG_FULL_NAME}, its associated group companies, and employees cannot be held accountable for such occurrences. 
    <br/>
    <br/>
    ${process.env.REACT_APP_ORG_FULL_NAME} explicitly disclaims any implied warranties dictated by the laws of any jurisdiction. We submit ourselves to the jurisdiction of the courts in Gurugram, India. If you disagree with our terms, conditions, and disclaimers, please refrain from reading the material on our pages. ${process.env.REACT_APP_ORG_FULL_NAME} holds no legal liabilities beyond the laws of India. We retain the right to modify our application, disclaimers, terms, and conditions at any time.</p> `,
    type: 'body1'
  }, {
    text: `Grievance & Redressal  `,
    type: 'h5'
  }, {
    text: `For any information regarding the various services and products offered by us or for providing any critical feedback with regard to our website/product performance/staff response, you can contact us at ${process.env.REACT_APP_ORG_MAIL} `,
    type: 'body1'
  },

];
