import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import React from 'react';

export default function Status({ done, waiting, total }) {
  console.log('done, waiting, total', done, waiting, total);
  return !isNaN(done) && !isNaN(waiting) && !isNaN(total) && (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: '1em',
        bgcolor: '#F4FCFD',
        width: '95%',
        borderRadius: '8px'
      }}
    >
      <Grid
        item
        xs={10}
        sx={{}}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start" sx={{ padding: '0em' }}>
          <Typography variant="h6" sx={{ margin: 0 }}>
            Steps Completed
          </Typography>
          <Typography
            variant="body2"
            color="grey" sx={{ margin: 0 }}>
            Select the steps below to start verification
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {(!isNaN(done) && !isNaN(waiting) && !isNaN(total)) ? <CircularWithValueLabel value={{
          remaining: done + waiting,
          total: total
        }} /> : null}
      </Grid>
    </Grid>
  );
}


function CircularProgressWithLabel({ value, ...props }) {
  console.log('props', props, value);
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>

      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={56}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          size={56}
          variant="determinate"
          value={(value?.remaining / value?.total) * 100}
          disableShrink
          sx={{
            color: (theme) => (theme.palette.primary.main),
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          thickness={4}
          {...props} />

      </Box>

      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6" component="div" color="text.secondary">
          {`${value.remaining}/${value.total}`}
        </Typography>
      </Box>
    </Box>
  );
}

export function CircularWithValueLabel({ value }) {
  return <CircularProgressWithLabel value={value} />;
}
