/* eslint-disable */
const routes = {
    getTxInfo: "/api/1.0/transactions/payment/price",
    getPaidTxDetails: '/api/1.0/transactions/payment',
    getPaymentRegionList: "/api/1.0/transactions/payment/region",
    initPayment: "api/1.0/transactions/payment/init",
    getPaymentStatus: "api/1.0/transactions/payment/poll",
    getEkycFormById: '/api/1.0/transactions/info',
    getTxHistory: '/api/1.0/transactions/payment/history',
    capturePaymentPaypal: '/api/1.0/transactions/payment/capture/paypal',
    downloadInvoice: '/api/1.0/transactions/payment/downloadInvoice',
};

export default routes