import { ArrowBack } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { UIStore } from '../../Config/store';
import { pathnameToBackLink, pathnameToText } from '../Header/data';

export default function SimpleBackground({ title = '', sx,
  disableBack = false,
  children, backButtonOnClick = null }) {
  const { state } = useLocation();

  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        borderRadius: '8px',
        margin: '1em',
        flexWrap: 'nowrap',
        paddingTop: '1em !important',
        ...sx
      }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          borderRadius: '8px',
          flexWrap: 'nowrap'
        }}>
        {disableBack ? null : (pathnameToText(window.location.pathname) === ' ' ? null :
          pathnameToBackLink(window.location.pathname, searchedQuery?.link) === null ? null : (
            <Grid item>
              <IconButton
                color="inherit"
                edge="end"
                onClick={() => {
                  if (backButtonOnClick) {
                    backButtonOnClick(() => {
                      navigate(pathnameToBackLink(window.location.pathname, searchedQuery?.link));
                    });
                  } else {
                    navigate(pathnameToBackLink(window.location.pathname, searchedQuery?.link));
                  }
                }}
                sx={{ margin: 0, marginTop: '5px', padding: 0, marginRight: '1em' }}
                datacy="back"
              >
                <ArrowBack />
              </IconButton>
            </Grid>))}
        <Grid item xs={pathnameToBackLink(window.location.pathname, searchedQuery?.link) !== null ? 11 : 12}>
          <Typography
            variant="body1" sx={{
              margin: 0,
              fontWeight: 600,
              fontSize: '2.4rem',
              textAlign: 'left',
            }}>

            {title}
          </Typography>
        </Grid>
      </Grid>
      {children}

    </Grid>
  );
}
