import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import bgWaveBlueCommon from '../../Assets/bgWaveBlueCommon.svg';

export default function Wave({ image, children }) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        borderRadius: '8px',
        margin: '0em 0 0em 0',

      }}>
      <Grid
        item
        xs={2}
        sm={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${bgWaveBlueCommon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100%',
          minHeight: '240px',
          paddingBottom: '1em'
        }}>
          {image ? <div style={{
            padding: '0.9em 0.5em',
            borderRadius: '50%',
            backgroundColor: '#ffffff',
            aspectRatio: 1,
            textAlign: 'center',
            width: 'fit-content',
          }}>
            <img
              src={image} style={{
                width: '60px',
              }} alt="org" />
          </div> : <Skeleton variant="circular" width={60} height={60} />}
          {children}
        </div>

      </Grid>
      <Grid
        item
        xs
        sm
        sx={{

        }}>
      </Grid>
    </Grid >
  );
}
