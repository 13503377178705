import { Delete, Edit, InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, Grid, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { default as React, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addProfileVerificationData, deleteVerification, getWallet } from '../../Config/api';
import { UIStore, updateStore } from '../../Config/store';
import { convertToTitleCase, hasJsonStructure, isValidUrlLite, shouldAllowModification } from '../../Helper/helpers';
import { mixpanel, trackingEvents } from '../../Monitoring/Mixpanel';
import { ignoreKeysToDisplayCommon } from '../../Utils/enums';
import ListJson from '../UI/list';

const ignoreKeys = ignoreKeysToDisplayCommon;

const RenderValue = ({ value, index }) => !hasJsonStructure(value) &&
  (
    isValidUrlLite(value) ?
      <Button
        href={value}
        sx={{ 'typography': 'body2', 'color': 'primary.main', 'textDecoration': 'underline', '&:hover': { textDecoration: 'underline' }, 'fontWeight': '600', 'wordBreak': 'break-word', 'display': 'flex', 'justifyContent': 'flex-start', 'minWidth': 'fit-content', 'padding': '.4rem .5rem' }}
        target="_blank" rel="noopener noreferrer"
      >
        Link
        {' '}
        {index >= 0 ? `- ${index + 1}` : ''}
      </Button> :
      <Typography variant="body2" sx={{ 'fontWeight': '400', 'wordBreak': 'break-word' }}>
        {/* {value || '-'} */}
        <ListJson data={value} />
      </Typography>
  );

const RenderTableRow = ({ label = '-', value = '-', format = {}, customUi }) => {
  if (ignoreKeys?.includes(label)) {
    return null;
  }

  return <TableRow>
    <TableCell sx={{ border: 0, pt: '.45em', pb: '.45em', borderBottom: '1px solid', borderColor: '#DADADA' }}>
      <Typography variant="body2" sx={{ fontWeight: '700' }}>
        {convertToTitleCase(label)}
      </Typography>

      {customUi?.[label] ? customUi?.[label](value) :
        <>
          <ListJson
            data={value} format={{ ...format, ...defaultFormat }}
            item={label} />
        </>
      }
    </TableCell>
  </TableRow>;
};

function ListDataUI({ info, format, index, id, customUi = {}, currentInfo = {},
  fn, setupStates }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [loadingSkip, setLoadingSkip] = useState(false);
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const form = UIStore.useState((s) => s.form);
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];

  console.log('Current workingStepper', info);
  return (
    <>
      {
        Array.isArray(info) ?
          <Box sx={{ padding: { xs: '0 0.34rem', md: '0 3rem' }, mb: index === info?.length - 1 ? '1.5rem' : 0 }}>
            {
              info?.map((item) =>
                Array.isArray(item) ?
                  <Table sx={{ width: '100%', mt: '.5rem', mb: '1rem', pageBreakInside: 'avoid' }}>
                    <TableBody>
                      {
                        item?.map((record) =>
                          <RenderTableRow label={record?.label} value={record?.value} />)
                      }
                    </TableBody>
                  </Table> :
                  typeof item === 'object' ?
                    <Box sx={{ mt: '1.75rem', pageBreakInside: 'avoid' }}>


                      <RenderTableRow
                        label={item?.label} value={item?.value}
                        setupInProgress
                        editableFieldNames={{ [item?.label]: 'text' }} />


                    </Box> : null)
            }
          </Box> :
          <Table>
            <TableBody sx={{ position: 'relative' }}>
              {currentCredentialUnderFetch && shouldAllowModification(
                  currentCredentialUnderFetch.key, currentInfo?.stateInProfile, currentInfo?.stateInForm,
                  formInfo?.[currentCredentialUnderFetch.key]?.state,
              ) ?
                <Grid
                  container direction="row"
                  sx={{
                    position: 'absolute',
                    top: '0em',
                    right: '0',
                    width: 'fit-content',
                  }}>
                  <IconButton
                    onClick={async () => {
                      const res = await addProfileVerificationData(
                          currentInfo?.name,
                          {
                            orgId: currentInfo?.orgId,
                            transactionId: currentInfo?.transactionId,
                            subType: id,
                            ...info
                          }
                      );
                      if (!res) {
                        return null;
                      }
                      updateStore('editData', {
                        data: info
                      });
                      navigate('/docs', {
                        state: {
                          ...state,
                          edit: {
                            uid: id
                          }
                        }
                      });
                    }}
                    color="primary"
                    id={`edit-icon`}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={async () => {
                      setOpen(true);
                    }}
                    color="error"
                    id={`delete-icon`}
                  >
                    <Delete />
                  </IconButton>
                </Grid> : null}
              {info &&
                Object.keys(info)?.map((key) => info?.[key] && info?.[key] !== '[]' ? <RenderTableRow
                  label={key}
                  value={info?.[key]}
                  format={format}
                  customUi={customUi}
                  setupStates={setupStates}
                /> : null)
              }
            </TableBody>

          </Table>
      }
      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={() => { setOpen(false); }}
        sx={{ padding: '1em' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: '1em' }}>

          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}>
            <InfoOutlined color="primary" sx={{ fontSize: 40, marginBottom: '0.4em' }} />
            Are you sure?
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: '1em' }}>
            <Button
              variant="outlined"
              color="brand"
              sx={{ width: '48%' }}
              onClick={async () => {
                setOpen(false);
              }}>
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="brand"
              loading={loadingSkip}
              sx={{ width: '48%' }}
              onClick={async () => {
                setLoadingSkip(true);
                await deleteVerification(
                    currentInfo.name,
                    currentInfo.orgId,
                    currentInfo.transactionId,
                    id
                    // item?.uid
                ).then(async (res) => {
                  if (res.code === 0) {
                    mixpanel.track(trackingEvents.verification.events.deleting, {
                      category: currentCredentialUnderFetch?.category?.name,
                      name: currentCredentialUnderFetch?.name,
                      status: 'SUCCESS',
                      orgId: searchedQuery?.orgId,
                      txnId: searchedQuery?.txnId,
                      subType: id
                    });
                    if (fn) {
                      await getWallet();
                      fn();
                    } else {
                      return navigate(-1);
                    }
                    // const found = res?.data?.verificationList?.find((z) => z.name === currentInfo.name);
                    // const infoSubmitted = fetchFromStore('infoSubmitted');
                    // const xx = { ...infoSubmitted };
                    // xx[currentInfo.name] = found.request.onGrid;
                    // updateStore('infoSubmitted', xx);
                    // let tempz = xx[currentInfo.name];
                    // if (Array.isArray(xx[currentInfo.name]) &&
                    //   xx[currentInfo.name].length > 0 &&
                    //   typeof xx[currentInfo.name][0] === 'object' &&
                    //   xx[currentInfo.name][0].name &&
                    //   xx[currentInfo.name]?.[0].value) {
                    //   tempz = xx[currentInfo.name]?.reduce((acc, item, i) => {
                    //     acc[item.name] = item.value;
                    //     return acc;
                    //   }, {});
                    // }

                    // updateStore('docData', tempz);
                    // if (found?.request?.onGrid?.length <= 0) {
                    // return navigate(-1);
                    // }
                  }
                }).finally(() => {
                  setLoadingSkip(false);
                  setOpen(false);
                });
              }}>
              Delete
            </LoadingButton>
          </Grid>

        </Grid>
      </Dialog>
    </>
  );
}

export default ListDataUI;

export const defaultFormat = {
  'photo': 'image',
  'Photo': 'image',
  'Image': 'image',
  'image': 'image',
  'url': 'url',
  'address': 'address',
  'Date Of Birth': 'formattedDate',
  'Date of Birth': 'formattedDate',
  'dob': 'formattedDate',
  'dateOfBirth': 'formattedDate',
  'issueDate': 'formattedDate',
  'Expiry Date': 'formattedDate',
  'Issue Date': 'formattedDate',
  'joiningDate': 'formattedDate',
  'lastWorkingDate': 'formattedDate',
};
