
import { AccessTime, ArrowBack, InfoOutlined } from '@mui/icons-material';
import { Avatar, Button, CircularProgress, Dialog, Grid, IconButton, Link, Menu, Skeleton, Typography, useTheme } from '@mui/material';
import Lottie from 'lottie-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import bgWaveBlue from '../../../Assets/bgWaveBlue.svg';
import emptyBox from '../../../Assets/emptyBox.png';
import errorLottie from '../../../Assets/lottie/error.json';
import AnimatedCard from '../../../Components/UI/animatedCard';
import { createTransactionsProcess, getTransactionsInfo, getWallet, skipVerificationsList } from '../../../Config/api';
import { getTxHistory, getTxInfoDetails } from '../../../Config/payment/api';
import { UIStore, updateStore } from '../../../Config/store';
import { getFetchProcessInterval, updateDocumentTitle, useQuery } from '../../../Helper/helpers';
import Status from '../status';
import BasicDetails from './basicDetails';
import VerifyList from './list';

import { LoadingButton } from '@mui/lab';
import { Alert } from '@mui/material';
import { debounce } from 'lodash';
import BottomComponent from '../../../Components/UI/bottomComponent';
import { displayTime, isADateBiggerThanB } from '../../../Helper/dates';
import { triggerCancellationController } from '../../../Helper/helpers';
import usePreStartQuestionList from '../../../Helper/usePreStartQuestionList';
import { mixpanel, trackingEvents } from '../../../Monitoring/Mixpanel';
import { isFormRequirementsNotSatisfied, statesEnum, tawkToConfig } from '../../../Utils/enums';
import SkeletonFormScreen from './skeleton';

export default function Verify() {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const query = useQuery();
  const { state } = useLocation();
  const lottieRef = useRef(null);
  const { preStartQuestionComponent, onPreStartAnswersSubmission, preStartQuestionIsDisabled, preStartQuestionListLength } = usePreStartQuestionList();
  const [loading, setLoading] = React.useState(true);
  const [showError, setShowError] = React.useState(false);
  const [openStartingLoader, setOpenStartingLoader] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [openCommonErrorDialog, setOpenCommonErrorDialog] = useState(null);

  const [openStartingProcessDialog, setOpenStartingProcessDialog] = useState(false);
  const [loadingStartingProcessDialogSubmitButton, setLoadingStartingProcessDialogSubmitButton] = useState(false);
  const [enabledDocs, setEnabledDocs] = React.useState({});
  const currentUserData = UIStore.useState((s) => s.currentUserData) ?? {};
  const form = UIStore.useState((s) => s.form) ?? {};
  const formInfo = UIStore.useState((s) => s.formInfo) ?? {};
  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  const isAuthenticated = UIStore.useState((s) => s.isAuthenticated);
  const formUnderProcess = UIStore.useState((s) => s.formUnderProcess);
  const userInput = UIStore.useState((s) => s.userInput);
  const payments = UIStore.useState((s) => s.payments);

  const [txInfo, setTxInfo] = React.useState(null);
  const [openIncompleteDialog, setOpenIncompleteDialog] = React.useState(null);
  const [loadingIncompleteApiCall, setLoadingIncompleteApiCall] = React.useState(false);
  const txnId = params?.txnId || searchedQuery?.txnId;


  const createTxn = async () => {
    let temp = null;
    temp = await createTransactionsProcess(query.get('orgId'), params?.formId);
    console.log('createTxn', temp);
    if (!temp?.error) {

    } else {
      setLoading(false);
      setShowError(temp.msg);
    }
    return temp;
  };
  const getCurrentTxInfo = async (txId = txnId) => {
    const response = await getTxInfoDetails(query.get('orgId'), txId);
    if (response) {
      console.log('response 205', response);
      setTxInfo(response.data);
    }
  };

  const triggerProcessDebounced = useCallback(debounce(async (txn) => {
    console.log('process');
    await getTransactionsInfo(query.get('orgId'), txn)
        .then((x) => {
          console.log('triggerProcessDebounced', x);
          if (x?.state === statesEnum.COMPLETED) {
            clearInterval(getFetchProcessInterval());
            triggerCancellationController();
            // navigate('/submitted', { replace: true });
            return null;
          } else if (x?.error && x?.code !== 0) {
            setLoading(false);
            setShowError(x.msg);
            clearInterval(getFetchProcessInterval());
            triggerCancellationController();
          }
        });
  }, 800), []);
  const startFetchingTxnProgress = async (txn) => {
    if (txn) {
      const temp = await getTransactionsInfo(query.get('orgId'), txn)
          .then((res) => {
          // moveNext(res);
            return res;
          });
      const intrvl = setInterval(() => {
        triggerProcessDebounced(txn);
      }, Number(process.env.REACT_APP_TIMEOUT_FOR_POLLING_PROCESS));
      return {
        res: temp,
        interval: intrvl
      };
    }

    return {};
  };

  const startProgressCheck = async (txn) => {
    clearInterval(getFetchProcessInterval());
    let interval = await startFetchingTxnProgress(txn);
    interval = interval.interval;
    sessionStorage.setItem('fetchProcessInterval', interval);
    setLoading(false);
    return interval;
  };

  const refreshTransactionInfo = async () => {
    let interval = '';
    let txn = params.txnId;
    let fup = { ...formUnderProcess };
    console.log('state?.moveToNextVerification', state?.moveToNextVerification);
    if (state?.moveToNextVerification) {
      txn = searchedQuery?.txnId;
    } else {
      fup = {};
      updateStore('formUnderProcess', {});
    }

    setLoading(true);
    if (!txn) {
      txn = await createTxn();
      txn = txn?.data;
    }
    updateStore('searchedQuery', {
      link: window.location.href.split(window.location.origin)[1],
      formId: params?.formId,
      txnId: txn,
      orgId: query.get('orgId')
    });
    if (txn) {
      await getTransactionsInfo(query.get('orgId'), txn)
          .then((res) => {
            if (!res.error) {
              console.log('getTransactionsInfo: res', res);

              getCurrentTxInfo(txn);
              if (res?.state === 'IN_PROGRESS') {
                interval = startProgressCheck(txn);
              }
            }
          })
          .finally(() => {
            setLoading(false);
          });
    } else {

    }
    return interval;
  };

  useEffect(() => {
    clearInterval(getFetchProcessInterval());
    let interval = null;
    if (isAuthenticated) {
      setLoading(true);
      getWallet()
          .then((res) => {
            if (res?.code === 0) {
              interval = refreshTransactionInfo();
            }
          });
    }
    () => {
      clearInterval(getFetchProcessInterval());
    };
  }, [isAuthenticated]);

  useEffect(() => {
    updateDocumentTitle([formInfo?.data?.formName]);
  }, []);

  useEffect(() => {
    if ((form.remaining?.length <= 0 && (txInfo?.userPaymentRequired))) {
      getTxHistory({
        orgId: searchedQuery?.orgId,
        formId: searchedQuery?.formId
      })
          .then((info) => {
            if (info?.code === 0) {
              updateStore('payments', info?.data?.[0]);
            }
          }).catch(() => {
            updateStore('payments', {});
          });
    } else {
      updateStore('payments', {});
    }
  }, [searchedQuery, txInfo, form]);

  console.log('enabledDocs', enabledDocs);

  const areMinimumVerificationsComplete = () => {
    const obj = Object.keys(form?.list)?.reduce((acc, key) => {
      acc[key] = form.list?.[key].existInWallet && !form.list?.[key].markedAsSkip;
      return acc;
    }, {});
    return process.env.REACT_APP_MIN_VERIFICATIONS ? isFormRequirementsNotSatisfied(obj, Object.keys(form?.list)) : false;
  };

  const isUserInputRequired = (callback = () => { }) => {
    const userInputCompletedCheck = userInput?.additionalInfo?.authOptions[0]?.page?.config?.[0]?.inputs?.[0]?.value &&
      ![statesEnum.NOT_INITIALIZED].includes(form?.state);
    if (userInput?.metadata) {
      if (!userInputCompletedCheck) {
        setOpenCommonErrorDialog({
          message: 'Complete the Basic Details to start with the verifications',
          ok: () => {
            clearInterval(getFetchProcessInterval());
            triggerCancellationController();
            updateStore('currentWorkingStepperStep', 'user-input');
            navigate('/user-input', { state: { link: window.location.href } });
          },

        });
        callback();
        return true;
      }
    }
    return false;
  };

  const onContinueHandlerForUnpaidForms = async () => {
    if (!currentUserData?.profileDetails?.name) {
      navigate('/profile-form');
      return null;
    }
    const showUserInput = isUserInputRequired();
    if (showUserInput) {
      return null;
    }
    // if (form?.state === 'NOT_INITIALIZED') {
    //   const responseForStarting = await getTransactionsProcess();
    //   if (responseForStarting?.error) {
    //     return null;
    //   } else {
    //     startProgressCheck(searchedQuery?.txnId);
    //   }
    // }
    const minVerificationsComplete = areMinimumVerificationsComplete();

    clearInterval(getFetchProcessInterval());
    if (minVerificationsComplete) {
      setErrorDialog(minVerificationsComplete);
      return null;
    }


    const nonSkippableIncompletes = formUnderProcess?.[searchedQuery?.txnId]?.sortedList
        ?.filter(((x) => !form?.list?.[x]?.existInWallet && !formInfo?.[x].skipAllowed));

    if (nonSkippableIncompletes.length > 0) {
      setOpenIncompleteDialog({
        items: nonSkippableIncompletes,
        skippables: false
      });
      return null;
    }

    const incompletes = form?.remaining;
    if (incompletes.length > 0) {
      const skippableIncompletes = incompletes?.filter((x) => formInfo?.[x].skipAllowed);
      if (skippableIncompletes.length > 0) {
        setOpenIncompleteDialog({
          items: skippableIncompletes,
          skippables: true
        });
      } else {
        navigate('/submitted');
      }
    } else {
      navigate('/submitted');
    }
  };

  const onContinueHandlerForPaidForms = async () => {
    if (!formInfo?.data?.payment?.paymentRequired) {
      updateStore('isAutoplay', false);

      const showUserInput = isUserInputRequired();
      if (showUserInput) {
        return null;
      }

      if (formUnderProcess?.[txnId]?.done?.length > 0 &&
        (formUnderProcess?.[txnId]?.total?.length === formUnderProcess?.[txnId]?.done?.length)) {
        clearInterval(getFetchProcessInterval());
        triggerCancellationController();
        mixpanel.track(trackingEvents.form.events.select.verificationStarted, {
          txnId,
          orgId: query.get('orgId')
        });
        navigate('/submitted', { replace: true });
      } else {
        if (form?.state === 'NOT_INITIALIZED') {
          const responseForStarting = await getTransactionsProcess();
          if (responseForStarting?.error) {
            return null;
          }
        }
        setOpenStartingLoader(true);
        await getTransactionsInfo(query.get('orgId'), txnId)
            .then((res) => {
              const form2 = res;
              if (form2?.state === statesEnum.COMPLETED) {
                clearInterval(getFetchProcessInterval());
                triggerCancellationController();
                navigate('/submitted', { replace: true });
                mixpanel.track(trackingEvents.form.events.select.verificationStarted, {
                  txnId,
                  orgId: query.get('orgId')
                });
                return form2;
              } else if (form2.error && form2?.code !== 0) {
                setLoading(false);
                setShowError(form2.msg);
                clearInterval(getFetchProcessInterval());
                triggerCancellationController();
                return null;
              }
              setTimeout(async () => {
                setOpenStartingLoader(false);
                if (form2.remaining?.length > 0) {
                  const minVerificationsComplete = areMinimumVerificationsComplete();

                  clearInterval(getFetchProcessInterval());
                  if (minVerificationsComplete) {
                    setErrorDialog(minVerificationsComplete);
                    return null;
                  }

                  const nonSkippableIncompletes = form2.remaining
                      ?.filter(((x) => !(form2?.list?.[x]?.existInWallet) && !formInfo?.[x].skipAllowed));

                  console.log(
                      'form2', form2.remaining,
                      form2?.list,
                      formInfo,
                      nonSkippableIncompletes
                  );
                  if (nonSkippableIncompletes?.length > 0) {
                    setOpenIncompleteDialog({
                      items: nonSkippableIncompletes,
                      skippables: false
                    });
                    return null;
                  }

                  const skippableIncompletes = form2.remaining
                      ?.filter((x) => formInfo?.[x].skipAllowed);
                  if (skippableIncompletes.length > 0) {
                    setOpenIncompleteDialog({
                      items: skippableIncompletes,
                      skippables: true
                    });
                  }
                } else {
                  const interval = setInterval(() => {
                    triggerProcessDebounced(txnId);
                  }, Number(process.env.REACT_APP_TIMEOUT_FOR_POLLING_PROCESS));
                  sessionStorage.setItem('fetchProcessInterval', interval);
                }
              }, 1000);
            });
      }
    }
  };

  console.log('openIncompleteDialog', openIncompleteDialog);
  return loading ? <SkeletonFormScreen /> : showError ?
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ flex: 1 }}>

      <AnimatedCard lottieRef={lottieRef}>
        <Lottie
          lottieRef={lottieRef}
          animationData={errorLottie}
          loop={false}
          style={{ width: '30%' }}
          autoplay={false} />
        <Typography variant="h6">
          Error occured
        </Typography>
        <Typography variant="body2">
          {showError}
        </Typography>
        <Button
          variant="text"
          onClick={() => setShowError(false)}>
          Back
        </Button>
      </AnimatedCard>
    </Grid> : (form?.list && formUnderProcess?.[searchedQuery?.txnId]
        ?.sortedList?.length <= 0) ? <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}>
          <img src={emptyBox} style={{ width: '100px' }} />
          <Typography variant="h6">
        No verifications found in this form
          </Typography>
        </Grid> : (<Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{
            position: 'relative', height: '100%',
            flexWrap: 'nowrap',
          }}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              borderRadius: '0px 0px 36px 36px',
              padding: 0,
              //   flex: 1,
              // height: '100%',
              flexWrap: 'nowrap'
            }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: `url(${bgWaveBlue})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
              paddingBottom: '1.4em',
              borderRadius: '0px 0px 36px 36px'
            }}>

              <IconButton
                onClick={() => navigate('/')}
                sx={{ position: 'absolute', top: '0.5em', left: '0.5em' }}>
                <ArrowBack />
              </IconButton>
              {(isAuthenticated) ? <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={() => updateStore('drawer', true)}
              >
                <Menu />
              </IconButton> : null}
              {form?.orgImage ? <img
                style={{
                  textAlign: 'center',
                  height: '50px',
                  margin: '2em 0 1em'
                }} src={form.orgImage} /> : (form?.orgName ? <Avatar>
                  {form?.orgName}
                </Avatar> : <Skeleton
                  variant="circular" width={50} height={50}
                  sx={{
                    margin: '2em 0 1em'
                  }} />)}
              <Typography
                variant="h6" sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}>
                {form.orgName}
              </Typography>
              <Typography
                variant="body1" sx={{
                  textAlign: 'center',
                  color: '#666666'
                }}>
            is requesting the following documents
              </Typography>
            </div>

            {statesEnum.COMPLETED === form?.state && form?.remaining?.length <= 0 ? <Alert
              color="info"
              icon={<></>}
              sx={{
                'width': '100%',
                'margin': '1em 0em',
                'textAlign': 'center',
                'bgcolor': '#F1F7FF',
                '& .MuiAlert-message': {
                  width: '100%',
                  padding: 0,
                }
              }}>
          Congratulations! Your verification was successfully verified on
              {' '}
              <b>
                {displayTime(formInfo?.data?.verificationCompletionDate, 'DD MMMM YYYY')}
              </b>
            </Alert> :
          (([statesEnum.IN_PROGRESS, statesEnum.DATA_SAVED].includes(form.state) &&
            formInfo?.data?.verificationEstimatedCompletionDate) ? <Alert
              color="info"
              icon={<></>}
              sx={{
                'width': '100%',
                'margin': '1em 0em',
                'bgcolor': '#F1F7FF',
                '& .MuiAlert-message': {
                  width: '100%',
                  padding: 0,
                }
              }}>
              <Grid
                container direction="column"
                justifyContent="center" alignItems="center"
                sx={{ flexWrap: 'nowrap', width: '100%', padding: '1em' }}>
                <AccessTime color="primary" sx={{ fontSize: '2rem' }} />
                {
                isADateBiggerThanB(new Date(), formInfo?.data?.verificationEstimatedCompletionDate) ?
                  <Typography
                    variant="body1" sx={{
                      width: '100%',
                      overflowWrap: 'anywhere',
                      textAlign: 'center',
                    }}>
                    Oh No!
                    It seems like your verifications are taking more time, we apologize for the delay, rest assured our best people are working on it.
                    If you have any questions you can
                    {' '}
                    <span
                      style={{
                        'fontWeight': 'bold',
                        'cursor': 'pointer',
                        'textDecoration': 'underline',
                        '&:hover': {
                          color: '#094754',
                          textDecoration: 'underline'
                        }
                      }}
                      onClick={() => {
                        window?.tawkToReactElement?.maximize();
                      }}>
                      contact us
                    </span>
                  </Typography> :
                  <>
                    {' '}
                    <Typography
                      variant="body1" sx={{
                        width: '100%',
                        overflowWrap: 'anywhere',
                        textAlign: 'center',
                      }}>
                      Expected Verification completion by
                    </Typography>

                    <Typography
                      variant="body2" sx={{
                        textAlign: 'center',
                        width: '100%',
                        fontSize: '1.6rem',
                        fontWeight: 700,
                        margin: '-0.4rem',
                        padding: 0,
                        color: '#094754'
                      }}>
                      {displayTime(formInfo?.data?.verificationEstimatedCompletionDate, 'DD MMMM YYYY')}
                    </Typography>
                  </>
                }
              </Grid>
            </Alert> :
            statesEnum.CLOSED === form?.state && formInfo?.data?.verificationCompletionDate ? <Alert
              color="info"
              icon={<></>}
              sx={{
                'width': '100%',
                'margin': '1em 0em',
                'textAlign': 'center',
                'bgcolor': '#F1F7FF',
                '& .MuiAlert-message': {
                  width: '100%',
                  padding: 0,
                }
              }}>
              Your verification was closed on
              {' '}
              <b>
                {displayTime(formInfo?.data?.verificationCompletionDate, 'DD MMMM YYYY')}
              </b>
              , please
              &nbsp;
              <span
                style={{
                  'fontWeight': 'bold',
                  'cursor': 'pointer',
                  'textDecoration': 'underline',
                  '&:hover': {
                    color: '#094754',
                    textDecoration: 'underline'
                  }
                }}
                onClick={() => {
                  if (tawkToConfig(process.env.REACT_APP_ORG)) {
                  } else {
                    window.open(`mailto:${process.env.REACT_APP_ORG_MAIL}`, '_blank');
                  }
                }}>
                contact us
              </span>
              &nbsp;
              for any queries
            </Alert> :
              <Typography
                variant="body2" sx={{
                  textAlign: 'center',
                  color: '#666666'
                }}>
                Click the verifications to start the process
              </Typography>)}


            {(form.state !== 'NOT_INITIALIZED') ? <Status
              done={formUnderProcess?.[txnId]?.done?.length + (userInput?.additionalInfo?.authOptions?.[0]
                  ?.page?.config?.[0]?.inputs?.[0]?.value ? 1 : 0)}
              waiting={formUnderProcess?.[txnId]?.inProgress?.length}
              total={formUnderProcess?.[txnId]?.total + (userInput?.metadata ? 1 : 0)} /> : null}

          </Grid>
          <Grid
            container
            sx={{
              padding: '1em'
            }}>

            {(payments?.paymentList?.length > 0) ?
          <Link
            variant="body2"
            component={Typography}
            onClick={() => navigate('/form-payments')}
            sx={{ cursor: 'pointer', margin: '0 0 0 auto', fontWeight: 800 }}>
            View Transactions
          </Link> : null}
            <BasicDetails
              enabledDocs={enabledDocs} setEnabledDocs={setEnabledDocs}
              setOpenStartingProcessDialog={setOpenStartingProcessDialog} />
            <VerifyList
              enabledDocs={enabledDocs} setEnabledDocs={setEnabledDocs}
              isUserInputRequired={isUserInputRequired}
              setOpenStartingProcessDialog={setOpenStartingProcessDialog} />
          </Grid>

          {/* footer */}
          {(formInfo && form && txInfo) ? (
        <>
          <BottomComponent>
            {(form.remaining?.length <= 0 && (form.state === 'IN_PROGRESS' || form.state === statesEnum.COMPLETED)) ? <Grid
              container
              direction="column"
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              sx={{ width: '100%' }}>
              {((!txInfo?.paymentRequired && txInfo?.userPaymentRequired) &&
                txInfo?.paymentDetails?.length > 0 &&
                statesEnum.COMPLETED !== form?.state) && <Alert
                severity="info"
                sx={{
                  'width': '100%',
                  'display': 'flex',
                  'flexDirection': 'row',
                  'justifyContent': 'flex-start',
                  'alignItems': 'center',
                  '& .MuiAlert-message': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'left'
                  }
                }}>
                  Congratulations! Your payment is complete. Your verifications are under process.
              </Alert>}
              {((txInfo?.userPaymentRequired ?
                (txInfo?.paymentRequired && txInfo?.userPaymentRequired) :
                true)) && <Button
                variant="contained" color="primary"
                id={txInfo?.paymentRequired ? 'Pay' : 'Done'}
                onClick={() => {
                  const minVerificationsComplete = areMinimumVerificationsComplete();

                  clearInterval(getFetchProcessInterval());
                  if (minVerificationsComplete) {
                    setErrorDialog(minVerificationsComplete);
                    return null;
                  } else {
                    if (!txInfo?.userPaymentRequired) {
                      return navigate('/submitted');
                    } else if (txInfo?.paymentRequired) {
                      navigate('/choose-payment-region');
                    }
                    else {
                      navigate('/submitted');
                    }
                  }
                }}>
                {txInfo?.paymentRequired ? `${txInfo?.discount ? `Pay (${txInfo?.discount?.percentage}% Discount Applied)` : 'Continue to Pay'}` : 'Done'}
              </Button>}
            </Grid> : null}
          </BottomComponent>


          {
            formInfo?.data?.payment?.formAcceptsPayment ? (
              (form.remaining?.length <= 0 && ([statesEnum.IN_PROGRESS, statesEnum.COMPLETED,
                statesEnum.CLOSED
              ].includes(form.state))) ? null : <BottomComponent>
                <Button
                  variant="contained" color="primary" sx={{ width: '100%' }}
                  disabled={Object.values(enabledDocs)?.filter((x) => x !== 'DONE')?.length > 0}
                  onClick={async () => {
                    if (form?.state === 'NOT_INITIALIZED' && form?.purposeRequired) {
                      setOpenStartingProcessDialog({
                        callback: async () => {
                          return onContinueHandlerForPaidForms();
                        }
                      });
                    } else {
                      onContinueHandlerForPaidForms();
                    }
                  }}>
                  Continue
                </Button>
              </BottomComponent>
            ) : (form.remaining?.length > 0 ? <BottomComponent>
              <Button
                variant="contained" color="primary" sx={{ width: '100%' }}
                disabled={Object.values(enabledDocs)?.filter((x) => x !== 'DONE')?.length > 0}
                onClick={() => {
                  if (form?.state === 'NOT_INITIALIZED' && form?.purposeRequired) {
                    setOpenStartingProcessDialog({
                      callback: async () => {
                        return onContinueHandlerForUnpaidForms();
                      }
                    });
                  } else {
                    onContinueHandlerForUnpaidForms();
                  }
                }}
              >
                Continue
              </Button>
            </BottomComponent> : null)
          }
        </>
      ) : null}


          {/* some verificaion was selected, opening that.. */}
          <Dialog
            maxWidth="xs"
            fullWidth
            open={openStartingLoader}
            onClose={() => { }}
            sx={{ padding: '1em' }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center">
              <CircularProgress sx={{ margin: '1em auto' }} />
              {form?.exists?.length > 0 && formInfo?.data?.payment?.formAcceptsPayment ? <Typography variant="body2">
            Sharing
                {' '}
                {form?.exists?.length}
                {' '}
            credentials to
                {' '}
                {form.orgName}
              </Typography> : null}

              {form?.remaining?.length > 0 ? <Typography variant="body2">
            Starting verification of
                {' '}
                {form?.remaining?.length}
                {' remaining '}
            credentials
              </Typography> : null}
            </Grid>
          </Dialog>

          {/* minimum verifications to complete */}
          <Dialog
            maxWidth="xs"
            fullWidth
            open={errorDialog}
            onClose={() => { setErrorDialog(false); }}
            sx={{ padding: '1em' }}
          >

            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ padding: '1em' }}>
              <Typography variant="h4" sx={{ fontSize: '1.4rem' }}>
            Please complete these verifications
              </Typography>

              <Typography variant="body1">
                <ol style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}>
                  {Object.keys(errorDialog)?.map((req, i) => <li>

                Complete atleast
                    {' '}
                    {errorDialog[req]}
                    {' '}
                in&nbsp;
                    <b>
                      {req.split(',')?.map((req2) => form?.list?.[req2]?.name)?.filter((z) => z).join(', ')}
                    </b>
                  </li>)}
                </ol>

              </Typography>

            </Grid>
          </Dialog>
          {/* Notify some verifications are left */}
          {openIncompleteDialog ?
        <Dialog
          maxWidth="xs"
          fullWidth
          open={Boolean(openIncompleteDialog)}
          onClose={() => { setOpenIncompleteDialog(null); }}
          sx={{ padding: '1em', margin: 'auto' }}
        >

          <InfoOutlined color="primary" sx={{ fontSize: 40, margin: '0.4em auto 0' }} />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ padding: '0 1em 1em' }}>

            <Typography
              variant="body1"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                mb: 0
              }}>
              {openIncompleteDialog?.skippables ?
                openIncompleteDialog?.items?.length === 1 ? 'This verification is left:' : 'These verifications are left:' :
                openIncompleteDialog?.items?.length === 1 ? 'This verification is mandatory:' : 'These verifications are mandatory:'
              }
            </Typography>
            <Typography variant="body1" sx={{ mt: 0 }}>
              <ol style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}>
                {openIncompleteDialog?.items?.map((req, i) => <li>
                  {form?.list?.[req]?.name}
                  {' '}
                </li>)}
              </ol>

            </Typography>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: '1em' }}>
              <Button
                variant="outlined"
                color="brand"
                sx={{ mb: 1, width: '98%' }}
                onClick={async () => {
                  setOpenIncompleteDialog(false);
                  return navigate('/');
                }}>
                {
                  openIncompleteDialog?.items?.length === 1 ? 'Complete this Later' : 'Complete them Later'
                }
                {' '}
              </Button>
              {openIncompleteDialog?.skippables ? <LoadingButton
                variant="contained"
                color="brand"
                loading={loadingIncompleteApiCall}
                sx={{ width: '98%' }}
                onClick={async () => {
                  setLoadingIncompleteApiCall(true);
                  await skipVerificationsList(
                      searchedQuery.orgId,
                      searchedQuery.txnId,
                      openIncompleteDialog?.items
                  ).then(async (res) => {
                    if (res.code === 0) {
                      if (!formInfo?.data?.payment?.formAcceptsPayment) {
                        return navigate('/');
                      } else {
                        startProgressCheck(searchedQuery?.txnId);
                      }
                    }
                  }).finally(() => {
                    setLoadingIncompleteApiCall(false);
                    setOpenIncompleteDialog(false);
                  });
                }}>
                {
                  openIncompleteDialog?.items?.length === 1 ? 'Skip this' : 'Skip them'
                }
              </LoadingButton> : <Button
                variant="outlined"
                color="brand"
                sx={{ width: '98%' }}
                onClick={async () => {
                  setOpenIncompleteDialog(null);
                }}>
                Cancel
              </Button>}
            </Grid>

          </Grid>
        </Dialog> : null}

          {/* common error */}
          {openCommonErrorDialog ?
        <Dialog
          maxWidth="xs"
          fullWidth
          open={Boolean(openCommonErrorDialog)}
          onClose={() => { setOpenCommonErrorDialog(null); }}
          sx={{ padding: '1em', margin: 'auto' }}
        >

          <InfoOutlined color="primary" sx={{ fontSize: 40, margin: '0.4em auto 0' }} />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ padding: '0 1em 1em' }}>

            <Typography
              variant="body1"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%'
              }}>
              {
                openCommonErrorDialog?.message
              }
            </Typography>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: '1em' }}>
              <Button
                variant="outlined"
                color="brand"
                sx={{ width: openCommonErrorDialog.ok ? '45%' : 'auto' }}
                onClick={async () => {
                  setOpenCommonErrorDialog(null);
                }}>
                Cancel
              </Button>
              {openCommonErrorDialog.ok ? <LoadingButton
                variant="contained"
                color="brand"
                sx={{ width: openCommonErrorDialog.ok ? '45%' : 'auto' }}
                onClick={async () => {
                  openCommonErrorDialog.ok();
                  setOpenCommonErrorDialog(false);
                }}>
                Ok
              </LoadingButton> : null}
            </Grid>

          </Grid>
        </Dialog> : null}

          {/* Click Start to start filling the form */}
          {openStartingProcessDialog ?
        <Dialog
          maxWidth="xs"
          fullWidth
          open={Boolean(openStartingProcessDialog)}
          onClose={() => { setOpenStartingProcessDialog(null); }}
          sx={{ padding: '1em', margin: 'auto' }}
        >

          <InfoOutlined color="primary" sx={{ fontSize: 40, margin: '0.4em auto 0' }} />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ padding: '0 1em 1em' }}>

            {preStartQuestionListLength <= 0 ? <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                mb: 0
              }}>
              Click
              {' '}
              <b>
                Start
              </b>
              {' '}
              to start filling the form
            </Typography> : preStartQuestionComponent}

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: '1em' }}>
              <Button
                variant="outlined"
                color="brand"
                sx={{ width: '48%' }}
                onClick={async () => {
                  setOpenStartingProcessDialog(false);
                }}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                color="brand"
                id="start"
                sx={{ width: '48%' }}
                loading={loadingStartingProcessDialogSubmitButton}
                disabled={preStartQuestionListLength > 0 ? preStartQuestionIsDisabled : null}
                onClick={async () => {
                  setLoadingStartingProcessDialogSubmitButton(true);
                  const res = preStartQuestionListLength > 0 ? await onPreStartAnswersSubmission() : true;
                  if (res) {
                    // await refreshTransactionInfo();
                    await getTransactionsInfo(query.get('orgId'), txnId);
                    setOpenStartingProcessDialog(false);
                    await openStartingProcessDialog.callback();
                  }
                  setLoadingStartingProcessDialogSubmitButton(false);
                }}>
                Start
              </LoadingButton>
            </Grid>

          </Grid>
        </Dialog> : null}
        </Grid >);
}

