import { Card, Grid, IconButton, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UIStore } from '../../Config/store';
import { useIsDesktop } from '../../Helper/hooks';


import { AccessTime } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { Chip } from '@mui/material';
import SimpleBackground from '../../Components/Background/Simple';
import { displayTime } from '../../Helper/dates';
import { paymentProvider, states } from '../../Utils/enums';

export default function FormPayments({ data }) {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const lottieRef = useRef(null);
  const form = UIStore.useState((s) => s.form);
  const payments = UIStore.useState((s) => s.payments);
  const searchedQuery = UIStore.useState((s) => s.searchedQuery);
  const [loading, setLoading] = useState(null);


  useEffect(() => {
    // setLoading(true);

  }, []);


  return <SimpleBackground
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap',
      height: '100%',
      width: '100%',
      padding: '0px',
      paddingBottom: '1em',
      margin: 0
    }}
    title={'Invoice'}>
    {loading ?
      <Grid
        container
        direction={'column'}
        sx={{ marginTop: '1em' }}>
        {[1, 2, 3, 4]?.map((s) => <Skeleton
          variant="rectangular"
          sx={{ width: '100%', marginBottom: '2em', borderRadius: '8px' }}
          height={140} />)}
      </Grid> :
      payments?.paymentList?.map((pay, index) => <TransactionCard
        formData={{ org: payments?.org, form: payments?.form }}
        data={pay} key={`transaction-card-${index}`} />)
    }
  </SimpleBackground>;
}


const TransactionCard = ({ formData, data }) => {
  return (
    <Card
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '8px',
        width: '100%',
        margin: '1em',
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/payment?transactionHistory=true`, { state: { order: { ...data, ...formData } } })}
    >
      <Grid
        item xs={2} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {paymentProvider[data?.provider]?.logo}
      </Grid>

      <Grid item xs>
        <Typography variant="h4" component="b">
          {paymentProvider[data?.provider]?.name
          }
        </Typography>
        <Typography variant="h5" component="div" sx={{ marginTop: '0.2rem' }}>
          {`${data?.price?.toPay
          } ${data?.price?.currencySymbol}`}
        </Typography>
        <Typography
          variant="body2" color="textSecondary"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <AccessTime sx={{ fontSize: '1.8rem' }} />
          &nbsp;
          Date:
          {' '}
          {displayTime(data?.updatedAt, 'DD-MMM-YYYY hh:mm:A')}
        </Typography>


        <Chip
          color={states[data?.currentStatus?.toUpperCase()]?.theme}
          variant="outline"
          sx={{
            'border': 'none',
            'marginTop': '0.2em',
            '& .MuiChip-label': { fontSize: '1rem', fontWeight: 'bold', color: '#ffffff' }
          }}
          size="small"
          icon={states[data?.currentStatus?.toUpperCase()]?.icon}
          label={states[data?.currentStatus?.toUpperCase()]?.title} />


        {data?.refundDetails?.length > 0 ? <Chip
          color={states[data?.currentStatus?.toUpperCase()]?.theme}
          variant="outlined"
          sx={{
            'marginLeft': '1em',
            'marginTop': '0.2em',
            '& .MuiChip-label': { fontSize: '1rem', fontWeight: 'bold' }
          }}
          size="small"
          label={`${data?.refundDetails?.length} refund${data?.refundDetails?.length > 1 ? 's' : ''}`} /> :
          null}


      </Grid>

      {data?.invoiceUrl ? <Grid item xs={1} sx={{ textAlign: 'right' }}>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(data?.invoiceUrl, '_blank');
          }}
          color="primary">
          <DownloadIcon sx={{ alignSelf: 'flex-end', ml: 'auto', cursor: 'pointer' }} />
        </IconButton>
      </Grid> : null}
    </Card>
  );
};

