/* eslint-disable no-eval */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Checkbox, Collapse, FormControlLabel, FormHelperText, IconButton, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import FileInput from '../../../Components/FileUpload/index.js';
import CustomTextField from '../../../Components/UI/Input/customTextField.js';
import Select from '../../../Components/UI/Input/select.js';
import SearchAutocomplete from '../../../Components/UI/autoComplete.js';
import BlockTextfield from '../../../Components/UI/blockTextfield.js';
import MarkdownWrapper from '../../../Components/UI/markdownWrapper.js';
import { getAxiosHeader } from '../../../Config/api.js';
import { axiosInstanceWithoutBaseUrl } from '../../../Config/axios.js';
import { apiEndpoints } from '../../../Config/routes.js';
import { displayTime } from '../../../Helper/dates.js';
import { predefinedOptionsStore } from '../../../Utils/enums.js';

export const ConstructFormInputs = ({ input, j, indexOfRecordSet, updateField, setError, error, progress, recordsSet, disabled, edit, currentCredentialUnderFetch }) => {
  console.log('recordsSet[indexOfRecordSet]2:', recordsSet, input, edit, currentCredentialUnderFetch);
  const [skip, setSkip] = useState(false);
  const [temp, setTemp] = useState('');
  const dropdownOptions = input?.optionsKey ? predefinedOptionsStore[input?.optionsKey] : (input?.options?.length > 0 ?
    (input?.label === 'Degree' ?
      input?.options?.map((r) => ({ value: r?.value, name: r?.name === 'Graduate' ? `Bachelor's` : r?.name })) :
      input?.options) :
    []);

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const fn = () => {
    switch (input.inputType) {
      case 'file': return (
        <>
          <FileInput
            id={`${indexOfRecordSet}-${input?.fieldName}`}
            input={input}
            onChange={(a, b) => {
              updateField(a, {
                target: {
                  value: b
                }
              }, indexOfRecordSet);
            }}
            error={error}
            progress={progress}
            data={recordsSet}
            defaultHelperText={typeof recordsSet?.[input?.fieldName] === 'string' ? recordsSet?.[input?.fieldName] : ''} />
          {input.checkText ? <FormControlLabel
            control={<Checkbox
              checked={skip}
              onChange={() => setSkip(!skip)} />} label={input.checkText} /> : null}
        </>);
      case 'select': return (
        <>
          <Select
            id={`${indexOfRecordSet}-${input?.fieldName}`}
            required={input.required}
            label={input.label}
            name={input.fieldName}
            disabled={disabled}
            options={dropdownOptions}
            onChange={(event) => {
              setError((jj) => {
                const tempErr = [...jj];
                delete tempErr[indexOfRecordSet]?.[input.fieldName];
                return tempErr;
              });
              updateField(input.fieldName, {
                target: {
                  value: event
                }
              }, indexOfRecordSet);
            }}
            sx={{ bgcolor: 'transparent' }}
            type={input?.type}
            defaultValue={''}
            value={recordsSet?.[input.fieldName] || ''}
            InputLabelProps={{ shrink: true }}
            helperText={(input?.helperText && !error?.[input.fieldName]?.message) ? input?.helperText : error?.[input.fieldName]?.message || ''}
            error={Boolean(error?.[input.fieldName]?.message || false) || false}
            fullWidth
            autoFocus={j === 0}
            FormHelperTextProps={{
              sx: {
                marginTop: '0em'
              }
            }} />
          {input.checkText ? <FormControlLabel
            control={<Checkbox
              checked={skip}
              onChange={() => setSkip(!skip)} />} label={input.checkText} /> : null}
        </>
      );
      case 'block': return (<>
        <BlockTextfield
          id={`${indexOfRecordSet}-${input?.fieldName}`}
          input={input}
          updateField={(qw) => updateField(input.fieldName, {
            target: {
              value: qw
            }
          }, indexOfRecordSet, input?.upperCase)}
          value={recordsSet?.[input.fieldName] || ''}
          error={error} />
        {input.checkText ? <FormControlLabel
          control={<Checkbox
            defaultChecked
            checked={skip}
            onChange={() => setSkip(!skip)} />} label={input.checkText} /> : null}
      </>);
      case 'autocomplete': return (
        <>
          <SearchAutocomplete
            required={input.required}
            id={`${indexOfRecordSet}-${input?.fieldName}`}
            sx={{ width: '100%', margin: '0em 0 0 0' }}
            label={`${input.label}`}
            name={`${input.label}`}
            selectedKey={temp}
            updateSelectedKey={(event) => {
              console.log('updateSelectedKey:', event);
              setError((jj) => {
                const tempErr = [...jj];
                delete tempErr[indexOfRecordSet]?.[input.fieldName];
                return tempErr;
              });
              setTemp(event?.value);
              if (event?.value !== 'Other') {
                updateField(input.fieldName, {
                  target: {
                    value: event?.value || ''
                  }
                }, indexOfRecordSet);
              } else {
                updateField(input.fieldName, {
                  target: {
                    value: ''
                  }
                }, indexOfRecordSet);
              }
            }}
            apiTrigger={async (searchThis = '', newSeedValue = 0) => {
              try {
                if (input?.options) {
                  return { list: input?.options, total: input?.options?.length };
                }
                const response = await axiosInstanceWithoutBaseUrl(`${input?.fetcher?.api?.endpointBaseUrl ?
                  apiEndpoints[input?.fetcher?.api?.endpointBaseUrl](currentCredentialUnderFetch.key) :
                  input?.fetcher?.api?.endpoint}?seed=${newSeedValue}&count=50&sort=name&order=1&search=${searchThis}`, {
                  method: input?.fetcher?.method?.toUpperCase(),
                  data: {},
                  ...getAxiosHeader({})
                });
                // const response = {
                //   data: {
                //     'code': 0,
                //     'message': 'Success',
                //     'data': {
                //       'total': '1',
                //       'list': [
                //         {
                //           '_id': '64e854cbfb360881c894a0d1',
                //           'name': 'University of Petroleum and Energy Studies',
                //           'onGridData': {
                //             'name': 'University of Petroleum and Energy Studies',
                //             'pricing': {
                //               'price': '10000',
                //               'urgent': false,
                //               'periodStart': 0,
                //               'periodEnd': 1644940800,
                //               'currency': 'INR'
                //             }
                //           },
                //           'type': 'university',
                //           'state': 'Uttarakhand',
                //           'ownership': 'private',
                //           'domains': []
                //         }
                //       ]
                //     }
                //   }
                // };
                let bodyForPatch = eval(input?.fetcher?.api?.body);
                bodyForPatch = bodyForPatch(response);

                return { ...bodyForPatch };
              } catch (e) {
                console.log(e);
                return { list: [{ name: 'Other', value: 'Other' }], total: 0 };
              }
            }}
            getName={(option) => {
              return ({ primary: option.name });
            }
            }
            initialText={temp === 'Other' ? 'Other' : recordsSet?.[input.fieldName] || 'Select an option'}
            error={Boolean(error?.[input.fieldName]?.message || false) || false}
            other={{ name: 'Other', value: 'Other' }}
            FormControlLabel={'aa'}
          />
          <Collapse
            in={temp === 'Other'} sx={{
              'width': '100%',
              '& .MuiCollapse-wrapperInner': {
                display: 'flex !important',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              },
              'margin': '0em 0 1em 0'
            }}>
            <CustomTextField
              variant={'outlined'}
              type={'text'}
              sx={{ margin: '0em auto', width: '90%' }}
              inputProps={{
                style: { paddingRight: '16px' },
              }}
              label={`Enter other ${input.label}`}
              value={recordsSet?.[input.fieldName] || ''}
              onChange={(event) => {
                event.target.value = event.target.value.trimStart();
                if (event.target.value != ' ') {
                  setError((jj) => {
                    const tempErr = [...jj];
                    delete tempErr[indexOfRecordSet]?.[input.fieldName];
                    return tempErr;
                  });
                  updateField(input.fieldName, event, indexOfRecordSet, input?.upperCase);
                }
              }}
              InputLabelProps={{ shrink: true }}
              error={Boolean(error?.[input.fieldName]?.message || false) || false}
            />
          </Collapse>

          {error?.[input.fieldName]?.message ?
            <FormHelperText
              error={error?.[input.fieldName]?.message}
              sx={{ textAlign: 'left', margin: '-1.5em 14px 1em 14px' }}>
              {error?.[input.fieldName]?.message || ''}
            </FormHelperText> : null}
          {input.checkText ? <FormControlLabel
            control={<Checkbox
              checked={skip}
              onChange={() => setSkip(!skip)} />} label={input.checkText} /> : null}
        </>
      );
      default: return (<>
        <CustomTextField
          id={`${indexOfRecordSet}-${input?.fieldName}`}
          fullWidth
          required={input.required}
          autoFocus={j === 0}
          variant={(disabled || skip) ? 'filled' : 'outlined'}
          disabled={disabled || skip}
          type={input?.inputType === 'password' ? (passwordVisibility ? 'text' : 'password') : (disabled ? 'text' : input?.inputType)}
          sx={{ margin: '0em 0' }}
          inputProps={{
            style: { paddingRight: '16px' },
            ...(input.inputType === 'date' ?
              { max: displayTime(new Date(), 'YYYY-MM-DD') } : {})
          }}
          label={`${input.label}`}
          placeholder={input.placeholder || ''}
          value={recordsSet?.[input.fieldName] ?
            (input.inputType === 'date' && (disabled || skip) ?
              displayTime(recordsSet?.[input.fieldName], 'YYYY-MMM-DD') :
              recordsSet?.[input.fieldName]) :
            ''}
          onChange={(event) => {
            event.target.value = event.target.value.trimStart();
            console.log('recordsSet::::', event.target.value);
            setError((jj) => {
              const tempErr = [...jj];
              delete tempErr[indexOfRecordSet]?.[input.fieldName];
              return tempErr;
            });
            updateField(input.fieldName, event, indexOfRecordSet, input?.upperCase);
          }}
          onClick={(e) => {
            if (!process.env.REACT_APP_TEST && input.inputType === 'date' && e?.target?.showPicker) {
              e.stopPropagation();
              e?.target?.showPicker();
            }
          }} //
          InputLabelProps={{ shrink: true }}
          helperText={(input?.helperText && !error?.[input.fieldName]?.message) ? <MarkdownWrapper>
            {input?.helperText}
          </MarkdownWrapper> : error?.[input.fieldName]?.message || ''}
          FormHelperTextProps={{
            sx: {
            }
          }}
          error={Boolean(error?.[input.fieldName]?.message || false) || false}
          InputProps={{
            endAdornment: input?.inputType === 'password' ?
              <InputAdornment
                position="end" sx={{
                  padding: 0,
                  margin: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <IconButton
                  onClick={() => setPasswordVisibility(!passwordVisibility)}
                >
                  {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                </IconButton>

              </InputAdornment> : null
          }}
        />
        {input.checkText ? <FormControlLabel
          control={<Checkbox
            defaultChecked
            checked={skip}
            onChange={(e) => {
              setSkip(!skip);
              updateField(input.fieldName, e, indexOfRecordSet, false, true);
            }} />} label={input.checkText} /> : null}
      </>);
    }
  };
  return fn();
};
