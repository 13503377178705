import { ArrowBack } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import Tabs from '../../Components/UI/tabs';
import Faq from './faq';
import Instructions from './instructions';

export default function Information() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ padding: '1em' }}
    >
      <Typography
        variant="body1"
        sx={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content',
          margin: '0.5em',
          marginRight: 'auto',
        }}
        onClick={() => {
          navigate(`/${window.location.search}`);
        }}>
        <ArrowBack />
        <strong>
          {' '}
          Back
        </strong>
      </Typography>
      <Tabs
        tabHeaders={['Instructions', 'FAQs']}
        tabsContent={[<Instructions />, <Faq />]}
      />
    </Grid>
  );
}
