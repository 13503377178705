/* eslint-disable */
import { ExpandMore, InfoOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, Dialog, Grid, List, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SimpleBackground from '../../Components/Background/Simple';
import { pathnameToText } from '../../Components/Header/data';
import ListDataUI from '../../Components/ListDataUI';
import BottomComponent from '../../Components/UI/bottomComponent';
import { getVerificationFromProfile, skipCredInTxn } from '../../Config/api';
import { specificInfoForTitles } from '../../Config/dataUi';
import { UIStore } from '../../Config/store';
import theme from '../../Config/theme';
import { nameValueArrayToJson, updateDocumentTitle } from '../../Helper/helpers';
import { getFormattedUiForAnAttribute, ignoreKeysToDisplayCommon, states, statesEnum } from '../../Utils/enums';
import Insufficiency from '../DocRequest/insufficiency';

const ignoreKeys = ignoreKeysToDisplayCommon;
export default function ReadOnlyData({ fn = () => { } }) {
  const navigate = useNavigate();
  const { state } = useLocation();

  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const [dataToShow, setDataToShow] = useState(null);

  const form = UIStore.useState((s) => s.form);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const formUnderProcess = UIStore.useState((s) => s.formUnderProcess);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];
  const docData = UIStore.useState((s) => s.docData) ?? {};
  const configs = UIStore.useState((s) => s.configs) ?? {};
  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  const [openSkipDialog, setOpenSkipDialog] = useState(null);
  const [loadingSkip, setLoadingSkip] = useState(false);
  const [skipWarning, setSkipWarning] = useState(null);

  useEffect(() => {
    updateDocumentTitle([currentCredentialUnderFetch?.name, pathnameToText(location.pathname, searchedQuery?.link)]);
  }, [])


  const skipTrigger = () => {
    let total = 0
    const totalVerifications = formUnderProcess?.[searchedQuery?.txnId]?.sortedList;
    const skips = Object.keys(formInfo)?.reduce((acc, key) => {
      if (formInfo[key]?.skipAllowed) {
        acc.skippables.push(form?.list?.[key]?.name);
      }
      if (formInfo[key]?.markedAsSkip) {
        acc.skipped.push(key);
      }
      return acc;
    }, { skippables: [], skipped: [] })
    if (totalVerifications?.length === (skips.skipped?.length + 1)) {
      setSkipWarning(skips.skippables?.join(', '))
    } else {
      setSkipWarning(null)
    }
    setOpenSkipDialog(true);
  };

  useEffect(() => {
    getVerificationFromProfile(currentCredentialUnderFetch)
      .then((x) => {
        if (x) {
          setDataToShow(x[0]);
        }
      });

  }, [currentCredentialUnderFetch]);


  useEffect(() => {
    if (!currentCredentialUnderFetch?.key) {
      navigate((typeof searchedQuery === 'object' && searchedQuery?.link) ? searchedQuery?.link : '/')
    }
  }, [searchedQuery, currentCredentialUnderFetch]);

  console.log(" docData?.data", docData)

  return <SimpleBackground
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap',
      height: '100%',
      width: '100%',
      padding: '0',
      margin: 0
    }}
    title={currentCredentialUnderFetch?.name}>

    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{
        padding: '0em 1em', height: '100%',
        flexWrap: 'nowrap'
      }}>
      {/* {state?.state !== 'NOT_INITIALIZED' && (docData?.data[0]?.request?.data?.length > 0 
      || dataToShow) ? <Typography
        variant="h6" sx={{
          margin: '1em 0',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        Data saved on the form
      </Typography> : null} */}
      <List dense style={{ width: '100%' }}>

        {state?.state !== 'NOT_INITIALIZED' ? docData?.data?.map((res, i) => <React.Fragment
          key={`read-data-${i}`}>
          <Card sx={{
            border: 'none',
            borderRadius: '8px',
            boxShadow: 'none',
            marginBottom: '1em !important',
          }}>




            {(res?.request?.data && Object.keys(res?.request?.data).length > 0) ?
              (specificInfoForTitles(res?.request?.data, currentCredentialUnderFetch.key) ?
                <Accordion
                  disableGutters
                  sx={{
                    // 'border': `1px solid ${theme.palette.grey.main}`,
                    'padding': '0em',
                    'outline': 'none',
                    'borderRadius': '8px',
                    // 'boxShadow': '1px 4px 10px 0px #ADADAD29',
                    'marginBottom': '1em !important',
                    '&:before': { height: '0px' },
                    'boxShadow': 'none'
                  }}
                  defaultExpanded={(formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum?.INSUFFICIENT_DATA ||
                    formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum?.ACTION_REQUIRED) ?
                    res.state === statesEnum.INSUFFICIENT_DATA
                    : i === 0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      boxShadow: 'none'
                    }}
                  >
                    <Grid sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '100%',
                      padding: 0
                    }}>

                      <Avatar style={{
                        // border: '1px solid #E7EDF6',
                        backgroundColor: '#F6F6F6',
                        height: '30px',
                        width: '30px', margin: '0 1em'
                      }}>
                        <img
                          src={currentCredentialUnderFetch.icon} style={{
                            minHeight: '15px',
                            maxHeight: '25px', margin: '0', objectFit: 'contain'
                          }} alt="logo" />
                      </Avatar>
                      <Typography
                        variant="h6">
                        {specificInfoForTitles(res?.request?.data, currentCredentialUnderFetch.key)}
                      </Typography>

                      <Typography
                        variant="body2" color={states[res?.state]?.color}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 'auto'
                        }}>
                        {states[res?.state]?.icon}
                        &nbsp;
                        {states[res?.state]?.title}
                      </Typography>

                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    <ListDataUI
                      fn={fn}
                      info={res?.request?.data}
                      currentInfo={{
                        name: currentCredentialUnderFetch?.key,
                        orgId: searchedQuery?.orgId,
                        transactionId: searchedQuery?.txnId,
                        stateInProfile: dataToShow?.state,
                        stateInForm: res?.state,
                        disableEditDelete: !["NOT_INTIALIZED", "DATA_SAVED"]?.includes(formInfo[currentCredentialUnderFetch?.key]?.state)
                      }}
                      id={res?.subType} />
                  </AccordionDetails>
                </Accordion> :
                <>
                  {res.state ? <Typography
                    variant="body2" color={states[res.state]?.color}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: 'auto',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      bgcolor: theme.palette?.[states?.[res?.state]?.palette]?.light,
                      width: '100%',
                      margin: 0,
                      padding: '1em',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      fontWeight: 400,
                    }}>
                    {states[res.state]?.icon}
                    &nbsp;
                    {states[res.state]?.title}
                  </Typography> : null}
                  <ListDataUI
                    info={res?.request?.data}
                    currentInfo={{
                      name: currentCredentialUnderFetch?.key,
                      orgId: searchedQuery?.orgId,
                      transactionId: searchedQuery?.txnId,
                      stateInProfile: dataToShow?.state,
                      stateInForm: res?.state,
                      disableEditDelete: !["NOT_INTIALIZED", "DATA_SAVED"]?.includes(formInfo[currentCredentialUnderFetch?.key]?.state)
                    }}
                    id={res?.subType} />
                </>
              ) : null}

            {res?.responseList?.length > 0 ?
              (res?.responseList?.map((item, i) => <>
                {item.presentation?.length > 0 ?
                  <Card
                    key={`presentation-${i}`}
                    sx={{
                      border: 'none',
                      borderRadius: '8px',
                      boxShadow: 'none',
                      marginBottom: '1em !important',
                    }}>
                    {res.state ? <Typography
                      variant="body2" color={states[res.state]?.color}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        bgcolor: theme.palette?.[states?.[res?.state]?.palette]?.light,
                        width: '100%',
                        margin: 0,
                        padding: '1em',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        fontWeight: 400,
                      }}>
                      {states[res.state]?.icon}
                      &nbsp;
                      {states[res.state]?.title}
                    </Typography> : null}
                    <ListDataUI
                      info={nameValueArrayToJson(item?.presentation)}
                      customUi={
                        getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] ?
                          getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] :
                          {}
                      }
                      currentInfo={{
                        name: currentCredentialUnderFetch?.key,
                        orgId: searchedQuery?.orgId,
                        transactionId: searchedQuery?.txnId,
                        stateInProfile: dataToShow?.state,
                        stateInForm: res?.state,
                        disableEditDelete: formInfo[currentCredentialUnderFetch?.key]?.state !== statesEnum.NOT_INITIALIZED || formInfo[currentCredentialUnderFetch?.key]?.state !== statesEnum.DATA_SAVED

                      }}
                      id={res.subType} />


                  </Card> : null}

                {item?.data ?
                  getFormattedUiForAnAttribute?.[currentCredentialUnderFetch.key]?.MAIN(item?.data)
                  // <ListDataUI
                  // info={item?.data}
                  // customUi={
                  //   getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] ?
                  //     getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] :
                  //     {}
                  // }
                  // currentInfo={{
                  //   name: currentCredentialUnderFetch?.key,
                  //   orgId: searchedQuery?.orgId,
                  //   transactionId: searchedQuery?.txnId,
                  //   disableEditDelete: formInfo[currentCredentialUnderFetch?.key]?.state !== statesEnum.NOT_INITIALIZED || formInfo[currentCredentialUnderFetch?.key]?.state !== statesEnum.DATA_SAVED

                  // }}
                  // id={''} />
                  : null}

              </>)) : null}


            <Insufficiency
              // index={i}
              bgv={currentCredentialUnderFetch?.name}
              // key={`InsufficiencyCard-${i}`}
              // insufsFiltered={insufsFiltered}
              responseList={res?.responseList}
              // insufficiencyData={item.insufficiencyData}
              insufficiencyDataList={res?.request?.insufficiencyDataList}
              id={res?.request?.id}
              subType={res?.subType}
            />

          </Card>

        </React.Fragment>) : (dataToShow ?
          <Card
            sx={{
              border: 'none',
              borderRadius: '8px',
              boxShadow: 'none',
              marginBottom: '1em !important',
            }}>
            {state?.state ? <Typography
              variant="body2" color={states[state?.state]?.color}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: theme.palette?.[states?.[res?.state]?.palette]?.light,
                width: '100%',
                margin: 0,
                padding: '1em',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                fontWeight: 400,
              }}>
              {states[state?.state]?.icon}
              &nbsp;
              {states[state?.state]?.title}
            </Typography> : null}
            <ListDataUI
              info={nameValueArrayToJson(configs?.credentials?.[currentCredentialUnderFetch.key])}
              customUi={
                getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] ?
                  getFormattedUiForAnAttribute[currentCredentialUnderFetch.key] :
                  {}
              }
              currentInfo={{
                name: currentCredentialUnderFetch?.key,
                orgId: searchedQuery?.orgId,
                transactionId: searchedQuery?.txnId,
                stateInProfile: dataToShow?.state,
                stateInForm: state?.state,
                disableEditDelete: formInfo[currentCredentialUnderFetch?.key]?.state !== statesEnum.NOT_INITIALIZED || formInfo[currentCredentialUnderFetch?.key]?.state !== statesEnum.DATA_SAVED

              }} />


          </Card> : null)}
      </List>



      {![form?.state].includes(statesEnum.CLOSED)
        && currentCredentialUnderFetch?.data?.acceptMultipleSubmissions &&
        currentCredentialUnderFetch?.key?.includes('bgv-') ?
        <Button
          variant="outlined"
          sx={{ margin: '1em 0' }}
          onClick={() => {
            navigate('/docs', {
              state: {
                openThisFlow: true,
                subTypes: docData?.data?.reduce((acc, x) => {
                  acc[x.subType] = x.request.data.documentList?.find(((x) => x.label === 'Marksheet'))?.url;
                  return acc;
                }, {})
              }
            });
          }}>
          Add More
        </Button> : null}
      <BottomComponent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: '100%', margin: '0 8px',
          }}>

          {formInfo[currentCredentialUnderFetch?.key]?.skipAllowed && !formInfo[currentCredentialUnderFetch?.key].markedAsSkip
            && (
              formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum.NOT_INITIALIZED ||
              formInfo[currentCredentialUnderFetch?.key]?.state === statesEnum.DATA_SAVED
            ) ? <Button
              variant="text"
              color="brand"
              // loading={loadingSkip}
              sx={{ width: '100%' }}
              onClick={async () => {
                skipTrigger()
              }}>
            Skip
          </Button> : null}
          <Button
            variant="outlined" color="primary"
            id="back-button"
            sx={{ width: '96%', margin: 'auto' }}
            onClick={async () => {
              navigate(-1);
            }}>
            Back
          </Button>
        </Grid>
      </BottomComponent>

    </Grid >
    <Dialog
      maxWidth="xs"
      fullWidth
      open={openSkipDialog}
      onClose={() => { setOpenSkipDialog(null); }}
      sx={{ padding: '1em' }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: '1em' }}>

        <Typography
          variant="body1"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}>
          <InfoOutlined color="primary" sx={{ fontSize: 40, marginBottom: '0.4em' }} />
          {skipWarning ? <span>{'Please complete atleast 1 of the following: '}
            <br />
            <b>{skipWarning}</b></span>
            : 'I declare that I would like to skip the verification for ' +
            currentCredentialUnderFetch?.name}
        </Typography>

        {skipWarning ? <Button
          variant="outlined"
          color="brand"
          loading={loadingSkip}
          sx={{ width: '100%' }}
          onClick={async () => {
            setOpenSkipDialog(false);
            setTimeout(() => {
              setSkipWarning(null)
            }, 400);
          }}>
          Okay
        </Button> : <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: '1em' }}>
          <Button
            variant="outlined"
            color="brand"
            loading={loadingSkip}
            sx={{ width: '48%' }}
            onClick={async () => {
              setOpenSkipDialog(false);
            }}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="brand"
            loading={loadingSkip}
            sx={{ width: '48%' }}
            datacy="submit"
            onClick={async () => {
              setLoadingSkip(true);
              await skipCredInTxn(searchedQuery?.orgId, searchedQuery?.txnId, currentCredentialUnderFetch?.key)
                .then((zz) => {
                  if (zz) {
                    // skipThese(openSkipDialog);
                    setLoadingSkip(false);
                    setOpenSkipDialog(false);
                    navigate(searchedQuery?.link);
                    // window.history.back();
                    // navigateToForm();
                  }
                });
              setLoadingSkip(false);
              setOpenSkipDialog(false);
            }}>
            Submit
          </LoadingButton>
        </Grid>}


      </Grid>
    </Dialog>
  </SimpleBackground>;
}


