import { displayTime } from '../Helper/dates';
import { callTheCallbackIfNotNull } from '../Helper/helpers';

export const dataUi = (info, config) => {
  const infoRecieved = { ...info };
  if (infoRecieved?.data?.responseList?.filter((d) => d.presentation).length > 0) {
    const x = infoRecieved?.data?.responseList?.map((z) => ({
      ...z,
      presentation: z?.presentation?.reduce((acc, atr, i) => {
        acc[atr.name] = atr.value;
        return acc;
      }, {})
    }));
    infoRecieved.data.responseList = x;
  }
  if (infoRecieved?.data?.responseList?.filter((d) => d.request).length > 0) {
    infoRecieved.data.request = {
      ...infoRecieved?.data.request,
    };
  }

  switch (config?.key) {
    case 'aadhaar': return ({
      header: {
        title: 'Unique Identification Authority of India',
        subTitle: `Verified on : ${displayTime(new Date())}`,
        icon: config?.icon
      },
      ...infoRecieved?.data
    });
    default: return ({
      ...infoRecieved?.data
    });
  }
};

export const specificInfoForTitles = (a, b) => {
  switch (b) {
    case 'bgv-degree':
      return callTheCallbackIfNotNull(a?.level, () => a?.level?.replaceAll('_', ' '));
    case 'bgv-employment':
      return a?.employerName;
    default: return null;
  }
};

export const fileInputConfig = {
  'label': 'Upload Marksheet',
  'required': true,
  'placeholder': '',
  'minLength': 2,
  'maxLength': 200,
  'inputType': 'file',
  'helperText': 'Upload Education Marksheet of all semesters in one PDF file',
  'fileTypeAccept': 'application/pdf',
  'validation': {
    'fn': '(e) => { return e?.length >= 1; }',
    'message': 'Upload Education Marksheet of all semesters in one PDF file'
  },
  'fieldName': 'Marksheet2',
  'visibility': '(e) => { return e?.marksheetRequired; }'
};
