import { Card } from '@mui/material';
import { animate } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import BottomComponent from '../../Components/UI/bottomComponent';
import { useIsDesktop } from '../../Helper/hooks';

export default function AnimatedCard({ data, lottieRef, children }) {
  const isDesktop = useIsDesktop();
  const detailsRef = useRef(null);

  useEffect(() => {
    // getTransactionsProcess(searchedQuery?.orgId, searchedQuery?.txnId).then((x) => {
    //   if (x) {
    //     setTimeout(() => {
    animate(detailsRef?.current, { scale: [0, 1] }, { duration: 0.2 });
    setTimeout(() => {
      return lottieRef?.current ? lottieRef?.current?.play() : null;
    }, 100);
    //     }, 1000);
    //   }
    // });
  }, [lottieRef]);

  return (
    <div style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    }}>
      <Card
        ref={detailsRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // backgroundImage: showDetails ? 'none' : `url(${submittedWaveBackground})`,
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover',
          width: '90%',
          // flex: 1,
          border: 'none',
          // ...(showDetails ? {} : {
          // minHeight: isDesktop ? '50%' : '240px',
          // height: !isDesktop ? '100%' : 'fit-content',
          // }),
          boxShadow: isDesktop ? '0px 4px 12px 0px #00000014' : 'none',
          margin: 'auto'

          // paddingBottom: '1em'
        }}>

        {children}
      </Card>

      <div style={{ margin: '2em' }}></div>
      <BottomComponent>
        <></>
      </BottomComponent>
    </div>
  );
}
