import Avatar from '@mui/material/Avatar';
import * as React from 'react';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name, sx) {
  const cl = stringToColor(name);
  console.log(name);
  if (!name) {
    return {};
  }
  return {
    sx: {
      bgcolor: cl + 22,
      width: 24, height: 24,
      ...sx
    },
    children: <span
      style={{
        color: cl
      }}>
      {name.split(' ')[0][0]}
      {name.split(' ')?.[1]?.[0]}
    </span>,
  };
}

export default function BackgroundLetterAvatars({name, sx}) {
  return (
    <Avatar {...stringAvatar(name, sx)} />
  );
}
