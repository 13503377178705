import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Carousel from '.';
import { UIStore } from '../../Config/store';
import './styles.css';

// import required modules

export default function HomeCarousel() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const configs = UIStore.useState((s) => s.configs);
  const images = process.env.REACT_APP_BANNER ? [process.env.REACT_APP_BANNER] : ['https://storage.googleapis.com/indisi-images-dev/d546c00a1f17433d55ea748f85fb695c6c0fe1b2f5c11ffadc11f621b693d1b4'];

  return (
    <Box
      sx={{
        // display: { xs: 'none', sm: 'block', md: 'block' },
        // height: '20vh',
        width: '98%',
        margin: '1em auto',
        background: 'transparent'
      }}>

      <Carousel
        images={images}
        imgStyle={{
        }}
      />

    </Box>
  );
}
