/* eslint-disable */
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Card, Grid, List, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FileInput from '../../Components/FileUpload';
import ListDataUI from '../../Components/ListDataUI';
import { dataUi, fileInputConfig, specificInfoForTitles } from '../../Config/dataUi';
import { UIStore } from '../../Config/store';
import { nameValueArrayToJson } from '../../Helper/helpers';
import { getFormattedUiForAnAttribute, states, statesEnum } from '../../Utils/enums';
import Insufficiency from '../DocRequest/insufficiency';

export default function OldData({ index, oldData, dataOnForm, selectedOldData, setSelectedOldData, setSubTypes, subTypes,
  subTypesWithEmptyFiles, fn }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  const currentWorkingStepperStep = UIStore.useState((s) => s.currentWorkingStepperStep);
  const formInfo = UIStore.useState((s) => s.formInfo);
  const [dataToShow, setDataToShow] = useState({});
  const [oldDataModified, setOldDataModified] = useState({})
  const [fileInputField, setFileInputField] = useState(null);

  const form = UIStore.useState((s) => s.form);
  const currentCredentialUnderFetch = form?.list?.[currentWorkingStepperStep];
  const configs = UIStore.useState((s) => s.configs) ?? {};
  const searchedQuery = UIStore.useState((s) => s.searchedQuery) ?? {};
  useEffect(() => {

    setDataToShow(dataUi(oldData, currentCredentialUnderFetch));

    const tempOldData = { ...oldData }
    if (tempOldData.state === statesEnum.FAILED) {
      tempOldData.stateToBeShown = statesEnum.FAILED
    } else if (dataOnForm?.state === statesEnum.CLOSED) {
      tempOldData.stateToBeShown = statesEnum.CLOSED
    }
    else if (dataOnForm) {
      tempOldData.stateToBeShown = [statesEnum.FAILED, statesEnum.CLOSED]
        .includes(tempOldData.state) ? tempOldData.state : statesEnum.SUBMITTED;
    } else {
      tempOldData.stateToBeShown = statesEnum.READY_TO_UPLOAD;
    }

    setOldDataModified(tempOldData)

    if (oldData.state === statesEnum.FAILED) {
      setFileInputField(null);
      if (subTypes?.[oldData?.subType]) {
        setSubTypes((zz) => {
          const jj = { ...zz };
          delete jj[oldData?.subType]
          return jj;
        });
      }
    } else if (typeof state?.subTypes?.[oldData?.subType] === 'string') {
      setFileInputField(state?.subTypes?.[oldData?.subType]);
      setSubTypes((zz) => {
        const jj = { ...zz };
        jj[oldData?.subType] = state?.subTypes?.[oldData?.subType];
        return jj;
      });
    }
  }, [currentCredentialUnderFetch]);


  console.log('oldData', oldData, oldDataModified, dataOnForm);


  return <Grid
    container
    direction="column"
    justifyContent="flex-start"
    alignItems="flex-start"
    sx={{
      padding: '0',
      flexWrap: 'nowrap',
      // border:  `2px solid ${theme.palette.success.main}` ,
      border: (oldDataModified?.state === statesEnum.FAILED && currentCredentialUnderFetch?.data?.acceptMultipleSubmissions) ? `none` : `1px solid ${theme.palette.grey.main}`,
      borderRadius: '8px',
      bgcolor: (oldDataModified?.state === statesEnum.FAILED && currentCredentialUnderFetch?.data?.acceptMultipleSubmissions) ? theme.palette.grey[200] : '#ffffff',
      marginTop: index === 0 ? 0 : '2em',
    }}>

    <List dense style={{
      bgcolor: theme.palette.grey[300], width: '100%', marginTop: '0em', paddingTop: '0em', paddingBottom: 0
    }}>

      {Array.isArray(oldDataModified?.bgvDetails?.ongrid?.data) ? oldDataModified?.bgvDetails?.
        ongrid?.data?.map((res, i) =>
          <React.Fragment
            key={`read-data-${i}`}>
            <Card sx={{
              border: 'none',
              borderRadius: '8px',
              boxShadow: 'none',
              marginBottom: '1em !important',
              bgcolor: 'transparent'
            }}>
              {(res?.request?.data && Object.keys(res?.request?.data).length > 0) ?
                (specificInfoForTitles(res?.request?.data, currentCredentialUnderFetch?.key) ? <Accordion
                  disableGutters
                  sx={{
                    'border': 'none',
                    'outline': 'none',
                    'borderRadius': '8px',
                    // 'boxShadow': '1px 4px 10px 0px #ADADAD29',
                    'marginBottom': '1em !important',
                    '&:before': { height: '0px' },
                    'boxShadow': 'none', bgcolor: theme.palette.grey[300],
                  }}
                  defaultExpanded={i === 0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      boxShadow: 'none'
                    }}
                  >
                    <Grid sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width: '100%'
                    }}>

                      <Avatar style={{
                        // border: '1px solid #E7EDF6',
                        backgroundColor: '#F6F6F6',
                        height: '30px',
                        width: '30px', margin: '0 1em'
                      }}>
                        <img
                          src={currentCredentialUnderFetch?.icon} style={{
                            minHeight: '15px',
                            maxHeight: '25px', margin: '0', objectFit: 'contain'
                          }} alt="logo" />
                      </Avatar>
                      <Typography
                        variant="h6">
                        {specificInfoForTitles(res?.request?.data, currentCredentialUnderFetch?.key)}
                      </Typography>

                      <Typography
                        variant="body2" color={states[res?.state]?.color}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 'auto'
                        }}>
                        {states[res?.state]?.icon}
                        &nbsp;
                        {states[res?.state]?.title}
                      </Typography>

                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ListDataUI
                      fn={fn}
                      info={res?.request?.data}
                      index={i}
                      currentInfo={{
                        name: currentCredentialUnderFetch?.key,
                        orgId: searchedQuery?.orgId,
                        transactionId: searchedQuery?.txnId,
                        stateInProfile: oldDataModified?.state,
                        stateInForm: dataOnForm?.state,
                        disableEditDelete: ![statesEnum.NOT_INITIALIZED, statesEnum.DATA_SAVED]?.
                          includes(formInfo[currentCredentialUnderFetch?.key]?.state)
                      }} />
                  </AccordionDetails>
                </Accordion> :

                  <ListDataUI
                    fn={fn}
                    info={res?.request?.data}
                    index={i}
                    currentInfo={{
                      name: currentCredentialUnderFetch?.key,
                      orgId: searchedQuery?.orgId,
                      transactionId: searchedQuery?.txnId,
                      stateInProfile: oldDataModified?.state,
                      stateInForm: dataOnForm?.state,
                      disableEditDelete: ![statesEnum.NOT_INITIALIZED, statesEnum.DATA_SAVED]?.
                        includes(formInfo[currentCredentialUnderFetch?.key]?.state)
                    }} />
                ) : null}
              {res?.responseList?.length > 0 ?
                (res?.responseList?.map((item, i) => <>
                  {item.presentation ?
                    <Card
                      key={`presentation-${i}`}
                      sx={{
                        border: 'none',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        marginBottom: '1em !important',
                        bgcolor: theme.palette.grey[300],
                      }}>

                      <ListDataUI
                        fn={fn}
                        info={nameValueArrayToJson(item?.presentation)}
                        customUi={
                          getFormattedUiForAnAttribute[currentCredentialUnderFetch?.key] ?
                            getFormattedUiForAnAttribute[currentCredentialUnderFetch?.key] :
                            {}
                        }
                        index={i}
                        currentInfo={{
                          name: currentCredentialUnderFetch?.key,
                          orgId: searchedQuery?.orgId,
                          transactionId: searchedQuery?.txnId,
                          stateInProfile: oldDataModified?.state,
                          stateInForm: dataOnForm?.state,
                          disableEditDelete: ![statesEnum.NOT_INITIALIZED, statesEnum.DATA_SAVED]?.
                            includes(formInfo[currentCredentialUnderFetch?.key]?.state)
                        }} />


                    </Card> : null}


                  {/* {item.insufficiencyData?.length > 0 ? <>
                
                  <Insufficiency
                    index={i}
                    bgv={currentCredentialUnderFetch?.name}
                    key={`InsufficiencyCard-${i}`}
                    insufficiencyData={item.insufficiencyData}
                    insufficiencyDataList={res?.request?.insufficiencyDataList}
                    id={item?.requestId} />

                </> : null} */}


                  <Insufficiency
                    // index={i}
                    bgv={currentCredentialUnderFetch?.name}
                    // key={`InsufficiencyCard-${i}`}
                    // insufsFiltered={insufsFiltered}
                    responseList={res?.responseList}
                    // insufficiencyData={item.insufficiencyData}
                    insufficiencyDataList={res?.request?.insufficiencyDataList}
                    id={res?.request?.id}
                    subType={res?.subType}
                  />


                </>)) : null}
            </Card>

          </React.Fragment>)
        : (typeof oldDataModified?.bgvDetails?.ongrid?.data === 'object' ?
          <Card
            sx={{
              border: 'none',
              borderRadius: '8px',
              boxShadow: 'none',
              bgcolor: 'transparent'
              // marginBottom: '1em !important',
            }}
            key={oldDataModified.subType}>
            <Typography
              variant="body2" color={states[oldDataModified.stateToBeShown]?.color}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: theme.palette?.[states?.[oldDataModified?.stateToBeShown]?.palette]?.light,
                width: '100%',
                margin: 0,
                padding: '1em',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                fontWeight: 400,
              }}>
              {states[oldDataModified.stateToBeShown]?.icon}
              &nbsp;
              {states[oldDataModified.stateToBeShown]?.title}
            </Typography>
            <ListDataUI
              fn={fn}
              info={oldDataModified?.bgvDetails?.ongrid?.data}
              id={oldDataModified?.subType}
              index={index}
              customUi={
                getFormattedUiForAnAttribute[currentCredentialUnderFetch?.key] ?
                  getFormattedUiForAnAttribute[currentCredentialUnderFetch?.key] :
                  {}
              }
              currentInfo={{
                name: currentCredentialUnderFetch?.key,
                orgId: searchedQuery?.orgId,
                transactionId: searchedQuery?.txnId,
                stateInProfile: oldDataModified?.state,
                stateInForm: dataOnForm?.state,
                disableEditDelete: !["NOT_INITIALIZED", "DATA_SAVED"]
                  ?.includes(formInfo[currentCredentialUnderFetch?.key]?.state)
              }} />

            {/* {dataOnForm?.responseList?.map((item, i) => <>
              {
                item.insufficiencyData?.length > 0 ? <>

                  <Insufficiency
                    index={i}
                    bgv={currentCredentialUnderFetch?.name}
                    key={`InsufficiencyCard-${i}`}
                    insufficiencyData={item.insufficiencyData}
                    insufficiencyDataList={dataOnForm?.request?.insufficiencyDataList}
                    id={item?.requestId} />


                  
                </> : null
              }
            </>)} */}


            <Insufficiency
              // index={i}
              bgv={currentCredentialUnderFetch?.name}
              // key={`InsufficiencyCard-${i}`}
              // insufsFiltered={insufsFiltered}
              responseList={dataOnForm?.responseList}
              // insufficiencyData={item.insufficiencyData}
              insufficiencyDataList={dataOnForm?.request?.insufficiencyDataList}
              id={dataOnForm?.request?.id}
              subType={dataOnForm?.subType}
            />





          </Card> : null)}


    </List>
    {
      !([statesEnum.FAILED, statesEnum.CLOSED].includes(oldData?.state)
    || [oldDataModified.stateToBeShown].includes(statesEnum.CLOSED)) ?
        ((formInfo?.[currentCredentialUnderFetch?.key]?.marksheetRequired
        ) ? <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: '100%', margin: '0 0 1em 0',
          }}>
          <FileInput
            input={fileInputConfig}
            disabled={![statesEnum.DATA_SAVED, statesEnum.NOT_INITIALIZED, undefined, null]
              .includes(dataOnForm?.state)
              || oldData.state === statesEnum.FAILED
            }
            onChange={(a, b) => {
              console.log('b:', b);
              setFileInputField(b);
              setSubTypes((zz) => {
                const jj = { ...zz };
                jj[oldDataModified?.subType] = b;
                return jj;
              });
            }}
            error={{
              [fileInputConfig.fieldName]: subTypesWithEmptyFiles
                .includes(oldDataModified?.subType) ?
                { message: 'Please upload Marksheet' } : null
            }}
            // progress={progress}
            data={{ 'Marksheet2': fileInputField }}
            defaultHelperText={
              (typeof state?.subTypes?.[oldDataModified?.subType] === 'string' ?
                state?.subTypes?.[oldDataModified?.subType] : fileInputConfig?.helperText || '')}
            sx={{ margin: '1em 1em 0 1em' }}
          />


        </Grid> : null) : (typeof state?.subTypes?.[oldDataModified?.subType] === 'string' ? <ListDataUI
          info={{
            'uploadedMarksheet': state?.subTypes?.[oldDataModified?.subType]
          }}
          currentInfo={{ stateInProfile: statesEnum.FAILED }} /> : null)
    }

  </Grid >;
}


