// Core modules imports are same as usual
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.css';

export default function Carousel({images, imgStyle, children}) {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          reverseDirection: false
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {images?.map((im, i) => (
          <SwiperSlide
            key={`carousel-img${i}`}
            // onClick={()=>window.open('https://www.trential.com/', '_blank')}
          >
            <img
              src={im}
              alt={`carousel-${i}`}
              style={{
                background: 'transparent',
                // objectFit: 'contain'
              }} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <div
        alt="slider"
        style={{
          ...imgStyle,
        }}
        className="sliding-image">
        <div />
        <div className="overlay-carousel">{children}</div>
        <div style={{
          textAlign: 'center'
        }} />

      </div> */}
    </>

  );
}
