import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { getInsufficiencyList } from './insufficiency';

export function useIsDesktop() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('750'));
  return isDesktop;
}

export function isDesktop() {
  if (window.innerWidth > 750) { return true; }
  else { return false; }
}

export function useGetInsufficiencies(responseList, insufficiencyDataList) {
  const [insufsFiltered, setInsufsFiltered] = useState([]);
  const [insufsResolvedFiltered, setInsufsResolvedFiltered] = useState({});

  console.log('insuff::', responseList, insufficiencyDataList);

  useEffect(() => {
    let insufs = [];
    let insufsResolved = {
      insuf: [],
      resolution: {
      }
    };

    responseList?.forEach((response, i) => {
      if (response?.insufficiencyData) {
        const foundResolved = insufficiencyDataList ? insufficiencyDataList?.findLast((x) => x?.responseIndex === i) : null;
        const insuff = getInsufficiencyList(response?.insufficiencyData, foundResolved, insufsResolved);
        insufs = [...insufs, ...insuff.unresolved];
        insufsResolved = insuff.resolved;
      }
    });

    console.log('insuf-ssss', insufs);
    setInsufsFiltered(insufs);
    setInsufsResolvedFiltered(insufsResolved);
  }, []);


  return ({
    insufsFiltered,
    insufsResolvedFiltered
  });
}
