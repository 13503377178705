import React, { useState } from 'react';
import { Box, Collapse, Grid, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import faqArt from '../../../../../Assets/wes-login/faq-art.svg';
import { TransitionGroup } from 'react-transition-group';
import { configs } from '../../../../Information/configs';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './styles';
import MarkdownWrapper from '../../../../../Components/UI/markdownWrapper';

function FAQ() {
  const theme = useTheme();
  const greaterThanXL = useMediaQuery(theme.breakpoints.up('xl'));
  const [expandedIndex, setExpandedIndex] = useState(null);
  return (
    <Box sx={styles.outerWrapper} id='wes-auth-faq'>
      <Box sx={{
        width: '100%',
        backgroundImage: `url(${faqArt})`,
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        backgroundPositionY: 'bottom',
        p: {xs: '7rem 5vw', sm: '15rem 5.9vw', xl: '15rem 12vw'},
        pb: '7.5rem !important',
      }}>
        <Grid container spacing={greaterThanXL ? '8.2' : '7'} alignItems={'flex-start'}>
          <Grid item xs={12} sm={4.65} xl={4.4}>
            <Typography className='wes-auth-subtitle' sx={{color: '#fff'}}>
              Frequently Asked Questions
            </Typography>
            <Typography className='wes-auth-caption' sx={{color: '#fff'}}>
              {'Aren\'t able to find answers you\'re looking for? Reach out to our '}
              <Box component='a' href={`mailto:${process.env.REACT_APP_ORG_MAIL}`} sx={{color: '#fff', textDecoration: 'underline !important'}}>
              customer support team.
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7.35} xl={7.6}>
            {
              configs?.faq?.map((row, index) =>
                <Box sx={{'borderBottom': '1px solid rgba(255, 255, 255, 0.3)',
                  'borderRadius': '6px', 'mb': '2rem'}}>
                  <Button
                    disableRipple
                    disableFocusRipple
                    sx={{...styles.questionWrapper,
                      borderRadius: expandedIndex === index ? '6px 6px 0 0' : '6px',
                    }}
                    onClick={() => {
                      if (expandedIndex === index) {
                        return setExpandedIndex(null);
                      }
                      setExpandedIndex(index);
                    }}>
                    <div style={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
                      <Box>
                        <Typography className='wes-auth-caption' sx={{ fontWeight: '500', color: '#fff', textAlign: 'left !important' }}>
                          {row?.question}
                        </Typography>
                      </Box>
                    </div>
                    <div style={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
                      {expandedIndex === index ? (
                  <ExpandLessIcon sx={{ width: '2.3rem', height: '2.3rem', color: '#CFCFD1' }} />
                ) : (
                  <ExpandMoreIcon sx={{ width: '2.3rem', height: '2.3rem', color: '#CFCFD1' }} />
                )}
                    </div>
                  </Button>
                  <TransitionGroup>
                    {expandedIndex === index && (
                      <Collapse>
                        <Box sx={styles.answerWrapper}>
                          <Typography className='wes-auth-caption' sx={{ fontWeight: '400 !important'}}>
                            <MarkdownWrapper>
                              {row.answer}
                            </MarkdownWrapper>
                          </Typography>
                        </Box>
                      </Collapse>
                    )}
                  </TransitionGroup>
                </Box>)
            }
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default FAQ;
