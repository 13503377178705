import { Store } from 'pullstate';
import { getFetchProcessInterval, triggerCancellationController } from '../Helper/helpers';
import { GleapInstance } from '../Monitoring/Gleap';
import { links, routesInsideEkyc } from '../Utils/enums';
const initialState = {
  isAuthenticated: false,
  authRes: {},
  currentUserData: {},
  selectedOffers: {},
  webcamFacingMode: 'environment',
  docData: {},
  credentials: [],
  digilockerCredentials: [],
  liveness: {},
  selectedCredentialAttributes: [],
  trentialId: null,
  credentialLivenessCheckRecord: {
    'Aadhaar': false,
    'Driving License': false,
    'Passport': false,
    'Voter ID Card': false,
    'Voter ID': false
  },
  currentCredentialUnderFetch: {},
  searchedQuery: '',
  form: {
    submittedFormData: {},
    remaining: []
  },
  formInfo: {},
  currentWorkingStepperStep: 0,
  credsWithDigilocker: {},
  formUnderProcess: {},
  credentialsTriggered: {},
  digilockerToken: '',
  cancellationController: null,
  isAutoplay: false,
  infoSubmitted: {},
  drawer: false,
  userInput: {},
  payments: []
};

const refreshValues = {
  formResponses: {},
  currentCredDefId: '',
  fieldContent: {},
  configs: null,
  openDemo: false
};

const localStorageInitialValues = {
  showWelcome: {
  },
};

export const UIStore = new Store(initialState);
export const UIStoreLocal = new Store(localStorageInitialValues);

UIStore.subscribe(
    (s) => s,
    (userStore) => {
      sessionStorage.setItem('store', JSON.stringify(userStore));
    }
);

UIStoreLocal.subscribe(
    (s) => s,
    (userStore) => {
      localStorage?.setItem('store', JSON.stringify(userStore));
    }
);

export const rehydrateStore = async () => {
  try {
    const user = sessionStorage.getItem('store');
    const userLocal = localStorage?.getItem('store');
    console.log('REHYDRATING STORE:', user);
    if (user !== null) {
      const data = JSON.parse(user);
      // login(data.authRes, true);
      UIStore.replace({ ...data, ...refreshValues });
    }
    if (userLocal !== null) {
      const dataLocal = JSON.parse(userLocal);
      console.log('REHYDRATING dataLocal', dataLocal);
      UIStoreLocal.replace({ ...dataLocal });
    }
  } catch (e) {
    console.log('Error occured', e);
  }
};

export const updateStore = (key, value) => {
  UIStore.update((s) => {
    s[key] = value;
  });
};

export const updateStoreLocal = (key, value) => {
  UIStoreLocal.update((s) => {
    s[key] = value;
  });
};

export const patchStore = (key, value, key1 = null) => {
  const val = fetchFromStore(key);
  UIStore.update((s) => {
    s[key] = key1 ? { ...val, [key1]: { ...val[key1], ...value } } : { ...val, ...value };
  });
};

export const fetchFromStore = (key) => {
  const mainStore = UIStore.getRawState();
  return mainStore[key];
};

export const fetchFromStoreLocal = (key) => {
  const mainStore = UIStoreLocal.getRawState();
  return mainStore[key];
};

export const logout = (redirect = false) => {
  GleapInstance.clearIdentity();
  let searchedQuery = {};
  let route = '/';
  if (redirect) {
    searchedQuery = fetchFromStore('searchedQuery');
    route = routesInsideEkyc.find((path) => window?.location?.pathname?.includes(path));
  }
  UIStore.update((s) => ({
    ...initialState
  }));

  UIStoreLocal.update((s) => {
    s.showWelcome = {};
  });
  clearInterval(getFetchProcessInterval());
  triggerCancellationController();
  localStorage?.setItem('token', '');
  localStorage?.setItem('refreshToken', '');
  localStorage?.setItem('refreshTokenExpiresIn', 0);


  // after login, the same route will open where logout happened
  if (!redirect) {
    return window.navigate('/login');
  }
  if (window?.location?.pathname?.includes(`/${links.EKYC}`)) {
    window.navigate('/login', { state: { link: window?.location?.href?.split(window?.location?.origin)[1] } });
  } else if (redirect) {
    const link = sessionStorage?.getItem('lastBaseUrlVisited');

    if (link) {
      if (link === '/') {
        if (route) {
          window.navigate('/login', {
            state: {
              link: searchedQuery?.link
            }, disableBack: true
          });
        }
      } else {
        window.navigate('/login', {
          state: {
            link: link
          }, disableBack: true
        });
      }
    }
  }
};

export const login = (info, noChangeInStore = false) => {
  // UIStore.update((s) => ({
  //   // ...initialState,
  //   isAuthenticated: true,
  //   authRes: info
  // }));
  UIStore.update((s) => {
    s.isAuthenticated = true;
    s.authRes = info;
  });
  localStorage?.setItem('token', info.accessToken);
  localStorage?.setItem('refreshToken', info.refreshToken);
  localStorage?.setItem('refreshTokenExpiresIn', info.refreshTokenExpiresIn);
};

